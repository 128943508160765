<template>
  <q-layout>
    <q-page-container class="flex flex-center">
      <router-view />
      <!-- <span>{{ token }}</span> -->
    </q-page-container>
  </q-layout>
</template>

<script>
//import Inicio from "./views/Home.vue"
import Inicio from "./components/Inicio/MainPage.vue"
import axios from 'axios'
import { colors } from 'quasar'
export default {
  name: 'LayoutDefault',

  components: {
    Inicio
  },

  data() {
    return {
      leftDrawerOpen: false
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
    this.GetEmpresa();
    // colors.setBrand('primary', '#33F')
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },

  methods: {
    async GetEmpresa() {
      try {
        let response = await axios.get('Empresa/GetEmpresa/erp_' + this.token.rfc + '/' + this.token.rfc);
        console.log(response)
        this.$store.state.empresaStore = response.data
      } catch (error) {

        this.$store.state.empresaStore = {
          _id: '',
          rfc: '',
          nombre: '',
          regimenFiscal: {
            regimenFiscal: ''
          },
          domicilioFiscal: '',
          logo: {
            base64: '',
          },
          nombreCorreo: '',
          correo: '',
          password: '',
          puerto: '',
          host: '',
        }
      }
    },
  }
}
</script>
