<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Póliza
                </q-toolbar-title>
                <template v-if="indicePol != -1">
                    <q-toolbar-title v-show="!poliza._id == ''">
                        <template class="text-center">
                            <q-btn round color="primary" dense icon="mdi-arrow-left-bold" class="q-mr-sm"
                                @click="polizaAnterior()">
                                <q-tooltip>
                                    Anterior
                                </q-tooltip>
                            </q-btn>

                            <q-btn round color="primary" dense icon="mdi-arrow-right-bold" class="q-mr-sm"
                                @click="polizaSiguiente()">
                                <q-tooltip>
                                    Siguiente
                                </q-tooltip>
                            </q-btn>
                        </template>
                    </q-toolbar-title>
                </template>

                <q-btn v-if="poliza._id == ''" dense round color="green-10" icon="mdi-content-save" class="q-mr-sm"
                    @click="PostPoliza()">
                    <q-tooltip>
                        Guardar y cerrar
                    </q-tooltip>
                </q-btn>

                <q-btn v-if="poliza._id == ''" dense round color="green-6" icon="mdi-content-save" class="q-mr-sm"
                    @click="PostPolizaAndNew()">
                    <q-tooltip>
                        Guardar y nuevo
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="PutPoliza()">
                    <q-tooltip>
                        Guardar edición
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
            <q-splitter v-model="splitterModel" style="height: 640px;" :limits="[40, 100]">

                <template v-slot:before>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-4">
                            <q-select dense class="full-width" v-model="poliza.tipo" filled :options="itemsTiposPoliza"
                                label="Tipo de Póliza" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input dense type="number" v-model="poliza.numPoliza" filled label="No. Póliza" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input v-model="fehaIMasked" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="poliza.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12">
                            <q-input dense filled label="Descripción de la Póliza" v-model="poliza.descripcion" />
                        </div>
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>ASIENTOS</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <!-- DESCRIPCION DEL ASIENTO -->
                        <div class="col-12">
                            <q-input dense filled v-model="asiento.descripcion" label="Descripción del asiento" />
                        </div>
                        <!-- NUMERO DE CUENTA -->
                        <div class="col-12 col-md-6">
                            <q-select dense filled label="Número de Cuenta" v-model="asiento.cuenta"
                                option-label="cuenta" @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas"
                                use-input input-debounce="0" ref="refNumCuenta">
                                <q-badge floating rounded color="primary" :label="nombreCuentaBadge" />
                            </q-select>
                        </div>
                        <!-- CARGO -->
                        <div class="col-12 col-md-3">
                            <q-field v-model="asiento.cargo" label="Cargo" dense class="q-mr-md" filled ref="refCargo">

                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>
                        </div>
                        <!-- ABONO -->
                        <div class="col-12 col-md-3">
                            <q-field v-model="asiento.abono" label="Abonos" dense filled ref="refAbono">

                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>

                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-plus" @click="agregarAsiento()">
                                        <q-tooltip>
                                            Agregar
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                                :data="poliza.asientos" :columns="columnsAientos" row-key="_id"
                                :rows-per-page-options="[0]" hide-bottom>

                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width key="acciones">
                                            <q-btn size="md" color="red" rounded flat dense
                                                @click="eliminarAsiento(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                                            </q-btn>
                                            <q-btn size="md" color="blue" rounded flat dense
                                                @click="editarAiento(props.row)" icon="mdi-pencil">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Editar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="cuenta" :props="props">{{ props.row.cuenta.cuenta }}
                                            <q-popup-edit v-model="props.row.cuenta" title="Editar cuenta"
                                                v-slot="scope" buttons persistent>
                                                <q-select dense filled label="Número de Cuenta" v-model="scope.value"
                                                    option-label="cuenta" @filter="filtroCatalogoCuenta"
                                                    :options="filtroCatalogoCuentas" use-input autofocus
                                                    @keyup.enter="scope.set" input-debounce="0" />
                                                <!-- <q-input v-model="scope.value" dense autofocus counter
                                                    @keyup.enter="scope.set" /> -->
                                            </q-popup-edit>
                                        </q-td>
                                        <q-td key="descripcion" :props="props">
                                            {{ props.row.descripcion }}
                                            <q-popup-edit v-model="props.row.descripcion"
                                                title="Editar descripcíon del asiento" auto-save v-slot="scope">
                                                <q-input v-model="scope.value" dense autofocus counter
                                                    @keyup.enter="scope.set" />
                                            </q-popup-edit>
                                        </q-td>
                                        <q-td key="cargo" :props="props">
                                            {{ formatCurrency(props.row.cargo) }}
                                        </q-td>
                                        <q-td key="abono" :props="props">
                                            {{ formatCurrency(props.row.abono) }}
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                        <div class="col-12 ">
                            <q-expansion-item header-class="bg-grey-3" class="full-width" v-model="listaXML"
                                icon="mdi-format-list-bulleted" label="Lista de XML">
                                <div class="row q-mt-sm q-col-gutter-sm">
                                    <div class="col-12">
                                        <q-table :columns="columnsXml" dense
                                            class="my-sticky-column-table shadow-0 header-tabla" row-key="folioFiscal"
                                            :data="poliza.listaXml">

                                            <template v-slot:body="props">
                                                <q-tr :props="props">
                                                    <q-td key="acciones">
                                                        <q-btn size="md" color="red" rounded flat dense
                                                            @click="VerComprobante(props.row)" icon="mdi-file-pdf-box">
                                                            <q-tooltip transition-show="flip-right"
                                                                transition-hide="flip-left"
                                                                content-style="font-size: 14px">PDF</q-tooltip>
                                                        </q-btn>
                                                    </q-td>
                                                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                                                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                                                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha)
                                                        }}</q-td>
                                                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                                                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                                    <q-td key="tipoComprobante" :props="props">
                                                        {{ props.row.tipoComprobante }}
                                                    </q-td>
                                                    <q-td key="subTotal" :props="props">
                                                        {{ formatCurrency(props.row.subTotal) }}
                                                    </q-td>
                                                    <q-td key="total" :props="props">
                                                        {{ formatCurrency(props.row.total)}}
                                                    </q-td>
                                                    <q-td key="descuento" :props="props">{{ formatCurrency(props.row.descuento) }}</q-td>
                                                    <q-td key="metodoPago" :props="props">{{ props.row.metodoPago
                                                        }}</q-td>
                                                    <q-td key="formaPago" :props="props">{{ props.row.formaPago
                                                        }}</q-td>
                                                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal
                                                        }}</q-td>
                                                    <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                                                    <q-td key="fechaPago" :props="props">{{
                                                        FormatDate(props.row.fechaPago)
                                                        }}</q-td>
                                                </q-tr>
                                            </template>
                                        </q-table>
                                    </div>
                                </div>
                            </q-expansion-item>
                        </div>
                    </div>
                </template>

                <template v-slot:after>
                    <div class="text-center">
                        <visor-pdf ref="miComponenteHijo"></visor-pdf>
                    </div>
                </template>
            </q-splitter>
        </q-card-section>

        <q-card-actions>
            <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
                <q-toolbar>
                    <q-space></q-space>
                    <div class="text-h6">Total Cargo: {{ formatCurrency(totalCargo) }}</div>
                    <q-space></q-space>
                    <div class="text-h6">Total Abono: {{ formatCurrency(totalAbono) }}</div>
                    <q-space></q-space>
                </q-toolbar>
            </q-footer>
        </q-card-actions>
    </q-card>
</template>

<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import visorPdf from '../PDF/VisorPDF.vue';
export default {
    components: {
        Money,
        visorPdf
    },
    data() {
        return {
            pdfBase64: '',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,

            verPDF: false,
            splitterModel: 100,
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },

            filtroCatalogoCuentas: null,
            itemsTiposPoliza: ['Ingreso', 'Egreso', 'Diario'],
            listaXML: false,

            asiento: {
                descripcion: '',
                cuenta: { cuenta: '', numCuenta: '' },
                cargo: 0,
                abono: 0,
            },
            indexAsiento: -1,
            columnsAientos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'cuenta', align: 'center', label: 'Cuenta', field: 'cuenta', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'descripcion', align: 'center', label: 'Asiento', field: 'descripcion', sortable: true, classes: 'text-left', },
                { name: 'cargo', align: 'center', label: 'Cargo', field: 'cargo', sortable: true, classes: 'text-right', },
                { name: 'abono', align: 'center', label: 'Abono', field: 'abono', sortable: true, classes: 'text-right', },
            ],

            columnsXml: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'serie', align: 'center', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'center', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'tipoComprobante', align: 'center', label: 'TipoComprobante', field: 'tipoComprobante', sortable: true },
                { name: 'subTotal', align: 'center', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'center', label: 'total', field: 'Total', sortable: true },
                { name: 'descuento', align: 'center', label: 'Descuento', field: 'descuento', sortable: true },
                { name: 'metodoPago', align: 'center', label: 'Metodo Pago', field: 'metodoPago', sortable: true },
                { name: 'formaPago', align: 'center', label: 'Forma Pago', field: 'formaPago', sortable: true },
                { name: 'folioFiscal', align: 'center', label: 'Folio Fiscal', field: 'folioFiscal', sortable: true },
                { name: 'moneda', align: 'center', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'fechaPago', align: 'center', label: 'Fecha Pago', field: 'fechaPago', sortable: true },
            ],

            //CATALOGO
            catalogoPadre: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        poliza() {
            return this.$store.state.polizaStore
        },

        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.poliza.fecha ? moment.utc(this.poliza.fecha).format('DD/MMMM/yyyy') : ''
        },

        totalCargo() {
            return this.sum(this.poliza.asientos, 'cargo')
        },

        totalAbono() {
            return this.sum(this.poliza.asientos, 'abono')
        },

        nombreCuentaBadge() {
            let cuenta = ''

            try {
                let partes = this.asiento.cuenta.numCuenta.split('.')
                let cuentaPadre = partes[0]

                let indice = 0
                indice = this.catalogoPadre.findIndex(x => x.numCuenta === cuentaPadre);
                if (indice >= 0) {
                    cuenta = this.catalogoPadre[indice].cuenta
                }
            } catch (error) {
                return cuenta
            }
        },

        indicePol() {
            return this.$store.state.indexPolizas
        }

    },
    watch: {
    },
    created() {
        this.getCatalogoCuentas();
    },
    methods: {
        validaCargo() {
            this.asiento.abono = 0
        },

        validaAbono() {
            this.asiento.cargo = 0
        },

        async PostPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando Poliza. Espere...', messageColor: 'white' });
            try {
                let response = await axios.post('Polizas/PostPoliza/erp_' + this.token.rfc, this.poliza)
                let ObjPoliza = { ...response.data }
                this.$store.state.listaPolizasStore.push(ObjPoliza)

                //VALIDAMOS SI HAY QUE UNIR POLIZAS
                let unir = [...this.$store.state.listaPolizasUnir];
                if (unir.length != 0) {
                    await this.BorraPolizasUnir();
                };

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async PostPolizaAndNew() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando Poliza. Espere...', messageColor: 'white' })
            try {
                let response = await axios.post('Polizas/PostPoliza/erp_' + this.token.rfc, this.poliza)
                let ObjPoliza = { ...response.data }
                this.$store.state.listaPolizasStore.push(ObjPoliza)

                //VALIDAMOS SI HAY QUE UNIR POLIZAS
                let unir = [...this.$store.state.listaPolizasUnir];
                if (unir.length != 0) {
                    await this.BorraPolizasUnir();
                };

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido guardado exitosamente.` })

                this.poliza._id = '';
                this.poliza.descripcion = '';
                this.poliza.numPoliza = 0;
                this.poliza.asientos = [];

            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async PutPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Editando Poliza. Espere...', messageColor: 'white' })
            try {
                let response = await axios.put('Polizas/PutPoliza/erp_' + this.token.rfc, this.poliza)
                // console.log(response)
                let ObjPoliza = {
                    _id: this.poliza._id,
                    tipo: this.poliza.tipo,
                    numPoliza: this.poliza.numPoliza,
                    descripcion: this.poliza.descripcion,
                    fecha: this.poliza.fecha,
                    cargo: this.poliza.cargo,
                    abono: this.poliza.abono,
                    diferencia: this.poliza.diferencia,
                    estatus: this.poliza.estatus,
                }
                let indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === ObjPoliza._id);
                if(indice != -1){
                    Object.assign(this.$store.state.listaPolizasStore[indice], ObjPoliza)
                }

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        agregarAsiento() {
            //VALIDAMOS CARGO Y NO ABONO
            if (this.asiento.cargo != 0) {
                this.asiento.abono = 0
            }

            //VALIDAMOS QUE TENGAN CANTIDAD
            if (this.asiento.cargo == 0 && this.asiento.abono == 0) {
                this.$q.notify({ type: 'warning', message: 'Indique el cargo o abono de la cuenta' })
                this.$refs.refNumCuenta.focus();
                return;
            }

            if (this.asiento.cuenta == '') {
                this.$q.notify({ type: 'warning', message: 'Indique el número de cuenta' })
                return;
            }

            let cargo = parseFloat(this.asiento.cargo);
            let abono = parseFloat(this.asiento.abono);
            this.asiento.cargo = cargo
            this.asiento.abono = abono

            if (this.indexAsiento == -1) {
                this.$store.state.polizaStore.asientos.push(this.asiento);

            } else {
                Object.assign(this.$store.state.polizaStore.asientos[this.indexAsiento], this.asiento)
            }

            this.asiento = {
                descripcion: '',
                cuenta: { cuenta: '' },
                cargo: 0,
                abono: 0
            }
            this.indexAsiento = -1;
            this.$refs.refNumCuenta.focus();
        },

        editarAiento(item) {
            this.indexAsiento = this.$store.state.polizaStore.asientos.indexOf(item);
            this.asiento = { ...item }
        },

        eliminarAsiento(data) {
            let editedIndexCorreo = this.poliza.asientos.indexOf(data)
            this.poliza.asientos.splice(editedIndexCorreo, 1)
        },

        async getCatalogoCuentas() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                let catalogo = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente')
                this.$store.state.listaCatalogoCuentasStore = catalogo;
                this.catalogoPadre = response.data.filter(f => f.nivel === 1);
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        FormatDate(value) {
            if (typeof value == "string") {
                let fecha_a = value.replace('T', ' ');
                let fecha_b = fecha_a.replace('Z', '');
                const cadenaFechaConHora = fecha_b;
                const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
                const formato = "dd-MMMM-yyyy HH:mm:ss";
                const configuracionLocal = { locale: es };
                let resultado = format(fecha, formato, configuracionLocal);
                return resultado;
            }
            return value;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        filtroCatalogoCuenta2(val, update, abort) {
            // Llama a abort() en cualquier momento si no puedes recuperar los datos de alguna manera

            setTimeout(() => {
                update(
                    () => {
                        const needle = val.toLocaleLowerCase()
                        this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
                    },

                    ref => {
                        if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                            ref.setOptionIndex(-1)
                            ref.moveOptionSelection(1, true)
                        }
                    }
                )
            }, 300)
        },

        filterFn(val, update, abort) {
            setTimeout(() => {
                update(
                    () => {
                        if (val === '') {
                            this.options = this.itemsCatalogoCuentas
                        }
                        else {
                            const needle = val.toLowerCase()
                            this.options = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
                        }
                    },

                    ref => {
                        if (val !== '' && ref.options.length > 0) {
                            ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
                            ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                        }
                    }
                )
            }, 300)
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        cierraVentana() {
            this.splitterModel = 0
            this.$emit('cierraVentana')
        },

        async VerComprobante(item) {
            // console.log(item);
            // console.log(this.poliza.listaXml[0].tipoComprobante);
            this.splitterModel = 40
            try {
                let tipo = this.poliza.tipo;
                if (tipo === 'Egreso') {
                    this.$store.state.vistaPreviaStore.tipo = "R"
                    this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "FACTURA"
                    if (item.tipoComprobante === "N") {
                        this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "NOMINA"
                        this.$store.state.vistaPreviaStore.tipo = "E"
                    } else if (item.tipoComprobante === "P") {
                        this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "PAGO"
                    } else if (item.tipoComprobante === "E") {
                        this.$store.state.vistaPreviaStore.tipo = "E"
                    }
                    if (this.poliza.listaXml[0].tipoComprobante === "E") {
                        this.$store.state.vistaPreviaStore.tipo = "E"
                    }

                } else if (tipo === 'Ingreso') {
                    this.$store.state.vistaPreviaStore.tipo = "E"
                    this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "FACTURA"
                    if (item.tipoComprobante === "N") {
                        this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "NOMINA"
                        this.$store.state.vistaPreviaStore.tipo = "R"
                    } else if (item.tipoComprobante === "P") {
                        this.$store.state.vistaPreviaStore.tipoComprobanteInterno = "PAGO"
                    } else if (item.tipoComprobante === "E") {
                        this.$store.state.vistaPreviaStore.tipo = "R"
                    }
                    if (this.poliza.listaXml[0].tipoComprobante === "E") {
                        this.$store.state.vistaPreviaStore.tipo = "R"
                    }
                }
                this.$store.state.vistaPreviaStore.folioFiscal = item.folioFiscal.toUpperCase();
                this.$store.state.vistaPreviaStore.color = "19775C"
                this.$store.state.vistaPreviaStore.rfc = this.token.rfc

                this.$refs.miComponenteHijo.VerComprobante();
                // this.$refs.miComponenteHijo.VerPolizaPdf();

            } catch (error) {
                console.log(error)
            }
        },

        async polizaAnterior() {
            this.splitterModel = 100
            let indice = this.$store.state.indexPolizas - 1
            if (indice == -1) {
                indice = this.$store.state.listaPolizasStore.length - 1
            }
            this.$store.state.indexPolizas = indice
            let id = this.$store.state.listaPolizasStore[indice]._id;

            //CONSULTAMOS LA POLIZA
            await this.GetPolizaId(id);
        },

        async polizaSiguiente() {
            this.splitterModel = 100
            let indice = this.$store.state.indexPolizas + 1
            if (indice > this.$store.state.listaPolizasStore.length - 1) {
                indice = 0
            }
            this.$store.state.indexPolizas = indice
            let id = this.$store.state.listaPolizasStore[indice]._id;

            //CONSULTAMOS LA POLIZA
            await this.GetPolizaId(id);
        },

        async GetPolizaId(id){
            try{
                let response = await axios.get("Polizas/GetPolizasIdAsync/erp_" + this.token.rfc + '/' + id);
                let poliza = response.data;
                this.$store.state.polizaStore = { ...poliza }
            }catch(error){
                console.log(error)
            }
        },

        descargarComprobante() {
            const fileName = this.$store.state.pdfStore.nombre + ".pdf";
            const link = document.createElement("a");
            link.href = this.pdfBase64;
            link.download = fileName;
            link.click();
        },

        async GetXML() {
            const nombresMeses = [
                'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
            ];

            let folioFiscal = this.$store.state.pdfStore.nombre;
            let rfc = this.token.rfc
            let fecha = new Date(this.$store.state.pdfStore.fecha);
            let año = fecha.getFullYear();
            let mes = fecha.getMonth();
            let nombreMes = nombresMeses[mes]

            try {
                let response = await axios.get('Comprobantes/GetXmlAsync/' + rfc + '/' + año + '/' + nombreMes + '/' + folioFiscal);
                console.log(response.data);
                await this.descargaComprobanteXml(response.data)
            } catch (error) {
                console.log(error)
            }
        },

        async descargaComprobanteXml(item) {
            const xmlBase64 = item
            const fileName = this.$store.state.pdfStore.nombre + ".xml";
            const link = document.createElement("a");
            link.href = "data:text/xml;base64," + xmlBase64;
            link.download = fileName;
            link.click();
        },

        async BorraPolizasUnir() {
            //CAMBIAMOS SU ESTATUS A ELIMINADO
            try {
                for (let x of this.$store.state.listaPolizasUnir) {
                    const estado = "Eliminado";
                    const _id = x;
                    let response = await axios.put('Polizas/PutEstadoPoliza/erp_' + this.token.rfc + '/' + _id + '/' + estado)
                }
            } catch (error) {
                console.log(error);
            }

            //QUITAMOS DE LA LISTA DE POLIZAS
            try {
                for (let y of this.$store.state.listaPolizasUnir) {
                    const indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === y);
                    this.$store.state.listaPolizasStore.splice(indice, 1);
                }
            } catch (error) {
                console.log(error);
            }
            this.$store.state.listaPolizasUnir = [];
        },
    }
}
</script>

<style lang="sass">
.my-sticky-column-table

  thead tr:first-child th:first-child
    /* bg color is important for th; just specify one */
    background-color: #fff

  td:first-child
    background-color: #fff

  th:first-child,
  td:first-child
    position: sticky
    left: 0
    z-index: 1
</style>dex: 1
</style>