<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Flujo de Efectivo
                </q-toolbar-title>
                <q-btn dense round color="green-10" icon="mdi-pdf-box" class="q-mr-sm" @click="CreaPdf">
                    <q-tooltip>
                        PDF
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
            <div class="row q-col-gutter-sm">
                <div class="col-12">
                    <q-toolbar class="bg-grey-3">
                        <q-toolbar-title>
                            UTILIDAD NETA
                        </q-toolbar-title>
                        {{ formatCurrency(estadoResultado) }}
                    </q-toolbar>
                </div>
                <div class="col-6">
                    <q-bar dense class="bg-primary text-white justify-between">
                        <div>ACTIVIDADES DE OPERACIÓN</div>
                        <div> {{ formatCurrency(sumaTotalA) }}</div>
                    </q-bar>

                    <q-table :rows-per-page-options="[0]" hide-bottom :data="actividadesOperacion" :columns="columns"
                        row-key="name">
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                <q-td key="total" :props="props">{{ formatCurrency(props.row.total)
                                    }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>

                </div>
                <div class="col-6">
                    <q-bar dense class="bg-primary text-white justify-between">
                        <div>ACTIVIDADES DE INVERSIÓN</div>
                        <div> {{ formatCurrency(totalActividadInversion) }}</div>
                    </q-bar>
                    <q-table :rows-per-page-options="[0]" hide-bottom :data="actividadesInversion" :columns="columns"
                        row-key="name">
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                <q-td key="total" :props="props">{{ formatCurrency(props.row.total)
                                    }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>

                    <q-bar dense class="q-mt-md bg-primary text-white justify-between">
                        <div>IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO</div>
                        <div> {{ formatCurrency(importePorAplicar) }}</div>
                    </q-bar>

                    <q-bar dense class="q-mt-md bg-primary text-white justify-between">
                        <div>ACTIVIDADES DE FINANCIAMIENTO</div>
                        <div> {{ formatCurrency(sumaTotalAF) }}</div>
                    </q-bar>

                    <q-table :rows-per-page-options="[0]" hide-bottom :data="actividadesFinanciamiento"
                        :columns="columns" row-key="name">
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="cuenta" :props="props">{{ props.row.cuenta }}</q-td>
                                <q-td key="total" :props="props">{{ formatCurrency(props.row.total)
                                    }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>

                    <q-bar dense class="q-mt-md bg-primary text-white justify-between">
                        <div>VARIACIONES EN FLUJO</div>
                        <div> {{ formatCurrency(variacionFlujo) }}</div>
                    </q-bar>
                    <q-bar dense class="q-mt-md bg-primary text-white justify-between">
                        <div>EFECTIVO AL INICIO DEL EJERCICIO</div>
                        <div> {{ formatCurrency(efectivoInicioEjercicio) }}</div>
                    </q-bar>
                    <q-bar dense class="q-mt-md bg-primary text-white justify-between">
                        <div>FLUJO DE EFECTIVO AL FINAL DEL PERIODO</div>
                        <div> {{ formatCurrency(flujoEfectivoFinalPeriodo) }}</div>
                    </q-bar>

                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import jsPDF from 'jspdf';
import moment from 'moment';
import { format, parseISO } from 'date-fns';
export default {
    data() {
        return {
            columns: [
                { name: 'cuenta', align: 'left', label: 'Cuenta', field: 'cuenta', sortable: true },
                { name: 'total', align: 'right', label: 'Importe', field: 'total', sortable: true },
            ]
        }
    },
    computed: {
        estadoResultado() {
            return this.$store.state.estadoResultado;
        },
        // ACTIVIDADES DE OPERACION
        actividadesOperacion() {
            return this.$store.state.listActividadesOperacion;
        },
        token() {
            return this.$store.state.usuario;
        },
        sumaTotalA() {
            let total = 0;
            for (let item of this.actividadesOperacion) {
                total += item.total;
            }

            return total
        },

        // ACTIVIDADES DE INVERSION
        actividadesInversion() {
            return this.$store.state.listActividadesInversion;
        },
        totalActividadInversion() {
            return this.$store.state.listActividadesInversion[0].total
        },

        // ACTIVIDADES DE FINANCIAMIENTO
        actividadesFinanciamiento() {
            return this.$store.state.listActividadesFinanciamiento;
        },

        sumaTotalAF() {
            let total = 0;
            for (let item of this.actividadesFinanciamiento) {
                total += item.total;
            }
            return total
        },

        importePorAplicar() {
            // let total = this.totalActividadInversion + this.estadoResultado
            let total = this.totalActividadInversion
            let totalActividadOperacion = 0;
            for (let item of this.actividadesOperacion) {
                totalActividadOperacion += item.total;
            }
            return total + totalActividadOperacion
        },

        // VARIACIONES EN FLUJO
        variacionFlujo() {
            let totalActividadFinanciamiento = 0;
            for (let item of this.actividadesFinanciamiento) {
                totalActividadFinanciamiento += item.total;
            }
            return this.importePorAplicar + totalActividadFinanciamiento
        },

        //EFECTIVO AL INICIO DEL EJERCICIO
        efectivoInicioEjercicio() {
            return this.$store.state.efectivoInicioEjercicioStore;
        },

        //FLUJO DE EFECTIVO AL FINAL DEL PERIODO
        flujoEfectivoFinalPeriodo() {
            return this.variacionFlujo + this.efectivoInicioEjercicio
        }
    },
    watch: {
    },
    created() {
    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
        CreaPdf() {

            function formatoNumerico(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }

            //DATOS DE LA EMPRESA
            let xCabecera = 10;
            let yCabecera = 15;
            const doc = new jsPDF();

            if (this.$store.state.empresaStore.idEmpresa != 0) {
                let base64logo = this.$store.state.empresaStore.logo.base64
                doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
            }

            // var fecha = 'De 2022 a 2023'



            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FF0000");
            // doc.text('REPORTE', 195, yCabecera, { align: 'right' });
            doc.setTextColor("#000");

            doc.text('ESTADO DE DE FLUJO DE EFECTIVO', 195, yCabecera += 5, { align: 'right' });
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(this.token.empresa, 195, yCabecera += 5, { align: 'right' });
            doc.text(this.$store.state.anioreciente, 195, yCabecera += 5, { align: 'right' });
            doc.text('', 195, yCabecera += 5, { align: 'right' });

            // Definir el color de la barra
            const color = '#662E91';
            const width = 180;
            const height = 8;

            // FLUJO DE EFECTIVO AL FINAL DEL PERIODO
            let yTotalUtilidadNeta = yCabecera += 8;
            let xTotalUtilidadNeta = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(15, yTotalUtilidadNeta, width, 10, 'F');
            doc.text('UTILIDAD NETA', xTotalUtilidadNeta, yTotalUtilidadNeta += 6, { align: 'left' });
            doc.text(formatoNumerico(this.estadoResultado), 195, yTotalUtilidadNeta, { align: 'right' });


            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(15, yTotalUtilidadNeta + 15, width, height, 'F');

            // ACTIVIDADES DE OPERACION
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#000");
            let yActividadesOperacion = yTotalUtilidadNeta += 13;
            let xActividadesOperacion = 25;
            let totalActividadesOperacion = this.sum(this.actividadesOperacion, 'total')

            // TITULO ACTIVO CIRCULANTE
            doc.text('ACTIVIDADES DE OPERACIÓN', 15, yActividadesOperacion, { align: 'left' });
            doc.text(formatoNumerico(totalActividadesOperacion), 195, yActividadesOperacion, { align: 'right' });


            // ACTIVIDADES DE OPERACION
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");


            // CABEZERA DE LA TABLA
            doc.text('CUENTA', xActividadesOperacion, yActividadesOperacion += 7, { align: 'center' });
            doc.text('TOTAL', xActividadesOperacion + 90, yActividadesOperacion, { align: 'center' });


            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            yActividadesOperacion += 4;
            for (let c of this.actividadesOperacion) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.setTextColor("#000");

                let descripcion = c.cuenta
                let importe = c.total

                doc.text(descripcion, 15, yActividadesOperacion += 7, { align: 'left' });
                doc.text(formatoNumerico(importe), 195, yActividadesOperacion, { align: 'right' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yActividadesOperacion > espacioDisponible
                if (dif) {
                    doc.addPage();
                    yActividadesOperacion = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(15, yActividadesOperacion += 3, 195, yActividadesOperacion);
                }
            }

            // ACTIVIDADES DE INVERSION
            let yActividadesInversion = yActividadesOperacion += 13;
            let xActividadesInversion = 17;

            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#000");

            let totalActividadesInversion = this.sum(this.actividadesInversion, 'total')

            // TITULO ACTIVO CIRCULANTE
            doc.text('ACTIVIDADES DE INVERSIÓN', 15, yActividadesInversion, { align: 'left' });
            doc.text(formatoNumerico(totalActividadesInversion), 195, yActividadesInversion, { align: 'right' });

            doc.setFillColor(color);
            doc.rect(15, yActividadesInversion + 2, width, height, 'F');

            // ACTIVO CIRCULANTE
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");

            // CABEZERA DE LA TABLA
            doc.text('CUENTA', xActividadesInversion, yActividadesInversion += 7, { align: 'left' });
            doc.text('TOTAL', xActividadesInversion + 100, yActividadesInversion, { align: 'center' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            yActividadesInversion += 5;
            for (let c of this.actividadesInversion) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(11);
                doc.setTextColor("#000");

                let descripcion = c.cuenta
                let importe = c.total

                doc.text(descripcion, 15, yActividadesInversion += 7, { align: 'left' });
                doc.setFontSize(12);

                doc.text(formatoNumerico(importe), 195, yActividadesInversion, { align: 'right' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yActividadesInversion > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yActividadesInversion = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(15, yActividadesInversion += 3, 195, yActividadesInversion);
                }

            }

            // IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO
            let yTotalIPAEADF = yActividadesInversion += 13;
            let xTotalIPAEADF = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(15, yTotalIPAEADF, width, 10, 'F');
            doc.text('IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO', xTotalIPAEADF, yTotalIPAEADF += 6, { align: 'left' });
            doc.text(formatoNumerico(this.importePorAplicar), 195, yTotalIPAEADF, { align: 'right' });


            // ACTIVIDADES DE FINANCIAMIENTO
            let yActividadesFinanciamiento = yTotalIPAEADF += 16;
            let xActividadesFinanciamineto = 17;

            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#000");

            let totalActividadesFinanciamiento = this.sum(this.actividadesFinanciamiento, 'total')

            // TITULO ACTIVIDADES DE FINANCIAMIENTO
            doc.text('ACTIVIDADES DE FINANCIAMIENTO', 15, yActividadesFinanciamiento, { align: 'left' });
            doc.text(formatoNumerico(totalActividadesFinanciamiento), 195, yActividadesFinanciamiento, { align: 'right' });

            doc.setFillColor(color);
            doc.rect(15, yActividadesFinanciamiento + 2, width, height, 'F');

            // ACTIVIDADES DE FINANCIAMIENTO
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");

            // CABEZERA DE LA TABLA
            doc.text('CUENTA', xActividadesFinanciamineto, yActividadesFinanciamiento += 7, { align: 'left' });
            doc.text('TOTAL', xActividadesFinanciamineto + 100, yActividadesFinanciamiento, { align: 'center' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            yActividadesFinanciamiento += 5;
            for (let c of this.actividadesFinanciamiento) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(11);
                doc.setTextColor("#000");

                let descripcion = c.cuenta
                let importe = c.total

                doc.text(descripcion, 15, yActividadesFinanciamiento += 7, { align: 'left' });
                doc.setFontSize(12);

                doc.text(formatoNumerico(importe), 195, yActividadesFinanciamiento, { align: 'right' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yActividadesFinanciamiento > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yActividadesFinanciamiento = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(15, yActividadesFinanciamiento += 3, 195, yActividadesFinanciamiento);
                }

            }


            // VARIACIONES EN FLUJO
            let yTotalVariacionFlujo = yActividadesFinanciamiento += 13;
            let xTotalVariacionFlujo = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(15, yTotalVariacionFlujo, width, 10, 'F');
            doc.text('VARIACIONES EN FLUJO', xTotalIPAEADF, yTotalVariacionFlujo += 6, { align: 'left' });
            doc.text(formatoNumerico(this.variacionFlujo), 195, yTotalVariacionFlujo, { align: 'right' });

            // EFECTIVO AL INICIO DEL EJERCICIO
            let yTotalEfectivoInicio = yTotalVariacionFlujo += 8;
            let xTotalEfectivoInicio = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(15, yTotalEfectivoInicio, width, 10, 'F');
            doc.text('EFECTIVO AL INICIO DEL EJERCICIO', xTotalEfectivoInicio, yTotalEfectivoInicio += 6, { align: 'left' });
            doc.text(formatoNumerico(this.efectivoInicioEjercicio), 195, yTotalEfectivoInicio, { align: 'right' });

            // FLUJO DE EFECTIVO AL FINAL DEL PERIODO
            let yTotalEfectivoFinal = yTotalEfectivoInicio += 8;
            let xTotalEfectivoFinal = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(15, yTotalEfectivoFinal, width, 10, 'F');
            doc.text('FLUJO DE EFECTIVO AL FINAL DEL PERIODO', xTotalEfectivoFinal, yTotalEfectivoFinal += 6, { align: 'left' });
            doc.text(formatoNumerico(this.flujoEfectivoFinalPeriodo), 195, yTotalEfectivoFinal, { align: 'right' });

            // // Guardar o mostrar el PDF
            doc.save('Flujo_de_Efectivo_' + this.token.rfc + '.pdf');

        }
    }
}
</script>