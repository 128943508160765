<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOGO PARA CREAR UNA NUEVA CUENTA -->
        <q-dialog v-model="dialogNuevaCuenta" persistent transition-show="scale" transition-hide="scale">
            <Cuenta @cierraVentana="dialogNuevaCuenta = false"></Cuenta>
        </q-dialog>

        <!-- DIALOGO DE BORRADO DE CUENTA -->
        <q-dialog v-model="dialogDelete" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-lg">Estas seguro de eliminar la cuenta {{ cuentaDelete.cuenta }}?</span>
                </q-card-section>

                <!-- <q-card-actions align="right"> -->
                <q-card-actions>
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-space></q-space>
                    <q-btn flat label="Aceptar" color="primary" @click="DeleteCuenta()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO DE BORRADO DE CUENTA -->
        <q-dialog v-model="dialogCe" persistent>
            <ContabilidadElectronica @cierraVentana="dialogCe = false"></ContabilidadElectronica>
        </q-dialog>

        <!-- DIALOGO DE ERROR AL BORRAR CUENTA -->
        <q-dialog v-model="dialogNotDelete">
            <q-card>
                <q-card-section>
                    <span class="text-h5 q-ml-lg">Error al eliminar la cuenta {{ cuentaDelete.cuenta }}, ya que se
                        encuentra en las siguientes pólizas</span>
                    <q-editor v-model="itemsNotDelete" min-height="10rem" :toolbar="[]" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-table class="shadow-0 header-tabla" :data="itemsCatalogoCuentas" :pagination="initialPagination"
            :loading="loadingTabla" :columns="columns" row-key="_id" :filter="filter">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:top>
                <div class="row q-col-gutter-sm q-mb-lg full-width">
                    <div class="col-12 col-md-8">
                        <div class=" text-h5">Catálogo de Cuentas</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input filled dense v-model="filter" debounce="300" placeholder="Buscar">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                            <template v-slot:after>
                                <q-btn flat round color="primary" icon="mdi-notebook-plus" @click="nuevaCuenta()">
                                    <q-tooltip>
                                        Crear Cuenta
                                    </q-tooltip>
                                </q-btn>
                                <q-btn flat round color="primary" icon="mdi-xml" @click="OpenDialogCe()">
                                    <q-tooltip>
                                        Generar XML
                                    </q-tooltip>
                                </q-btn>
                                <q-btn flat round color="primary" icon="mdi-update" @click="getCatalogoCuentas()">
                                    <q-tooltip>
                                        Actualizar
                                    </q-tooltip>
                                </q-btn>
                                <q-btn flat round color="primary" icon="mdi-file-pdf" @click="GenerarPdf()">
                                    <q-tooltip>
                                        Generar PDF
                                    </q-tooltip>
                                </q-btn>
                                <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="GeneraExcel()">
                                    <q-tooltip>
                                        Generar Excel
                                    </q-tooltip>
                                </q-btn>
                                <!-- <q-btn flat round color="primary" icon="mdi-update" @click="PostCatalogoCuentas()">
                                    <q-tooltip>
                                        Subir
                                    </q-tooltip>
                                </q-btn> -->
                            </template>
                        </q-input>
                    </div>
                </div>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn v-show="props.row.usuarioCreador" size="md" color="red-10" rounded flat dense
                            icon="mdi-delete" @click="OpenDialogConfirm(props.row)">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="numCuenta" :props="props">{{ props.row.numCuenta }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="naturaleza" :props="props">{{ props.row.naturaleza }}</q-td>
                    <q-td key="numCuentaSat" :props="props">{{ props.row.numCuentaSat.numCuenta }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import axios from 'axios'
import Cuenta from './Cuenta.vue';
import ContabilidadElectronica from "./CatalogoCe.vue"
import { CatalogoCuentasPdf } from '../PDF/CatalogoCuentasPdf.js'
import * as XLSX from 'xlsx';
import { QSpinnerCube } from 'quasar'

export default {
    components: { Cuenta, ContabilidadElectronica },
    data() {
        return {
            filter: '',
            loadingTabla: false,
            initialPagination: {
                sortBy: 'numCuenta',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'numCuenta', align: 'left', label: 'Número de Cuenta', field: 'numCuenta', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true, },
                { name: 'naturaleza', align: 'center', label: 'Naturaleza', field: 'naturaleza', sortable: true, },
                { name: 'numCuentaSat', align: 'left', label: 'Cuenta SAT', field: 'numCuentaSat', sortable: true, },
            ],
            dialogNuevaCuenta: false,
            indexDelete: -1,
            dialogDelete: false,
            cuentaDelete: {
                cuenta: null,
            },
            dialogNotDelete: false,
            itemsNotDelete: [],

            //CONTABILIDAD ELECTRONICA
            dialogCe: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore
        },

        itemsCatalogoCuentasSat() {
            return this.$store.state.listaCatalogoCuentasSatStore
        },

        empresa() {
            return this.$store.state.empresaStore;
        },
    },

    created() {
        this.getCatalogoCuentas();
        this.getCatalogoCuentasSat()
    },

    methods: {
        nuevaCuenta() {
            this.dialogNuevaCuenta = true
            this.iniciaizarObjeto();
        },

        iniciaizarObjeto() {
            let cuenta = {
                _id: '',
                numCuentaUnico: '',
                numCuenta: '',
                descripcion: '',
                numCuentaSat: { cuenta: '' },
                cuenta: '',
                tipoCuenta: 'R',
                naturaleza: '',
                nivel: 0,
                editable: 'SI',
                estatus: "Vigente"
            }
            this.$store.state.cuentaStore = cuenta
        },

        async getCatalogoCuentas() {
            this.loadingTabla = true
            try {
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                this.$store.state.listaCatalogoCuentasStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async getCatalogoCuentasSat() {
            try {
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentasSat/");
                this.$store.state.listaCatalogoCuentasSatStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async PostCatalogoCuentas() {
            try {
                let response = await axios.post("CatalogoCuentas/PostCatalogo/erp_" + this.token.rfc);
            } catch (error) {
                console.log(error);
            }
        },

        async GenerarPdf() {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            let color = this.empresa.color;
            const pdfBase64 = await CatalogoCuentasPdf(this.itemsCatalogoCuentas, this.empresa, color);
            const fileName = "Catálogo de cuentas" + this.token.empresa + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async GeneraExcel() {
            let Catalogo = [...this.itemsCatalogoCuentas]
            Catalogo.forEach(e => {
                e.sat = e.numCuentaSat.cuenta;
            });
            const data = [...Catalogo];
            const columnsToInclude = ['numCuenta', 'descripcion', 'naturaleza', 'sat'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            const libroTrabajo = XLSX.utils.book_new();

            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['CATÁLOGO DE CUENTAS'],
                [this.token.rfc + '| ' + this.token.empresa],
                [],
                [
                    'Num. Cuenta',
                    'Descripción',
                    'Naturaleza',
                    'Num. Cuenta SAT',
                ],
                ...arrayDeValores.map((registro, index) => [
                    registro[0],
                    registro[1],
                    registro[2],
                    registro[3],
                ]),
            ]);
            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 7, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 7, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 7, r: 2 } });

            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'CATÁLOGO DE CUENTAS ' + this.token.empresa;
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');

        },

        async OpenDialogConfirm(item) {
            //VALIDAMOS SI LA CUENTA NO ESTA EN POLIZAS
            let validacion = await this.ValidaCuentaEnPolizas(item);
            if (validacion) {
                this.itemsNotDelete = [];
                this.itemsNotDelete = validacion.join('\n');
                this.dialogNotDelete = true;
            } else {
                let indice = this.itemsCatalogoCuentas.indexOf(item)
                this.dialogDelete = true;
                this.cuentaDelete = { ...item }
                this.indexDelete = indice;
                this.idCuenta = item._id;
            }
        },

        async ValidaCuentaEnPolizas(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Validando cuenta...', messageColor: 'white' })
            try {
                let response = await axios.post("CatalogoCuentas/PostCuentaEnPolizasAsync/erp_" + this.token.rfc, item);
                this.$q.loading.hide()
                return response.data;
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                return null;
            }
        },

        async DeleteCuenta() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Eliminando cuenta, espere...', messageColor: 'white' })
            try {
                console.log("id", this.idCuenta);
                let response = await axios.delete("CatalogoCuentas/DeteleCuentaAsync/erp_" + this.token.rfc + "/" + this.idCuenta);
                this.itemsCatalogoCuentas.splice(this.indexDelete, 1);
                this.dialogDelete = false;
                this.$q.notify({ type: 'positive', message: `Cuenta eliminada exitosamente.` })
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
                this.$q.loading.hide()
            }
        },

        //CONTABILIDAD ELECTRONICA
        OpenDialogCe() {
            this.dialogCe = true;
        }

    }
}
</script>

<style></style>