<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class=" text-h5">Libro Diario</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-3">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetLibroDiario">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcel">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip>
                                PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" :data="itemsPolizas" dense row-key="idPoliza">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="sm" color="accent" round dense @click="props.expand = !props.expand"
                            :icon="props.expand ? 'remove' : 'add'" />
                    </q-td>
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                    <q-td key="numPoliza" :props="props">{{ props.row.numPoliza }}</q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="totalCargo" :props="props">{{ formatCurrency(props.row.totalCargo) }}</q-td>
                    <q-td key="totalAbono" :props="props">{{ formatCurrency(props.row.totalAbono) }}</q-td>
                </q-tr>
                <!-- //TABA CON LOS ASIENTOS -->
                <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%">
                        <!-- {{ props.row.asientos }} -->
                        <q-table class="shadow-0 header-tabla" :columns="columnsAsientos" dense
                            :data="props.row.asientos" :rows-per-page-options="[0]" :pagination.sync="pagination"
                            hide-bottom>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="numCuenta" :props="props">{{ props.row.numCuenta }}</q-td>
                                    <q-td key="descripcionCuenta" :props="props">{{ props.row.descripcionCuenta
                                        }}</q-td>
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="cargo" :props="props">{{ formatCurrency(props.row.cargo) }}</q-td>
                                    <q-td key="abono" :props="props">{{ formatCurrency(props.row.abono) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { LibroDiarioPDF } from '../../PDF/LibroDiarioPDF.js'
import * as XLSX from 'xlsx';

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),

            loadingTabla: false,
            initialPagination: {
                page: 1,
                rowsPerPage: 10
            },

            columns: [
                { name: 'actions', align: 'center', label: '', field: 'actions', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'numPoliza', align: 'center', label: 'Num. Póliza', field: 'numPoliza', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'totalCargo', align: 'center', label: 'Total Cargo', field: 'totalCargo', sortable: true, style: 'text-align: right;' },
                { name: 'totalAbono', align: 'center', label: 'Total Abono', field: 'totalAbono', sortable: true, style: 'text-align: right;' },
                // { name: 'name7', align: 'center', label: 'Estado', field: 'name', sortable: true },
            ],

            columnsAsientos: [
                { name: 'numCuenta', align: 'center', label: 'Num. Cuenta', field: 'numCuenta', sortable: true, style: 'text-align: left;' },
                { name: 'descripcionCuenta', align: 'center', label: 'Desc. Cuenta', field: 'descripcionCuenta', sortable: true, style: 'text-align: left;' },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true, style: 'text-align: left;' },
                { name: 'cargo', align: 'center', label: 'Cargo', field: 'cargo', sortable: true, style: 'text-align: right;' },
                { name: 'abono', align: 'center', label: 'Abono', field: 'abono', sortable: true, style: 'text-align: right;' },
                // { name: 'name7', align: 'center', label: 'Estado', field: 'name', sortable: true },
            ],

            itemsPolizas: [],

            pagination: {
                rowsPerPage: 0
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {
    },
    methods: {

        FormatDate(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd/MMMM/yyyy', { locale: es });
            return fechaFormateada;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async GetLibroDiario() {
            this.itemsPolizas = []
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Libro Diario...', messageColor: 'white' })
            try {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let response = await axios.get('Reportes/GetLibroDiarioAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF)
                let x = response.data;
                this.itemsPolizas = [...x]
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await LibroDiarioPDF(this.itemsPolizas, this.empresa, fechaI, fechaF);
            const fileName = 'Libro_Diario_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },

        async ExportExcel() {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);

            let TablaCompleta = [];
            for (let a of this.itemsPolizas) {
                let ObjLibro = {
                    tipo: a.tipo,
                    numPoliza: a.numPoliza,
                    fecha: a.fecha,
                    descripcion: a.descripcion,
                    totalCargo: a.totalCargo,
                    totalAbono: a.totalAbono,
                };
                TablaCompleta.push(ObjLibro);
                for (let p of a.asientos) {
                    let ObjAsiento = {
                        tipo: '',
                        numPoliza: p.numCuenta,
                        fecha: p.descripcionCuenta,
                        descripcion: p.descripcion,
                        totalCargo: p.cargo,
                        totalAbono: p.abono,
                    }
                    TablaCompleta.push(ObjAsiento);
                }
            }

            //CREAMOS LOS ARRAY DE VALORES, SIN OBJETOS
            const arrayLibroDiario = TablaCompleta.map(objeto => Object.values(objeto));

            const libroTrabajo = XLSX.utils.book_new();

            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['LIBRO DIARIO'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)],
                [],
                ['TIPO', 'NUM. PÓLIZA', 'FECHA', 'DESCRIPCIÓN', 'TOTAL CARGO', 'TOTAL ABONO'],
                ...arrayLibroDiario.map((registro, index) => [
                    registro[0],
                    registro[1],
                    registro[2],
                    registro[3],
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                ]),
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 5, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 5, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 5, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'LIBRO DIARIO ' + this.empresa.rfc + '_' + this.empresa.nombre + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },
    }
}
</script>

<style></style>