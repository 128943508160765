<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-8">
                <div class=" text-h5">Devoluciones de IVA</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="getReporte()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel"
                            @click="exportToTxtFile(itemsReporte)">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" row-key="_id" :data="itemsReporte">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="uuid" :props="props">{{ props.row.uuid }}</q-td>
                    <q-td key="uuidRelacionado" :props="props">{{ props.row.uuidRelacionado }}</q-td>
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                    <q-td key="fechaEmision" :props="props">{{ props.row.fechaEmision }}</q-td>
                    <q-td key="rfcEmisor" :props="props">{{ props.row.rfcEmisor }}</q-td>
                    <q-td key="nombreEmisor" :props="props">{{ props.row.nombreEmisor }}</q-td>
                    <q-td key="rfcReceptor" :props="props">{{ props.row.rfcReceptor }}</q-td>
                    <q-td key="nombreReceptor" :props="props">{{ props.row.nombreReceptor }}</q-td>
                    <q-td key="claveProductoServicio" :props="props">{{ props.row.claveProductoServicio }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="usoCFDI" :props="props">{{ props.row.usoCFDI }}</q-td>
                    <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                    <q-td key="metodoPago" :props="props">{{ props.row.metodoPago }}</q-td>
                    <q-td key="formaPago" :props="props">{{ props.row.formaPago }}</q-td>
                    <q-td key="descuento" :props="props">{{ formatCurrency(props.row.descuento) }}</q-td>
                    <q-td key="subtotal" :props="props">{{ formatCurrency(props.row.subtotal) }}</q-td>
                    <q-td key="iva16" :props="props">{{ formatCurrency(props.row.iva16) }}</q-td>
                    <q-td key="ieps" :props="props">{{ formatCurrency(props.row.ieps) }}</q-td>
                    <q-td key="isrRetenido" :props="props">{{ formatCurrency(props.row.isrRetenido) }}</q-td>
                    <q-td key="ivaRetenido" :props="props">{{ formatCurrency(props.row.ivaRetenido) }}</q-td>
                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                    <q-td key="poliza" :props="props">{{ props.row.poliza }}</q-td>
                    <q-td key="referencia" :props="props">{{ props.row.referencia }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
function wrapCsvValue(val, formatFn) {
    let formatted = formatFn !== void 0
        ? formatFn(val)
        : val

    formatted = formatted === void 0 || formatted === null
        ? ''
        : String(formatted)

    formatted = formatted.split('"').join('""')
    /**
     * Excel accepts \n and \r in strings, but some other CSV parsers do not
     * Uncomment the next two lines to escape new lines
     */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`
}
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { exportFile } from 'quasar'
export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),

            loadingTabla: false,
            initialPagination: {
                sortBy: 'poliza',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },

            columns: [
                { name: 'uuid', align: 'center', label: 'UUID', field: 'uuid', sortable: true },
                { name: 'uuidRelacionado', align: 'center', label: 'UUID Relacionado', field: 'uuidRelacionado', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true, },
                { name: 'fechaEmision', align: 'center', label: 'Fecha de Emisión', field: 'fechaEmision', sortable: true, },
                { name: 'rfcEmisor', align: 'center', label: 'RFC Emisor', field: 'rfcEmisor', sortable: true, },
                { name: 'nombreEmisor', align: 'left', label: 'Nombre del Emisor', field: 'nombreEmisor', sortable: true, },
                { name: 'rfcReceptor', align: 'center', label: 'RFC Receptor', field: 'rfcReceptor', sortable: true, },
                { name: 'nombreReceptor', align: 'left', label: 'Nombre del Receptor', field: 'nombreReceptor', sortable: true, },
                { name: 'claveProductoServicio', align: 'left', label: 'Clave del producto o servicio', field: 'claveProductoServicio', sortable: true, },
                { name: 'descripcion', align: 'left', label: 'Descripcion', field: 'descripcion', sortable: true, },
                { name: 'usoCFDI', align: 'center', label: 'Uso del CFDI', field: 'usoCFDI', sortable: true, },
                { name: 'moneda', align: 'center', label: 'Moneda', field: 'moneda', sortable: true, },
                { name: 'metodoPago', align: 'center', label: 'Metodo de Pago', field: 'metodoPago', sortable: true, },
                { name: 'formaPago', align: 'center', label: 'Forma de Pago', field: 'formaPago', sortable: true, },
                { name: 'descuento', align: 'right', label: 'Descuento', field: 'descuento', sortable: true, },
                { name: 'subtotal', align: 'right', label: 'SubTotal', field: 'subtotal', sortable: true, },
                { name: 'iva16', align: 'right', label: 'IVA al 16', field: 'iva16', sortable: true, },
                { name: 'ieps', align: 'right', label: 'IEPS', field: 'ieps', sortable: true, },
                { name: 'isrRetenido', align: 'right', label: 'ISR Retenido', field: 'isrRetenido', sortable: true, },
                { name: 'ivaRetenido', align: 'right', label: 'IVA Retenido', field: 'ivaRetenido', sortable: true, },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true, },
                { name: 'poliza', align: 'center', label: 'Poliza', field: 'poliza', sortable: true, },
                { name: 'referencia', align: 'center', label: 'Referencia Bancaria', field: 'referencia', sortable: true, },
            ],
            itemsReporte: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {
    },
    methods: {
        async getReporte() {
            this.loadingTabla = true
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')
            this.itemsReporte = [];
            try {
                let response = await axios.get("ReportesEspeciales/ReporteDevolucionesIva/erp_" + this.token.rfc + '/' + fI + '/' + fF);
                console.log(response.data)
                this.itemsReporte = response.data;

                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },
        exportToTxtFile(data) {
            // Convertir la lista de datos en una cadena de texto con cada dato separado por "|"
            const textToSave = data.map(item => Object.values(item).join('|')).join('\n').trim();

            // Crear un elemento de enlace y configurarlo para descargar el archivo
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')

            const link = document.createElement('a');
            const fileName = 'DEVOLUCIONES DE IVA_' + this.token.rfc + '_DEL_' + fI + '_AL_' + fF;

            const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
                this.itemsReporte.map(row => this.columns.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                )).join(','))
            ).join('\r\n')

            const status = exportFile(
                fileName,
                content,
                'text/csv'
            )

            if (status !== true) {
                this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                })
            }
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>