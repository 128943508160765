<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-8">
                <div class=" text-h5">Depreciación</div>
            </div>

            <!-- FECHA DE APLICACION DEL EJERCICIO -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha de Aplicación del Ejercicio" class="q-mr-sm"
                    name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA DE DEPRECIACION -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Depreciación" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaDep">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>
            <div class="col-12 col-md-6">
                <q-select dense class="full-width" filled label="Activo Fijo" v-model="selectActivoFijo"
                    :options="itemsActivoFijo" option-label="activo_fijo" />
            </div>
            <div class="col-12 col-md-6">
                <q-select dense class="full-width" filled label="Activo Fijo" v-model="selectTipoActivoFijo"
                    :options="itemsTipoAcivoFijo" option-label="tipo_activo_fijo" />
            </div>
            <div class="col-12">
                <q-input label="Descripción Activo Fijo" filled dense v-model="descripcion"></q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="fechaAMasked" filled label="Fecha de Adquisición" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaA">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-field v-model="valorAdqui" label="Valor de la Adquisición" dense filled>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>
                </q-field>
            </div>
            <div class="col-12 col-md-2">
                <q-field v-model="selectTipoActivoFijo.porcentaje" readonly label="Porcentaje activo fijo" dense
                    class="q-mr-md" filled>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>
                </q-field>
            </div>
            <div class="col-12 col-md-2 text-left">
                <q-btn flat round color="primary" icon="mdi-tag-plus" @click="pushActivo()">
                    <q-tooltip>
                        Agregar Activo
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-calculator" @click="calculaDep()">
                    <q-tooltip>
                        Calcular
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-content-save-outline" @click="GuardarDepreciacion()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
            </div>
        </div>

        <q-table class="shadow-0 header-tabla" title="Reporte Declaración" :pagination="initialPagination"
            :loading="loadingTabla" :columns="columns" :data="itemsListaActivo" :filter="filter">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:top-right>
                <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="red-6" rounded flat dense icon="mdi-delete"
                            @click="EliminarActivo(props.row)">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="descripcionAF" :props="props">{{ props.row.descripcionAF }}</q-td>
                    <q-td key="tipoActivoFijo" :props="props">{{ props.row.tipoActivoFijo.tipo_activo_fijo }}</q-td>
                    <q-td key="fechaAdquisicion" :props="props">{{ props.row.fechaAdquisicion }}</q-td>
                    <q-td key="inversion" :props="props">{{ formatCurrency(props.row.inversion) }}</q-td>
                    <q-td key="tipoActivoFijoPorcentaje" :props="props">{{ props.row.tipoActivoFijo.porcentaje }}</q-td>
                    <q-td key="depreciacionAnual" :props="props">{{ formatCurrency(props.row.depreciacionAnual)
                        }}</q-td>
                    <q-td key="depreciacionMensual" :props="props">{{ formatCurrency(props.row.depreciacionMensual)
                        }}</q-td>
                    <q-td key="mesesDeVidaUtil" :props="props">{{ props.row.mesesDeVidaUtil }}</q-td>
                    <q-td key="mesesDepAl" :props="props">{{ props.row.mesesDepAl }}</q-td>
                    <q-td key="mesesPendientesDeDepreciar" :props="props">{{ props.row.mesesPendientesDeDepreciar
                        }}</q-td>
                    <q-td key="mesesDepreciarEnEjercicio" :props="props">{{ props.row.mesesDepreciarEnEjercicio
                        }}</q-td>
                    <q-td key="depreciacionEjercicio" :props="props">{{ formatCurrency(props.row.depreciacionEjercicio)
                        }}</q-td>
                    <q-td key="depreciacionAcumulada" :props="props">{{ formatCurrency(props.row.depreciacionAcumulada)
                        }}</q-td>
                    <q-td key="pendientesPorDepreciar" :props="props">{{
                    formatCurrency(props.row.pendientesPorDepreciar) }}</q-td>
                    <q-td key="inpMesAdquisicion" :props="props">{{ formatCurrency(props.row.inpMesAdquisicion)
                        }}</q-td>
                    <q-td key="inpcUltimoMesPrimeraMitad" :props="props">{{
                    formatCurrency(props.row.inpcUltimoMesPrimeraMitad)
                }}</q-td>
                    <q-td key="factorActualizacion" :props="props">{{ props.row.factorActualizacion
                        }}</q-td>
                    <q-td key="depreciacionFiscalEjercicio" :props="props">{{
                    formatCurrency(props.row.depreciacionFiscalEjercicio)
                        }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { Money } from 'v-money'
import { QSpinnerCube } from 'quasar'

export default {
    components: { Money },
    data() {
        return {
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },
            itemsActivoFijo: [],
            itemsTipoAcivoFijoPre: [],
            selectActivoFijo: { activo_fijo: '' },
            selectTipoActivoFijo: { tipo_activo_fijo: '' },
            descripcion: '',
            valorAdqui: 0,

            fechaI: new Date(),
            fechaDep: new Date(),
            fechaA: new Date(),
            filter: '',
            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },

            columns: [
                { name: 'actions', align: 'center', label: 'Acciones', field: 'actions', sortable: true },
                { name: 'descripcionAF', align: 'center', label: 'Descripción del activo fijo', field: 'descripcionAF', sortable: true },
                { name: 'tipoActivoFijo', align: 'center', label: 'Concepto', field: 'tipoActivoFijo', sortable: true },
                { name: 'fechaAdquisicion', align: 'center', label: 'Fecha Adquisición', field: 'fechaAdquisicion', sortable: true },
                { name: 'inversion', align: 'center', label: 'Inversión', field: 'inversion', sortable: true, },
                { name: 'tipoActivoFijoPorcentaje', align: 'center', label: 'Porcentaje de Depreciación', field: 'tipoActivoFijo', sortable: true, },
                { name: 'depreciacionAnual', align: 'center', label: 'Depreciasion Anual', field: 'depreciacionAnual', sortable: true, },
                { name: 'depreciacionMensual', align: 'center', label: 'Depreciasion Mensual', field: 'depreciacionMensual', sortable: true, },
                { name: 'mesesDeVidaUtil', align: 'center', label: 'Meses de Vida util', field: 'mesesDeVidaUtil', sortable: true, },
                { name: 'mesesDepAl', align: 'center', label: 'Meses Depreciasión a la fecha', field: 'mesesDepAl', sortable: true, },
                { name: 'mesesPendientesDeDepreciar', align: 'center', label: 'Meses pendientes de depreciar', field: 'mesesPendientesDeDepreciar', sortable: true, },
                { name: 'mesesDepreciarEnEjercicio', align: 'center', label: 'Meses por Depreciar en el ejercicio', field: 'mesesDepreciarEnEjercicio', sortable: true },
                { name: 'depreciacionEjercicio', align: 'center', label: 'Depresiacón del ejercicio', field: 'depreciacionEjercicio', sortable: true, },
                { name: 'depreciacionAcumulada', align: 'center', label: 'Depresiacón acumulada', field: 'depreciacionAcumulada', sortable: true, },
                { name: 'pendientesPorDepreciar', align: 'center', label: 'Pendiente por depreciar en el ejercicio', field: 'pendientesPorDepreciar', },
                { name: 'inpMesAdquisicion', align: 'center', label: 'INPC Mes de adquisición ', field: 'inpMesAdquisicion', sortable: true, },
                { name: 'inpcUltimoMesPrimeraMitad', align: 'center', label: 'INPC Último mes 1ra mitad', field: 'inpcUltimoMesPrimeraMitad', sortable: true, },
                { name: 'factorActualizacion', align: 'center', label: 'F.A.', field: 'factorActualizacion', sortable: true, },
                { name: 'depreciacionFiscalEjercicio', align: 'center', label: 'Dep. Fiscal del Ejercicio', field: 'depreciacionFiscalEjercicio', sortable: true },
            ],
            itemsListaActivo: [],
            listInpc: []
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaDep ? moment(this.fechaDep).format('DD/MMMM/yyyy') : ''
        },

        fechaAMasked() {
            moment.locale('es-mx');
            return this.fechaA ? moment(this.fechaA).format('DD/MMMM/yyyy') : ''
        },
        itemsTipoAcivoFijo() {
            this.selectTipoActivoFijo = Object.assign({ tipo_activo_fijo: '' })
            var lista = []
            lista = this.itemsTipoAcivoFijoPre.filter(x => x.id_activo_fijo === this.selectActivoFijo.id_activo_fijo)
            return lista;
        },
    },
    created() {
        this.GetActivosFijos();
        this.GetActivosFijosPorcen();
        this.GetInpc()
        this.GetDepreciacion()
    },
    methods: {
        async GuardarDepreciacion() {
            if (this.itemsListaActivo.length == 0) {
                this.$q.notify({ type: 'info', position: 'top-right', message: 'No hay items que guardar.' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando depreciación...', messageColor: 'white' })
            let items = []
            for (let i of this.itemsListaActivo) {
                let objeto = {
                    activoFijo: i.activoFijo,
                    tipoActivoFijo: i.tipoActivoFijo,
                    descripcionAF: i.descripcionAF,
                    fechaAdquisicion: i.fechaAdquisicion,
                    fechaAplicacion: i.fechaAplicacion,
                    inversion: i.inversion,
                    fechaDepreciacion: i.fechaDepreciacion
                }
                items.push(objeto);
            }
            try {
                let respuesta = await axios.post('ReportesEspeciales/PostDepreciacion/erp_' + this.token.rfc, items)
                console.log(respuesta.data)
                this.$q.notify({ type: 'positive', position: 'top-right', message: `Depreciación guardada exitosamente.` })
                this.$q.loading.hide()
            } catch (error) {
                console.log(error.data)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },
        pushActivo() {
            //TIPO DE ACTIVO FIIJO
            let activo = {
                folio: '0',
                activoFijo: Object.assign({}, this.selectActivoFijo),
                tipoActivoFijo: Object.assign({}, this.selectTipoActivoFijo),
                descripcionAF: this.descripcion,
                fechaAdquisicion: this.fechaA,
                fechaAplicacion: this.fechaI,
                inversion: this.valorAdqui,
                fechaDepreciacion: this.fechaDep,
                depreciacionAnual: 0,
                depreciacionMensual: 0,
                mesesDeVidaUtil: 0,
                mesesDepAl: 0,
                mesesPendientesDeDepreciar: 0,
                mesesDepreciarEnEjercicio: 0,
                depreciacionEjercicio: 0,
                depreciacionAcumulada: 0,
                pendientesPorDepreciar: 0,
                inpMesAdquisicion: 0,
                inpcUltimoMesPrimeraMitad: 0,
                factorActualizacion: 0,
                depreciacionFiscalEjercicio: 0,
            }
            this.itemsListaActivo.push(activo);
        },
        calculaDep() {
            this.loadingTabla = true
            moment.locale('es-mx');
            this.fechaI = moment(this.fechaI).format('YYYY/MM/DD');
            this.fechaDep = moment(this.fechaDep).format('YYYY/MM/DD');
            try {
                for (var i of this.itemsListaActivo) {
                    let depAnual = i.inversion * (i.tipoActivoFijo.porcentaje / 100)
                    let depMensual = depAnual / 12
                    let mesesVidaUtil = (1 / (i.tipoActivoFijo.porcentaje / 100)) * 12

                    //CONVERTIR LAS FECHAS
                    let fechaA = this.fechaI.split('/')
                    let fechaB = i.fechaAdquisicion.split('/')
                    let fechaC = this.fechaDep.split('/')

                    let fechaAplicacion = new Date(fechaA[0], fechaA[1], fechaA[2])
                    let fechaAdquicicion = new Date(fechaB[0], fechaB[1], fechaB[2])
                    let fechaDepreciacion = new Date(fechaC[0], fechaC[1], fechaC[2])
                    console.log(fechaB[0]);
                    console.log(fechaB[1]);
                    console.log(fechaC[0]);
                    console.log(fechaC[1]);

                    var diferencia = this.monthDiff(parseInt(fechaB[0]), parseInt(fechaB[1]), parseInt(fechaC[0]), parseInt(fechaC[1]));
                    console.log(diferencia)
                    var mesesDep = mesesVidaUtil
                    if (diferencia < mesesVidaUtil) {
                        mesesDep = diferencia
                    }

                    let pendientes = mesesVidaUtil - mesesDep

                    let mesesDepEjercicio = 0
                    if (pendientes >= 12) {
                        mesesDepEjercicio = 12
                    }
                    else if (pendientes < 12) {
                        mesesDepEjercicio = pendientes
                    } else if (pendientes <= 0) {
                        mesesDepEjercicio = 0
                    }


                    let depreciacionDelEjercicio = 0
                    depreciacionDelEjercicio = depMensual * mesesDepEjercicio

                    let depreciciacionAcumulada = 0
                    depreciciacionAcumulada = depMensual * mesesDep + depreciacionDelEjercicio

                    let pendientePorDpreciar = 0
                    pendientePorDpreciar = i.inversion - depreciciacionAcumulada

                    //OBTENEMOS INPC DEPENDIENDO DEL AÑO Y MES DE ADQUIICICION
                    let ObjInpc = this.listInpc.find(x => x.anio == fechaB[0])
                    console.log(fechaAdquicicion)
                    let ObjMes = this.getLongMonthNameInt(parseInt(fechaB[1]) - 1);
                    var inpcMes = ObjInpc[ObjMes];

                    //INPC ULTIMO MES PRIMERA MITAD
                    let fechaInicial = this.fechaI;
                    var dayFechaInicial = fechaInicial.split('/');
                    console.log(dayFechaInicial);
                    let mitadFecha = dayFechaInicial[1] / 2
                    let fechaNueva_ = new Date(dayFechaInicial[0], mitadFecha - 1, 1);
                    let ObjMitadFecha = this.listInpc.find(x => x.anio == dayFechaInicial[0])
                    let ObjtMesMitadFecha = this.getLongMonthName(fechaNueva_);
                    let inpcMitadFecha = ObjMitadFecha[ObjtMesMitadFecha]

                    //FACTOR DE ACTUALIZACION
                    let factorActualizacion = 0;
                    factorActualizacion = inpcMitadFecha / inpcMes;

                    //DEPRESIACION FISCAL DEL EJERCICIO
                    let depreciacionFiscalEjercicio = 0;
                    depreciacionFiscalEjercicio = depreciacionDelEjercicio * factorActualizacion

                    i.depreciacionAnual = Math.round(depAnual * 100) / 100
                    i.depreciacionMensual = Math.round(depMensual * 100) / 100
                    i.mesesDeVidaUtil = Math.round(mesesVidaUtil * 100) / 100
                    i.mesesDepAl = Math.round(mesesDep * 100) / 100
                    i.mesesPendientesDeDepreciar = Math.round(pendientes * 100) / 100
                    i.mesesDepreciarEnEjercicio = Math.round(mesesDepEjercicio * 100) / 100
                    i.depreciacionEjercicio = Math.round(depreciacionDelEjercicio * 100) / 100
                    i.depreciacionAcumulada = Math.round(depreciciacionAcumulada * 100) / 100
                    i.pendientesPorDepreciar = Math.round(pendientePorDpreciar * 100) / 100
                    i.inpMesAdquisicion = Math.round(inpcMes * 1000000) / 1000000
                    i.inpcUltimoMesPrimeraMitad = Math.round(inpcMitadFecha * 1000000) / 1000000
                    i.factorActualizacion = Math.round(factorActualizacion * 10000) / 10000
                    i.depreciacionFiscalEjercicio = Math.round(depreciacionFiscalEjercicio * 100) / 100
                    // this.listActivo[i].depreciacionAnual = Math.round(depAnual * 100) / 100
                }
                this.loadingTabla = false

            } catch (error) {
                console.log(error)
                this.loadingTabla = false

            }
        },
        monthDiff(year1, month1, year2, month2) {
            var months;
            months = (year2 - year1) * 12;
            months -= (month1 - 1); // Resta 1 al mes inicial para incluirlo en el cálculo
            months += month2;
            return months <= 0 ? 0 : months;
        },

        // monthDiff(d1, d2) {
        //     var months;
        //     months = (d2.getFullYear() - d1.getFullYear()) * 12;
        //     months -= d1.getMonth();
        //     months += d2.getMonth();
        //     return months <= 0 ? 0 : months;
        // },
        getLongMonthName(date) {

            const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
            return monthNames[date.getMonth()];
        },
        getLongMonthNameInt(date) {
            console.log(date)
            const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
            return monthNames[date];
        },
        EliminarActivo(item) {
            let index = this.itemsListaActivo.indexOf(item)
            this.itemsListaActivo.splice(index, 1)
        },

        async GetDepreciacion() {
            this.loadingTabla = true
            try {
                let respuesta = await axios.get('ReportesEspeciales/GetDepreciacion/erp_' + this.token.rfc)
                console.log(respuesta)
                this.itemsListaActivo = respuesta.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error.data)
                this.loadingTabla = false

            }
        },
        async GetActivosFijos() {

            try {
                let respuesta = await axios.get('ReportesEspeciales/GetActivoFijo/erp_' + this.token.rfc)
                console.log(respuesta)
                this.itemsActivoFijo = respuesta.data;
            } catch (error) {
                console.log(error.data)
            }
        },
        async GetActivosFijosPorcen() {
            try {
                let respuesta = await axios.get('ReportesEspeciales/GetActivoFijoPorcentaje/erp_' + this.token.rfc)
                console.log(respuesta)
                this.itemsTipoAcivoFijoPre = respuesta.data;
            } catch (error) {
                console.log(error.data)
            }
        },
        async GetInpc() {
            try {
                let respuesta = await axios.get('ReportesEspeciales/GetInpc/')
                this.listInpc = respuesta.data;
            } catch (error) {
                console.log(error)
            }
        },
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>