import { render, staticRenderFns } from "./RelacionesFinancieras.vue?vue&type=template&id=1dba241e&"
import script from "./RelacionesFinancieras.vue?vue&type=script&lang=js&"
export * from "./RelacionesFinancieras.vue?vue&type=script&lang=js&"
import style0 from "./RelacionesFinancieras.vue?vue&type=style&index=0&id=1dba241e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QInput,QIcon,QPopupProxy,QDate,QBtn,QTooltip,QList,QExpansionItem,QItemSection,QTable,QTr,QTd});qInstall(component, 'directives', {ClosePopup});
