import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

const PolizaPDF = async (ObjPoliza, objEmpresa, objColor) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = objColor

            //LOGO
            if (objEmpresa) {
                if (objEmpresa.logo) {
                    if (objEmpresa.logo.base64 != '') {
                        doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
                    }
                }
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            if (objEmpresa.rfc) {
                doc.text(objEmpresa.rfc, 206, yCabecera, { align: 'right' });
            } else {
                doc.text("", 206, yCabecera, { align: 'right' });
            }
            if (objEmpresa.nombre) {
                doc.setFontSize(12);
                doc.text(objEmpresa.nombre, 206, yCabecera += 5, { align: 'right' });
            } else {
                doc.setFontSize(12);
                doc.text("", 206, yCabecera += 5, { align: 'right' });
            }
            doc.text(ObjPoliza.tipo + ' | ' + ObjPoliza.numPoliza, 206, yCabecera += 5, { align: 'right' });
            doc.text(formtaoFecha(ObjPoliza.fecha), 206, yCabecera += 6, { align: 'right' });

            //DESCRIPCION
            // Definir el color de la barra
            const width = 201;
            const height = .5;

            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yCabecera += 7, width, height, 'F');

            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold')
            doc.text('Descripción', 110, yCabecera += 5, { align: 'center' });
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            let descripcion_ = doc.splitTextToSize(ObjPoliza.descripcion, 200);
            let descripcion_Height = doc.getTextDimensions(descripcion_).h;
            doc.text(descripcion_, 110, yCabecera += 5, { align: 'center' });

            // Definir el color de la barra
            const widthT = 201;
            const heightT = 6;
            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yCabecera + 5, widthT, heightT, 'F');
            doc.setFontSize(10);

            doc.setTextColor("#fff");
            doc.text('Cuenta', 7, yCabecera += 9, { align: 'left' });
            doc.text('Descripción del Asiento', 70, yCabecera, { align: 'left' });
            doc.text('Cargo', 145, yCabecera, { align: 'left' });
            doc.text('Abono', 180, yCabecera, { align: 'left' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            let yLista = yCabecera + 2;
            for (let c of ObjPoliza.asientos) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(9);
                doc.setTextColor("#000");

                let cuenta = c.cuenta.cuenta
                let descripcion = c.descripcion
                let cargo = c.cargo
                let abono = c.abono

                //doc.text(cuenta, 7, yLista += 7, { align: 'left' });
                doc.text(formatoNumerico(cargo), 175, yLista += 5, { align: 'right' });
                doc.text(formatoNumerico(abono), 205, yLista, { align: 'right' });

                let cuenta_ = doc.splitTextToSize(cuenta, 55);
                let cuenta_Height = doc.getTextDimensions(cuenta_).h;

                let concepto_ = doc.splitTextToSize(descripcion, 70);
                let concepto_Height = doc.getTextDimensions(concepto_).h;

                doc.text(cuenta_, 7, yLista, { align: 'left' });
                doc.text(concepto_, 70, yLista, { align: 'left' });

                if (cuenta_Height > concepto_Height) {
                    yLista += cuenta_Height + 1
                }
                else {
                    yLista += concepto_Height + 1
                }
                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yLista > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yLista = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    yLista -= 3;
                    doc.line(5, yLista, 205, yLista);
                }
            }
            // CAPITAL
            let yTotalActivo = yLista + 1;
            let xTotalActivo = 17;

            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(5, yTotalActivo, 201, 9, 'F');
            doc.text('TOTAL:', 7, yTotalActivo += 6, { align: 'left' });
            doc.text(formatoNumerico(ObjPoliza.abono), 205, yTotalActivo, { align: 'right' });
            doc.text(formatoNumerico(ObjPoliza.cargo), 160, yTotalActivo, { align: 'right' });

            // Definir el color de la barra
            const widthx = 201;
            const heightx = 8;

            let yXML = yTotalActivo + 5

            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yXML, widthx, heightx, 'F');
            doc.setFontSize(6);

            doc.setTextColor("#fff");
            doc.text('Serie/Folio', 7, yXML += 5, { align: 'left' });
            doc.text('Tipo', 25, yXML, { align: 'left' });
            doc.text('Fecha', 32, yXML, { align: 'left' });
            doc.text('RFC', 52, yXML, { align: 'left' });
            doc.text('Nombre', 70, yXML, { align: 'left' });
            doc.text('Forma Pago', 89, yXML, { align: 'left' });
            doc.text('Metodo Pago', 108, yXML, { align: 'left' });
            doc.text('Moneda', 126, yXML, { align: 'left' });
            doc.text('Descuento', 137, yXML, { align: 'left' });
            doc.text('SubTotal', 155, yXML, { align: 'left' });
            doc.text('Total', 173, yXML, { align: 'left' });
            doc.text('Folio Fiscal', 187, yXML, { align: 'left' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            let yListaXml = yXML + 2;

            for (let c of ObjPoliza.listaXml) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(6);
                doc.setTextColor("#000");

                let serieFolio = c.serie + ' | ' + c.folio
                let tipoComprobante = c.tipoComprobante
                let fecha = c.fecha
                let rfc = c.rfc
                let nombre = c.nombre
                let subtotal = c.subTotal
                let total = c.total
                let descuento = c.descuento
                let formaPago = c.formaPago
                let metodoPago = c.metodoPago
                let moneda = c.moneda
                let fechaPago = c.fechaPago
                let folioFiscal = c.folioFiscal

                doc.text(serieFolio, 5, yListaXml += 7, { align: 'left' });
                doc.text(tipoComprobante, 25, yListaXml, { align: 'left' });
                doc.text(formtaoFecha(fecha), 32, yListaXml, { align: 'left' });
                doc.setFontSize(5);
                doc.text('Fecha Pago:', 32, yListaXml + 3, { align: 'left' });
                doc.setFontSize(6);
                console.log(fechaPago);
                doc.text(formtaoFecha(fechaPago), 32, yListaXml + 6, { align: 'left' });
                doc.text(rfc, 52, yListaXml, { align: 'left' });
                doc.text(moneda, 127, yListaXml, { align: 'left' });
                doc.setFontSize(5.5);

                doc.text(formatoNumerico(descuento), 150, yListaXml, { align: 'right' });
                doc.text(formatoNumerico(subtotal), 166, yListaXml, { align: 'right' });
                doc.text(formatoNumerico(total), 182, yListaXml, { align: 'right' });
                doc.setFontSize(6);

                let nombre_ = doc.splitTextToSize(nombre, 20);
                let nombre_Height = doc.getTextDimensions(nombre_).h;
                doc.setFontSize(5.5);

                let forma_ = doc.splitTextToSize(formaPago, 15);
                let forma_Height = doc.getTextDimensions(forma_).h;

                let metdodo_ = doc.splitTextToSize(metodoPago, 15);
                let metodo_Height = doc.getTextDimensions(metdodo_).h;

                let folioFiscal_ = doc.splitTextToSize(folioFiscal, 19);
                let folioFiscal_Height = doc.getTextDimensions(folioFiscal_).h;
                doc.setFontSize(6);


                //doc.text(nombre, 105, yListaXml, { align: 'left' });

                doc.text(nombre_, 70, yListaXml, { align: 'left' });
                doc.text(forma_, 89, yListaXml, { align: 'left' });
                doc.text(metdodo_, 107, yListaXml, { align: 'left' });
                doc.text(folioFiscal_, 185, yListaXml, { align: 'left' });

                yListaXml += nombre_Height + 1
                //yListaXml += folioFiscal_Height + 1

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yListaXml > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yListaXml = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(5, yListaXml += 2, 205, yListaXml);
                }
            }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }



            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { PolizaPDF };
