<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">REPORTES</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" behavior="mobile" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="320" :breakpoint="500" bordered content-class="bg-purple-1">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>

                    <q-item active clickable v-ripple @click="irBalanza">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-scale-balance" />
                        </q-item-section>
                        <q-item-section>
                            Balanza
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irEstadoResultados">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-file-chart" />
                        </q-item-section>
                        <q-item-section>
                            Estado de Resultados
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irEstadoSituacionFinanciera">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-file-chart" />
                        </q-item-section>
                        <q-item-section>
                            Estado de Situación Financiera
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <!-- <q-item active clickable v-ripple @click="irEstadoSituacionFinancieraAnual">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-file-chart" />
                        </q-item-section>
                        <q-item-section>
                            Estado de Situación Financiera Anual
                        </q-item-section>
                    </q-item>

                    <q-separator /> -->

                    <q-item active clickable v-ripple @click="irLibroDiario">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-book-open-variant" />
                        </q-item-section>
                        <q-item-section>
                            Libro Diario
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irRelacionesFinancieras">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-file-sign" />
                        </q-item-section>
                        <q-item-section>
                            Relaciones Financieras
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irMayores()">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-book" />
                        </q-item-section>
                        <q-item-section>
                            Mayores
                        </q-item-section>
                    </q-item>

                    <q-separator />

                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <!-- <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer> -->
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"


export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {

        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {
        irBalanza() {
            this.$router.push({ name: 'Balanza' })
        },
        irEstadoResultados() {
            this.$router.push({ name: 'EstadoResultados' })
        },
        irEstadoSituacionFinanciera() {
            this.$router.push({ name: 'EstadoSituacionFinanciera' })
        },

        irEstadoSituacionFinancieraAnual() {
            this.$router.push({ name: 'EstadoSituacionFinancieraAnual' })
        },

        irLibroDiario() {
            this.$router.push({ name: 'LibroDiario' })
        },

        irRelacionesFinancieras() {
            this.$router.push({ name: 'RelacionesFinancieras' })
        },

        irMayores() {
            this.$router.push({ name: 'Mayores' })
        },

    }
}
</script>
