<template>
    <div>
        <div class="row q-col-gutter-sm q-ma-sm">
            <div class="col-12 ">
                <q-toolbar class="bg-primary text-white">
                    <q-toolbar-title>
                        Configuración
                    </q-toolbar-title>
                    <q-btn flat round dense class="q-mr-sm" icon="mdi-image-off" @click="eliminarLogo()"><q-tooltip>
                            Eliminar Logo
                        </q-tooltip></q-btn>
                    <q-btn flat round dense icon="mdi-content-save" @click="guardarOactualizar()">
                        <q-tooltip>
                            Guardar
                        </q-tooltip></q-btn>
                </q-toolbar>
            </div>
            <div class="col-12 col-md-6">
                <div class="row q-col-gutter-sm">
                    <!-- RFC -->
                    <div class="col-12 col-md-6 " color="bg-primary">
                        <q-input dense outlined v-model="empresa.rfc" label="RFC" />
                    </div>
                    <!-- CODIGO POSTAL -->
                    <div class="col-12 col-md-6 " color="bg-primary">
                        <q-input dense outlined v-model="empresa.domicilioFiscal" label="Código Postal" />
                    </div>
                    <!-- NOMBRE -->
                    <div class="col-12" color="bg-primary">
                        <q-input dense outlined v-model="empresa.nombre" label="Nombre" />
                    </div>
                    <!-- REGIMEN FISCAL -->
                    <template v-if="ValidaRegimen != ''">
                        <div class="col-12">
                            <q-select dense class="full-width" outlined fill-input v-model="empresa.regimenFiscal"
                                :options="regimenFiscal" label="Régimen Fiscal" option-label="regimenFiscal" />
                        </div>
                    </template>
                    <!-- CARGAR LOGO -->
                    <div class="col-12 col-md-12 " color="bg-primary">
                        <q-bar dense class=" bg-primary text-white">
                            <q-space />
                            <div>Logo</div>
                            <q-space />
                        </q-bar>
                        <q-input @input="uploadImage" outlined type="file" accept=".jpg,.jpeg,.png" />
                    </div>
                    <!-- COLOR DE LOS REPORTES -->
                    <div class="col-12 col-md-12 " color="bg-primary">
                        <q-bar dense class=" bg-primary text-white">
                            <q-space />
                            <div>Color de los reportes</div>
                            <q-space />
                        </q-bar>
                        <q-input dense v-model="empresa.color" :rules="['anyColor']" hint="With validation"
                            class="my-input">
                            <template v-slot:append>
                                <q-icon name="colorize" class="cursor-pointer">
                                    <q-popup-proxy transition-show="scale" transition-hide="scale">
                                        <q-color v-model="empresa.color" />
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row q-col-gutter-sm">
                    <div class="col-12 col-md-12  text-center" color="bg-primary">
                        <q-img :src="empresa.logo.base64" style=" width: 600px;">
                            <div class=" absolute-bottom text-subtitle1 text-center">
                                LOGO DE LA EMPRESA
                            </div>
                        </q-img>
                    </div>
                </div>
            </div>
        </div>
        <div class="row q-col-gutter-sm q-ma-sm">
            <div class="col-12">
                <q-toolbar class="bg-primary text-white">
                    <q-toolbar-title>
                        Correo
                    </q-toolbar-title>
                </q-toolbar>
            </div>
        </div>
        <div class="row q-col-gutter-sm q-ma-sm">
            <!-- NOMBRE DEL EMISOR -->
            <div class="col-12 col-md-6">
                <q-input dense outlined v-model="empresa.nombreCorreo" label="Nombre del emisor" />
            </div>
            <!-- CORREO ELECTRONICO -->
            <div class="col-12 col-md-6">
                <q-input dense outlined v-model="empresa.correo" label="Correo" />
            </div>
            <!-- CONTRASEÑA DEL CORRREO -->
            <div class="col-12 col-md-4">
                <q-input dense outlined v-model="empresa.password" :type="isPwd ? 'password' : 'text'">
                    <template v-slot:append>
                        <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                            @click="isPwd = !isPwd" />
                    </template>
                </q-input>
            </div>
            <!-- PUERTO -->
            <div class="col-12 col-md-4">
                <q-input dense outlined v-model="empresa.puerto" label="Puerto" />
            </div>
            <!-- HOST -->
            <div class="col-12 col-md-4">
                <q-input dense outlined v-model="empresa.host" label="Host" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { resizeImagePixels } from '../../plugins/rezizeImage.js'
import blancoImage from '../../assets/blanco.png';
import { QSpinnerCube } from 'quasar'

export default {
    components: {},
    data() {
        return {
            isPwd: true,
            regimenFiscal: []
        };
    },
    mounted() {

    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },

        token() {
            return this.$store.state.usuario;
        },

        correo() {
            return this.$store.state.correoStore;
        },

        regimenFiscalDefault() {
            return this.$store.state.regimenFiscalStore;
        },

        ValidaRegimen() {
            let rfc = this.$store.state.empresaStore.rfc
            if (rfc.length === 12) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.moral === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                return 'M';
            } else if (rfc.length === 13) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.fisica === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                if (rfc === 'XAXX010101000' || rfc === 'XEXX010101000') {
                    let b = filtroRegimen.find(f => f.clave === '616');
                    this.$store.state.empresaStore.regimenFiscalReceptor = b;
                }
                return 'F';
            }
            return "";
        },
    },
    created() {
        this.GetRegimenFiscal();
        this.GetEmpresa();

    },
    methods: {
        async GetRegimenFiscal() {
            if (this.$store.state.regimenFiscalStore.length == 0) {
                try {
                    0
                    let response = await axios.get("https://api-timbrado.contago.com.mx/api/CatalogosSat/GetCatRegimenFiscalAsync/");
                    this.$store.state.regimenFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        guardarOactualizar() {
            if (this.empresa._id == 0 || this.empresa._id == null) {
                this.PostEmpresa();
            } else {
                this.PutEmpresa();
            }
        },

        async PostEmpresa() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: '<b>Guardando Información...' })
            try {
                let response = await axios.post('Empresa/PostEmpresa/erp_' + this.token.rfc, this.empresa);
                console.log(response.data)
                this.$q.notify({ type: 'positive', message: 'Configuración guardada exitosamente.' })
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique la información e intentelo de nuevo.' })
                this.$q.loading.hide()
            }
        },

        async PutEmpresa() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: '<b>Guardando Información...' })
            try {
                let response = await axios.put('Empresa/PutEmpresa/erp_' + this.token.rfc, this.empresa)
                console.log(response.data)
                this.$q.notify({ type: 'positive', message: 'Configuración guardada exitosamente.' })
                this.$q.loading.hide()

            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique la información e intentelo de nuevo.' })
                this.$q.loading.hide()
            }
        },

        async GetEmpresa() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Cargando datos de la empresa...', messageColor: 'white' })
            try {
                let response = await axios.get('Empresa/GetEmpresa/erp_' + this.token.rfc + '/' + this.token.rfc);
                // console.log(response.data)
                this.$store.state.empresaStore = { ...response.data }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$store.state.empresaStore = {
                    _id: '',
                    rfc: '',
                    nombre: '',
                    regimenFiscal: null,
                    domicilioFiscal: '',
                    logo: {
                        base64: '',
                    },
                    correo: '',
                    password: '',
                    nombreCorreo: '',
                    host: '',
                    puerto: '',
                    noCre: '',
                }
                this.$q.loading.hide()
            }
        },

        eliminarLogo() {
            this.rutaToBase64(blancoImage)
        },

        rutaToBase64(imagePath) {
            var img = new Image();
            img.src = imagePath;

            img.onload = () => {
                // Crea un canvas para dibujar la imagen
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                var base64String = canvas.toDataURL('image/png');
                this.$store.state.empresaStore.logo.base64 = base64String;
            };

        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        async uploadImage(event) {
            const file = event[0];
            try {
                const resizedImageBase64 = await resizeImagePixels(file, 600, 400);
                console.log(resizedImageBase64);
                this.$store.state.empresaStore.logo.base64 = resizedImageBase64;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
    },
};
</script>
<style>