import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const ReporteMovBancosPdf = async (ObjBalanza, objEmpresa, fechaI, fechaF, objColor) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = objColor;

            //LOGO
            if (objEmpresa.logo.base64 != '') {
                doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('REPORTE DE MOVIMIENTOS BANCARIOS', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });

            // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            let periodo = 'PERIODO DEL ' + FormatoFechaSinHora(fechaI) + ' AL ' + FormatoFechaSinHora(fechaF);
            doc.text(periodo, 210, yCabecera += 10, { align: 'right' });


            // Definir el color de la barra
            const widthT = 210;
            const heightT = 8;
            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yCabecera + 5, widthT, heightT, 'F');
            doc.setFontSize(10);
            doc.setTextColor("#FFFFFF");
            doc.text('Fecha', 10, yCabecera += 10, { align: 'left' });
            doc.text('Descripción', 28, yCabecera, { align: 'left' });
            doc.text('Referencia', 100, yCabecera, { align: 'left' });
            doc.text('Depósito', 180, yCabecera, { align: 'right' });
            doc.text('Retiro', 210, yCabecera, { align: 'right' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            let yLista = yCabecera + 2;
            for (let c of ObjBalanza) {
                // console.log(c)
                doc.setFontSize(8);
                if (c.tipo === "Ingreso") {
                    // doc.setTextColor("#19775C");
                    doc.setTextColor("#000000");
                } else if (c.tipo === "Egreso") {
                    doc.setTextColor("#C73133");
                }

                let concepto_ = doc.splitTextToSize(c.concepto, 70);
                let concepto_Height = doc.getTextDimensions(concepto_).h;
                let referencia_ = doc.splitTextToSize(c.referencia, 70);
                let referencia_Height = doc.getTextDimensions(referencia_).h;


                doc.text(c.fecha.substring(0, 10), 10, yLista += 5, { align: 'left' });
                doc.text(concepto_, 28, yLista, { align: 'left' });
                doc.text(referencia_, 100, yLista, { align: 'left' });
                doc.text(formatoNumerico(c.deposito), 180, yLista, { align: 'right' });
                doc.text(formatoNumerico(c.retiro), 210, yLista, { align: 'right' });

                let espacio = 0;
                if (concepto_Height <= referencia_Height) {
                    espacio = referencia_Height - 2;
                } else {
                    espacio = concepto_Height - 2;
                }
                yLista += espacio;

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yLista > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yLista = 10;
                } else {
                    // const color_ = "#662E91"
                    doc.setDrawColor(color);
                    doc.setLineWidth(.1);
                    doc.line(5, yLista, 210, yLista + 1);
                }
            }
            let sumaDeposito = ObjBalanza.reduce((a, b) => a + b.deposito, 0);
            let sumaRetiro = ObjBalanza.reduce((a, b) => a + b.retiro, 0);

            //TOTALES
            let yTotalBalanza = yLista;
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);
            doc.rect(5, yTotalBalanza, 210, 9, 'F');
            doc.text('TOTALES:', 10, yTotalBalanza += 6, { align: 'left' });
            doc.text(formatoNumerico(sumaDeposito), 180, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumaRetiro), 210, yTotalBalanza, { align: 'right' });

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { ReporteMovBancosPdf };