import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const EstadoSituacionFinancieraPDF = async (objActivoCirculante, objActivoFijo, objActivoDiferido, objPasivoCirculante, objPasivoLargoPlazo, objPasivoDiferido, objCapital, objEmpresa, fechaI, fechaF) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter',
                orientation: 'landscape',
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#662e91'

            //LOGO
            if (objEmpresa.logo.base64 != '') {
                doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('ESTADO DE SITUACIÓN FINANCIERA', 270, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 270, yCabecera += 5, { align: 'right' });

            // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            let periodo = 'PERIODO DEL ' + FormatoFechaSinHora(fechaI) + ' AL ' + FormatoFechaSinHora(fechaF);
            doc.text(periodo, 270, yCabecera += 5, { align: 'right' });

            // APARTADO DE LAS CABECERAS
            doc.text('ACTIVO', 80, yCabecera += 10, { align: 'center' });
            doc.text('PASIVO Y CAPITAL', 200, yCabecera, { align: 'center' });

            //APARTADO DEL ACTIVO
            //DEFINIR EL TAMAÑO DE LA BARRA
            const widthT = 130;
            const heightT = 8;

            // Dibujar la barra de color
            let yActivo = yCabecera;
            let yPasivo = yCabecera;
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.rect(5, yActivo + 1, widthT, heightT, 'F');

            //ACTIVO CIRCULANTE
            let sumaActivoCirculante = objActivoCirculante.reduce((a, b) => a + b.importe, 0);
            doc.text('Activo Circulante', 10, yActivo += 6, { align: 'left' });
            doc.text(formatoNumerico(sumaActivoCirculante), 134, yActivo, { align: 'right' });
            yActivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objActivoCirculante) {
                doc.text(a.descripcion, 10, yActivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 134, yActivo, { align: 'right' });
            }

            //ACTIVO FIJO
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(5, yActivo + 5, widthT, heightT, 'F');
            let sumaActivoFijo = objActivoFijo.reduce((a, b) => a + b.importe, 0);
            doc.text('Activo Fijo', 10, yActivo += 10, { align: 'left' });
            doc.text(formatoNumerico(sumaActivoFijo), 134, yActivo, { align: 'right' });
            yActivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            yActivo += 4;
            for (let a of objActivoFijo) {
                let descripcion = a.descripcion;
                let descripcion_ = doc.splitTextToSize(descripcion, 96);
                let descripcionHeight = doc.getTextDimensions(descripcion_).h;

                doc.text(descripcion_, 10, yActivo, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 134, yActivo, { align: 'right' });
                yActivo += descripcionHeight;
            }
            yActivo = yActivo - 2;
            //ACTIVO FIJO
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(5, yActivo + 5, widthT, heightT, 'F');
            let sumaActivoDiferido = objActivoDiferido.reduce((a, b) => a + b.importe, 0);
            doc.text('Activo Fijo', 10, yActivo += 10, { align: 'left' });
            doc.text(formatoNumerico(sumaActivoDiferido), 134, yActivo, { align: 'right' });
            yActivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objActivoDiferido) {
                doc.text(a.descripcion, 10, yActivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 134, yActivo, { align: 'right' });
            }

            //PASIVO CIRCULANTE
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(140, yPasivo + 1, widthT, heightT, 'F');
            let sumaPasivoCirculante = objPasivoCirculante.reduce((a, b) => a + b.importe, 0);
            doc.text('Pasivo Circulante', 145, yPasivo += 6, { align: 'left' });
            doc.text(formatoNumerico(sumaPasivoCirculante), 268, yPasivo, { align: 'right' });
            yPasivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objPasivoCirculante) {
                doc.text(a.descripcion, 145, yPasivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 268, yPasivo, { align: 'right' });
            }

            //PASIVO LARGO PLAZO
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(140, yPasivo + 5, widthT, heightT, 'F');
            let sumaPasivoLargoPlazo = objPasivoLargoPlazo.reduce((a, b) => a + b.importe, 0);
            doc.text('Pasivo Largo Plazo', 145, yPasivo += 10, { align: 'left' });
            doc.text(formatoNumerico(sumaPasivoLargoPlazo), 268, yPasivo, { align: 'right' });
            yPasivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objPasivoLargoPlazo) {
                doc.text(a.descripcion, 145, yPasivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 268, yPasivo, { align: 'right' });
            }

            //PASIVO DIFERIDO
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(140, yPasivo + 5, widthT, heightT, 'F');
            let sumaPasivoDiferido = objPasivoDiferido.reduce((a, b) => a + b.importe, 0);
            doc.text('Pasivo Diferido', 145, yPasivo += 10, { align: 'left' });
            doc.text(formatoNumerico(sumaPasivoDiferido), 268, yPasivo, { align: 'right' });
            yPasivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objPasivoDiferido) {
                doc.text(a.descripcion, 145, yPasivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 268, yPasivo, { align: 'right' });
            }

            //CAPITAL
            doc.setFillColor(color);
            doc.setTextColor("#fff");
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.rect(140, yPasivo + 5, widthT, heightT, 'F');
            let sumaCapital = objCapital.reduce((a, b) => a + b.importe, 0);
            doc.text('Capital', 145, yPasivo += 10, { align: 'left' });
            doc.text(formatoNumerico(sumaCapital), 268, yPasivo, { align: 'right' });
            yPasivo += 3;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");
            for (let a of objCapital) {
                doc.text(a.descripcion, 145, yPasivo += 4, { align: 'left' });
                doc.text(formatoNumerico(a.importe), 268, yPasivo, { align: 'right' });
            }

            //AGREGAMOS LAS SUMAS FINALES
            let sumaActivos = sumaActivoCirculante + sumaActivoFijo + sumaActivoDiferido;
            let sumaPasivosYCapital = sumaPasivoCirculante + sumaPasivoLargoPlazo + sumaPasivoDiferido + sumaCapital;
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('TOTAL ACTIVO: ', 10, doc.internal.pageSize.getHeight() - 20, { align: 'left' });
            doc.text(formatoNumerico(sumaActivos), 134, doc.internal.pageSize.getHeight() - 20, { align: 'right' });
            doc.text('TOTAL PASIVO + CAPITAL: ', 145, doc.internal.pageSize.getHeight() - 20, { align: 'left' });
            doc.text(formatoNumerico(sumaPasivosYCapital), 268, doc.internal.pageSize.getHeight() - 20, { align: 'right' });

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 268, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { EstadoSituacionFinancieraPDF };