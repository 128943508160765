import jsPDF from 'jspdf';
import moment from 'moment'

function formatoCantidad(numero) {
    let respuesta = numero.toFixed(3);
    return respuesta;
}

function formatoImpuestos(numero) {
    let respuesta = numero.toFixed(4);
    return respuesta;
}

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY HH:mm:ss')
}

const ComprobantePagoBase64 = async (ObjComprobante, ObjTipoComprobante, ObjEstatus, ObjColor, ObjQr) => {
    return new Promise((resolve, reject) => {
        try {

            const doc = new jsPDF();

            //CABECERA 
            let xCabecera = 10;
            let yCabecera = 15;

            //DATOS DE LA EMPRESA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text(ObjTipoComprobante, 200, yCabecera, { align: 'right' });
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(ObjComprobante.emisor.rfc, 200, yCabecera += 5, { align: 'right' });
            doc.text(ObjComprobante.emisor.nombre, 200, yCabecera += 5, { align: 'right' });
            doc.setFontSize(9);
            doc.text(ObjComprobante.emisor.regimenFiscal.regimenFiscal, 200, yCabecera += 5, { align: 'right' });

            //DATOS DEL CLIENTE
            let xCliente = xCabecera;
            let yCliente = yCabecera += 10;
            doc.setFontSize(12);
            doc.text('Cliente: ', xCliente, yCliente += 5, { align: 'left' });
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            let datosReceptor = doc.splitTextToSize(ObjComprobante.receptor.rfc + ' | ' + ObjComprobante.receptor.nombre, 130);
            let datosReceptor_Height = doc.getTextDimensions(datosReceptor).h;
            doc.text(datosReceptor, xCliente, yCliente += 5, { align: 'left' });
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(9);
            let regimenFiscalReceptor = '';
            if (ObjComprobante.receptor.regimenFiscalReceptor.regimenFiscalReceptor) {
                regimenFiscalReceptor = ObjComprobante.receptor.regimenFiscalReceptor.regimenFiscalReceptor
            }
            doc.text(ObjComprobante.receptor.usoCfdi.usoCfdi, xCliente, yCliente += datosReceptor_Height, { align: 'left' });
            doc.text(ObjComprobante.receptor.domicilioFiscalReceptor, xCliente, yCliente += 5, { align: 'left' });
            doc.text(regimenFiscalReceptor, xCliente, yCliente += 5, { align: 'left' });
            // doc.text(ObjComprobante.cliente.direccion, xCliente, yCliente += 5, { align: 'left' });

            //DATOS GENERALES DE LA FACTURA
            let xGenerales = 145;
            let yGenerales = yCabecera;
            let yyGenerales = yGenerales;

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            doc.text('', xGenerales, yGenerales += 5, { align: 'left' });
            doc.text('Fecha: ', xGenerales, yGenerales += 5, { align: 'left' });
            doc.text('Moneda: ', xGenerales, yGenerales += 5, { align: 'left' });
            doc.text('Tipo de Comprobante: ', xGenerales, yGenerales += 5, { align: 'left' });
            doc.text('Lugar de Expedición: ', xGenerales, yGenerales += 5, { align: 'left' });
            // if (ObjComprobante.ordenCompra != '') {
            //     doc.text('OC: ', xGenerales, yGenerales += 5, { align: 'left' });
            // }

            doc.setFontSize(14);
            doc.text(ObjComprobante.serie + ' ' + ObjComprobante.folio, 200, yyGenerales += 5, { align: 'right' });
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            doc.text(formtaoFecha(ObjComprobante.fecha), 200, yyGenerales += 5, { align: 'right' });
            doc.text(ObjComprobante.moneda.clave, 200, yyGenerales += 5, { align: 'right' });
            let tipoComprobante = 'P | Pago';
            doc.text(tipoComprobante, 200, yyGenerales += 5, { align: 'right' });
            doc.text(ObjComprobante.lugarExpedicion, 200, yyGenerales += 5, { align: 'right' });
            // if (ObjComprobante.ordenCompra != '') {
            //     doc.text(ObjComprobante.ordenCompra, 200, yyGenerales += 5, { align: 'right' });
            // }

            //DATOS DEL PAGO
            let xDatosPago = 10;
            let yDatosPago = yyGenerales;

            //VERIFICAMOS SI TIENE CFDI RELACIONADOS
            if (ObjComprobante.cfdiRelacionados != null) {
                //     console.log(ObjComprobante.cfdiRelacionados[0].cfdiRelacionados.length, "Relacion")
                //     if (ObjComprobante.cfdiRelacionados[0].cfdiRelacionados.length != 0) {
                let yRelacionados = yDatosPago += 5;
                let xRelacionados = 10;
                for (var r of ObjComprobante.cfdiRelacionados) {
                    doc.setFont('helvetica', 'bold');
                    doc.setFontSize(10);
                    doc.text("CFDI'S Relacionados", xRelacionados, yRelacionados, { align: 'left' });
                    //LINEA DE LOS CONCEPTOS
                    doc.setFontSize(8);
                    doc.setFont('helvetica', 'normal');
                    let width = 190;
                    let height = 1;
                    let cornerRadius = 0;
                    const color_ = "#" + ObjColor
                    doc.setDrawColor(color_);
                    yRelacionados += 1
                    doc.setLineWidth(1);
                    doc.line(10, yRelacionados, 200, yRelacionados);
                    for (var c of r.cfdiRelacionados) {
                        doc.text(r.tipoRelacion.tipoRelacion + ':', xRelacionados, yRelacionados += 4, { align: 'left' });
                        var stringWidth = doc.getStringUnitWidth(r.tipoRelacion.tipoRelacion) * 8;
                        var stringWidthInMM = (stringWidth * 25.4) / 72;

                        doc.text(c.serie + ' ' + c.folio + ' | ' + formtaoFecha(c.fecha) + ' | ' + c.uuid, xRelacionados + stringWidthInMM + 1, yRelacionados, { align: 'left' });
                    }
                }
                yDatosPago = yRelacionados + 2
                //     }
            }

            // //CONCEPTOS
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            let yConceptos = yDatosPago += 5;
            let xConceptos = 10;
            doc.text('Valor', xConceptos + 165, yConceptos, { align: 'right' });
            doc.text('Unitario', xConceptos + 165, yConceptos + 5, { align: 'right' });
            doc.text('Descripción', xConceptos, yConceptos += 3, { align: 'left' });
            doc.text('Cantidad', xConceptos + 110, yConceptos, { align: 'center' });
            doc.text('Unidad', xConceptos + 130, yConceptos, { align: 'center' });
            doc.text('Importe', xConceptos + 190, yConceptos, { align: 'right' });

            //LINEA DE LOS CONCEPTOS
            doc.setFontSize(8);
            doc.setFont('helvetica', 'normal');
            let width = 190;
            let height = 1;
            let cornerRadius = 0;
            doc.setDrawColor(ObjColor);
            yConceptos += 4
            doc.setLineWidth(1);
            doc.line(xConceptos - 1, yConceptos, 202, yConceptos);

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            yConceptos += 5;
            for (let c of ObjComprobante.conceptos) {
                let ic = yConceptos;
                let fc = 0;
                //SEPARAMOS EL TEXTO, PRA HACER LOS SALTOS DE LINEA
                let arrayTexto = c.descripcion.split('<p>')
                for (let t of arrayTexto) {
                    doc.setFont('helvetica', 'bold');
                    let tl = t.replace(/<[^>]+>/g, '');

                    let concepto_ = doc.splitTextToSize(tl, 80);
                    let concepto_Height = doc.getTextDimensions(concepto_).h;

                    doc.text(concepto_, xConceptos, yConceptos + 1, { align: 'left' });
                    yConceptos += concepto_Height + 1
                }
                fc = yConceptos
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(7);
                let claveProdServ_ = doc.splitTextToSize(c.claveProdServ.claveProdServ, 80);
                let claveProdServ_Height = doc.getTextDimensions(claveProdServ_).h;
                doc.text(claveProdServ_, xConceptos, yConceptos + 1, { align: 'left' });

                //NO DE IDENTIFICACION
                doc.setFontSize(8);
                let divc = ((fc - ic) / 2) + ic
                doc.setFont('helvetica', 'normal');
                // let noIdentificacion_ = doc.splitTextToSize(c.noIdentificacion, 15);
                // let noIdentificacion_Height = doc.getTextDimensions(noIdentificacion_).h;
                // let yNoidentificacion = divc - (noIdentificacion_Height / 2)
                // doc.text(noIdentificacion_, xConceptos + 10, yNoidentificacion, { align: 'center' });

                doc.text(formatoCantidad(c.cantidad), xConceptos + 110, divc, { align: 'center' });
                doc.setFont('helvetica', 'bold');

                let unidad_ = c.unidad.toUpperCase();
                unidad_ = doc.splitTextToSize(unidad_, 20);
                let unidad_Height = doc.getTextDimensions(unidad_).h;
                doc.text(unidad_, xConceptos + 130, divc, { align: 'center' });

                doc.setFont('helvetica', 'normal');
                doc.setFontSize(7);
                doc.text(c.claveUnidad.claveUnidad, xConceptos + 130, divc + unidad_Height, { align: 'center' });
                doc.setFontSize(8);
                doc.text(formatoNumerico(c.valorUnitario), xConceptos + 165, divc, { align: 'right' });
                doc.text(formatoNumerico(c.importe), xConceptos + 190, divc, { align: 'right' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yConceptos > espacioDisponible
                if (dif) {
                    doc.addPage();
                    yConceptos = 10;
                } else {
                    doc.setLineWidth(.1);
                    yConceptos += claveProdServ_Height
                    doc.line(xConceptos, yConceptos, 200, yConceptos);
                    yConceptos += 4;
                }
            }

            //IMPORTE CON LETRA
            const func = require('./numeroALetras.js')
            let a = func.numeroALetras(ObjComprobante.total, 'MXN')
            let xImporteLetra = 10;
            let yImporteLetra = yConceptos + 5
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(9);
            doc.text('Importe con letra: ', xImporteLetra, yImporteLetra, { align: 'left' });
            doc.setFont('helvetica', 'normal');

            let importeLetra_ = doc.splitTextToSize(a, 120);
            let importeLetra_Height = doc.getTextDimensions(importeLetra_).h;
            doc.text(importeLetra_, xImporteLetra + 10, yImporteLetra += 5, { align: 'left' });

            //COMPLEMENTO DE PAGO
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            let yComplemento = yImporteLetra += 4;
            doc.text('COMPLEMENTO DE PAGO', 100, yComplemento, { align: 'center' });
            doc.setLineWidth(1);
            doc.line(xConceptos - 1, yComplemento + 1, 202, yComplemento + 1);

            doc.setFontSize(10);
            doc.text('MONTO TOTAL PAGOS', 100, yComplemento += 5, { align: 'center' });
            doc.text(formatoNumerico(ObjComprobante.pago.totales.montoTotalPagos), 100, yComplemento += 4, { align: 'center' });
            doc.setLineWidth(.1);
            doc.line(xConceptos - 1, yComplemento + 1, 202, yComplemento + 1);

            //VALIDAMOS LAS RETENCIONES DE IVA
            // IVA DEL 16
            if (ObjComprobante.pago.totales.totalTrasladosBaseIVA16 != 0) {
                let y_ = yComplemento += 5
                doc.setFont('helvetica', 'bold');
                doc.text('Total Trasladado Base IVA 16.0000%', 70, y_, { align: 'center' });
                doc.text('Total Trasladado Impuesto IVA 16.0000%', 140, y_, { align: 'center' });
                y_ += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosBaseIVA16), 70, y_, { align: 'center' });
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosImpuestoIVA16), 140, y_, { align: 'center' });
                doc.line(xConceptos - 1, y_ + 1, 202, y_ + 1);
                yComplemento = y_ + 2
            }
            // IVA DEL 8
            if (ObjComprobante.pago.totales.totalTrasladosBaseIVA8 != 0) {
                let y_ = yComplemento += 5
                doc.setFont('helvetica', 'bold');
                doc.text('Total Trasladado Base IVA 8.0000%', 70, y_, { align: 'center' });
                doc.text('Total Trasladado Impuesto IVA 8.0000%', 140, y_, { align: 'center' });
                y_ += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosBaseIVA8), 70, y_, { align: 'center' });
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosImpuestoIVA8), 140, y_, { align: 'center' });
                doc.line(xConceptos - 1, y_ + 1, 202, y_ + 1);
                yComplemento = y_ + 2
            }
            // IVA DEL 0
            if (ObjComprobante.pago.totales.totalTrasladosBaseIVA0 != 0) {
                let y_ = yComplemento += 5
                doc.setFont('helvetica', 'bold');
                doc.text('Total Trasladado Base IVA 0.0000%', 70, y_, { align: 'center' });
                doc.text('Total Trasladado Impuesto IVA 0.0000%', 140, y_, { align: 'center' });
                y_ += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosBaseIVA0), 70, y_, { align: 'center' });
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalTrasladosImpuestoIVA0), 140, y_, { align: 'center' });
                doc.line(xConceptos - 1, y_ + 1, 202, y_ + 1);
                yComplemento = y_ + 2
            }
            //VALIDAMOS LAS RETENCIONES
            if (ObjComprobante.pago.totales.totalRetencionesIEPS != 0 || ObjComprobante.pago.totales.totalRetencionesISR != 0 || ObjComprobante.pago.totales.totalRetencionesIVA != 0) {
                let y_ = yComplemento += 5
                doc.setFont('helvetica', 'bold');
                doc.text('Total Retenciones IEPS', 30, y_, { align: 'center' });
                doc.text('Total Retenciones ISR', 110, y_, { align: 'center' });
                doc.text('Total Retenciones IVA', 180, y_, { align: 'center' });
                y_ += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalRetencionesIEPS), 30, y_, { align: 'center' });
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalRetencionesISR), 110, y_, { align: 'center' });
                doc.text(formatoNumerico(ObjComprobante.pago.totales.totalRetencionesIVA), 180, y_, { align: 'center' });
                doc.line(xConceptos - 1, y_ + 1, 202, y_ + 1);
                yComplemento = y_ + 2
            }

            //ANEXAMOS LOS PAGOS
            for (let p of ObjComprobante.pago.pagos) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
                let yPago = yComplemento += 5;
                doc.text('PAGO', 100, yPago, { align: 'center' });
                yPago += 1;
                doc.setLineWidth(.5);
                doc.line(xConceptos - 1, yPago + 1, 202, yPago + 1);
                yComplemento += 2;
                doc.setFontSize(10);
                yPago += 5;
                doc.text('Fecha de Pago', 70, yPago, { align: 'center' });
                doc.text('Forma de PagoP', 140, yPago, { align: 'center' });
                yPago += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(p.fechaPago, 70, yPago, { align: 'center' });
                doc.text(p.formaPagoP.formaPago, 140, yPago, { align: 'center' });
                doc.setLineWidth(.1);
                doc.line(xConceptos - 1, yPago + 1, 202, yPago + 1);

                yPago += 5
                doc.setFont('helvetica', 'bold');
                doc.text('MonedaP', 50, yPago, { align: 'center' });
                doc.text('Tipo de CambioP', 110, yPago, { align: 'center' });
                doc.text('Monto', 160, yPago, { align: 'center' });
                yPago += 4;
                doc.setFont('helvetica', 'normal');
                doc.text(p.monedaP.moneda, 50, yPago, { align: 'center' });
                doc.text(formatoNumerico(p.tipoCambioP), 110, yPago, { align: 'center' });
                doc.text(formatoNumerico(p.monto), 160, yPago, { align: 'center' });
                doc.setLineWidth(.1);
                doc.line(xConceptos - 1, yPago + 1, 202, yPago + 1);

                //ANEXAMOS LOS PAGOS
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                yPago += 5;
                doc.text('Serie', xConceptos + 8, yPago, { align: 'center' });
                doc.text('Folio', xConceptos + 18, yPago, { align: 'center' });
                doc.text('Imp. Saldo Ant.', xConceptos + 46, yPago, { align: 'right' });
                doc.text('Imp Pagado', xConceptos + 64, yPago, { align: 'right' });
                doc.text('Imp.', xConceptos + 84, yPago, { align: 'right' });
                doc.text('Saldo Insoluto', xConceptos + 84, yPago + 2, { align: 'right' });
                doc.text('Moneda', xConceptos + 90, yPago, { align: 'center' });
                doc.text('DR', xConceptos + 90, yPago + 2, { align: 'center' });
                doc.text('Equivalencia', xConceptos + 104, yPago, { align: 'center' });
                doc.text('DR', xConceptos + 104, yPago + 2, { align: 'center' });
                doc.text('Num', xConceptos + 120, yPago, { align: 'center' });
                doc.text('Parcialidad', xConceptos + 120, yPago + 2, { align: 'center' });
                doc.text('Id Documento', xConceptos + 160, yPago, { align: 'center' });

                //LINEA DE LOS CONCEPTOS
                let width = 190;
                let height = 1;
                let cornerRadius = 0;
                doc.setDrawColor(ObjColor);
                yPago += 3
                doc.setLineWidth(1);
                doc.line(xConceptos - 1, yPago, 202, yPago);
                doc.setFont('helvetica', 'normal');
                for (let dr of p.doctoRelacionados) {
                    yPago += 3
                    doc.text(dr.serie, xConceptos + 8, yPago, { align: 'center' });
                    doc.text(dr.folio, xConceptos + 18, yPago, { align: 'center' });
                    doc.text(formatoNumerico(dr.impSaldoAnt), xConceptos + 46, yPago, { align: 'right' });
                    doc.text(formatoNumerico(dr.impPagado), xConceptos + 64, yPago, { align: 'right' });
                    doc.text(formatoNumerico(dr.impSaldoInsoluto), xConceptos + 84, yPago, { align: 'right' });
                    doc.text(dr.monedaDR.clave, xConceptos + 90, yPago, { align: 'center' });
                    doc.text(formatoNumerico(dr.equivalendiaDR), xConceptos + 104, yPago, { align: 'center' });
                    doc.text(dr.numParcialidad.toString(), xConceptos + 120, yPago, { align: 'center' });
                    doc.text(dr.idDocumento, xConceptos + 160, yPago, { align: 'center' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yPago > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yPago = 10;
                    } else {
                        doc.setLineWidth(.1);
                        doc.line(xConceptos - 1, yPago + 1, 202, yPago + 1);
                        yPago += 3

                    }

                }

                yComplemento = yPago;
                yComplemento += 5;
            }

            //CERTIFICADOS
            let xCertificado = 10;
            let yCertificado = yComplemento += 4
            let yyCertificado = yCertificado;

            // if (ObjComprobante.timbreFiscalDigital.folioFiscal != '') {
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(9);
            doc.text('Fecha de Timbrado: ', xCertificado, yCertificado, { align: 'left' });
            doc.text('No. Certificado: ', xCertificado, yCertificado += 4, { align: 'left' });
            doc.text('No. Certificado SAT: ', xCertificado, yCertificado += 4, { align: 'left' });
            doc.text('Folio Fiscal: ', xCertificado, yCertificado += 4, { align: 'left' });

            doc.setFont('helvetica', 'normal');
            doc.text(ObjComprobante.timbreFiscalDigital.fechaTimbrado, xCertificado + 32, yyCertificado, { align: 'left' });
            doc.text(ObjComprobante.timbreFiscalDigital.noCertificado, xCertificado + 32, yyCertificado += 4, { align: 'left' });
            doc.text(ObjComprobante.timbreFiscalDigital.noCertificadoSAT, xCertificado + 32, yyCertificado += 4, { align: 'left' });
            doc.text(ObjComprobante.timbreFiscalDigital.uuid, xCertificado + 32, yyCertificado += 4, { align: 'left' });
            // }

            //TABLA CON LOS TOTALES
            let xTotales = 200
            let xxTotales = 150
            let yTotales = yImporteLetra - 12
            doc.text('SubTotal', xxTotales, yTotales, { align: 'left' });
            doc.text(formatoNumerico(ObjComprobante.subTotal), xTotales, yTotales, { align: 'right' });

            if (ObjComprobante.descuento != 0) {
                doc.text('Descuento', xxTotales, yTotales += 5, { align: 'left' });
            }
            if (ObjComprobante.impuestos) {
                for (let t of ObjComprobante.impuestos.traslados) {
                    let nombre = '';
                    if (t.impuesto === '001') {
                        nombre = 'ISR('
                    } else if (t.impuesto === '002') {
                        nombre = 'IVA('
                    } else if (t.impuesto === '003') {
                        nombre = 'IEPS('
                    }
                    let tasa = formatoImpuestos(t.tasaOCuota * 100)
                    nombre = nombre + tasa + ')'
                    doc.text(nombre, xxTotales, yTotales += 5, { align: 'left' });
                    doc.text(formatoNumerico(t.importe), xTotales, yTotales, { align: 'right' });
                }
                for (let t of ObjComprobante.impuestos.retenciones) {
                    let nombre = '';
                    if (t.impuesto === '001') {
                        nombre = 'ISR('
                    } else if (t.impuesto === '002') {
                        nombre = 'IVA('
                    } else if (t.impuesto === '003') {
                        nombre = 'IEPS('
                    }
                    let tasa = formatoImpuestos(t.tasaOCuota * 100)
                    nombre = nombre + tasa + ')'
                    doc.text(nombre, xxTotales, yTotales += 5, { align: 'left' });
                    doc.text(formatoNumerico(t.importe * -1), xTotales, yTotales, { align: 'right' });
                }
            }
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            yTotales += 1
            doc.line(xxTotales, yTotales, 200, yTotales);
            doc.setFontSize(10);
            doc.text('Total', xxTotales, yTotales += 5, { align: 'left' });
            doc.text(formatoNumerico(ObjComprobante.total), xTotales, yTotales, { align: 'right' });

            //INSERTMOS EL SALTO DE PAGINA
            let xQr = 10;
            let yQr = yTotales += 7;
            let espacioDisponibleS = doc.internal.pageSize.height - 45;
            let dif = yQr > espacioDisponibleS
            if (dif) {
                doc.addPage();
                yQr = 10;
                yCertificado = -5;
            }

            //CODIGO QR
            if (yTotales < yCertificado) {
                yQr = yCertificado += 5
            }
            // if (codigoQr) {
            doc.addImage(ObjQr, 'PNG', xQr - 4, yQr - 4, 44, 44);
            // }

            //SELLOS
            let xSellos = 50
            let ySellos = yQr
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(6);

            let selloCfdi_ = doc.splitTextToSize(ObjComprobante.timbreFiscalDigital.selloCFD, 150);
            let selloCfdi_Height = doc.getTextDimensions(selloCfdi_).h;
            let selloSat_ = doc.splitTextToSize(ObjComprobante.timbreFiscalDigital.selloSAT, 150);
            let selloSat_Height = doc.getTextDimensions(selloSat_).h;
            let cadenaOriginal = doc.splitTextToSize(ObjComprobante.timbreFiscalDigital.cadenaOriginal, 150);
            let cadenaOriginal_Height = doc.getTextDimensions(selloSat_Height).h;

            doc.setFont('helvetica', 'bold');
            doc.text('Sello CFDI: ', xSellos, ySellos)
            doc.text('Sello SAT: ', xSellos, ySellos + selloCfdi_Height + 3)
            doc.text('Cadena Original: ', xSellos, ySellos + selloCfdi_Height + selloSat_Height + 6)
            doc.setFont('helvetica', 'normal');
            doc.text(selloCfdi_, xSellos, ySellos += 3);
            doc.text(selloSat_, xSellos, ySellos += selloCfdi_Height += 3);
            doc.text(cadenaOriginal, xSellos, ySellos += selloSat_Height += 3);

            // }

            // //AGREGAMOS LAS NOTAS SI ES QUE LAS TIENE
            // if (ObjComprobante.notas != "") {
            //     doc.setFontSize(10);
            //     let xNotas = 10;
            //     let yNotas = ySellos += 20;
            //     doc.setLineWidth(1);
            //     doc.line(xNotas, yNotas - 4, xNotas, yNotas + 1);
            //     doc.text(ObjComprobante.notas, xNotas + 2, yNotas);
            // }

            //AGREGAMOS EL PIE DE PAGINA
            var footerText = "Representación impresa de un CFDI";
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text(footerText, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Documento generado por ContaGo', 10, doc.internal.pageSize.getHeight() - 10, { align: 'left' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            let nombreDoc = '';
            if (ObjComprobante.serie === '') {
                nombreDoc = ObjComprobante.folio + '_' + ObjComprobante.receptor.rfc + '_' + ObjComprobante.folioFiscal
            } else {
                nombreDoc = ObjComprobante.serie + '_' + ObjComprobante.folio + '_' + ObjComprobante.receptor.rfc + '_' + ObjComprobante.folioFiscal
            }
            // doc.save(nombreDoc + '.pdf');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    });
};

export { ComprobantePagoBase64 };