import { render, staticRenderFns } from "./PolizaCierre.vue?vue&type=template&id=f5fb678e&"
import script from "./PolizaCierre.vue?vue&type=script&lang=js&"
export * from "./PolizaCierre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QToolbar,QBtn,QTooltip,QToolbarTitle,QSeparator,QSelect,QBadge,QTable,QTr,QTd,QPopupEdit,QInput,QCardActions,QFooter,QSpace});qInstall(component, 'directives', {ClosePopup});
