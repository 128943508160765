<template>
    <q-page class=" q-pa-lg ">

        <!-- MENU DE BUSQUEDA -->
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7" style="  white-space: nowrap;">
                <div class=" text-h5">Ajuste Anual por Inflación</div>

            </div>
            <div class="col-12 col-md-2 text-right">
                <q-btn-toggle v-model="tipoConsulta" class="my-custom-toggle" no-caps bordered unelevated
                    toggle-color="primary" color="white" text-color="primary" :options="[
                    { label: 'Anual', value: 'Anual' }, { label: 'Mensual', value: 'Mensual' },
                ]" />
            </div>
            <template v-if="tipoConsulta == 'Mensual'">
                <div class="col-12 col-md-1">
                    <q-select filled dense v-model="selectedAnio" :options="itemsselectedAnio" label="Año" />
                </div>
                <div class="col-12 col-md-1">
                    <q-select filled dense v-model="selectedMes" :options="itemsMes" label="Mes" />
                </div>
            </template>

            <template v-else>
                <div class="col-12 col-md-2">
                    <q-select filled dense v-model="selectedAnio" :options="itemsselectedAnio" label="Año" />
                </div>
            </template>
            <div class="col-12 col-md-1 text-right">
                <q-btn flat round color="primary" icon="mdi-magnify" @click="GetReportes()">
                    <q-tooltip>
                        Buscar
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-pdf-box" @click="ExportarPDF()">
                    <q-tooltip>
                        PDF
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcel()">
                    <q-tooltip>
                        Generar Excel
                    </q-tooltip>
                </q-btn>
            </div>
        </div>

        <!-- ACUMULABLES -->
        <div class="row q-col-gutter-md">

            <div class="col-12 col-md-3">
                <q-card bordered class="my-card shadow-0 bg-grey-1 full-width">
                    <q-card-section class="bg-primary text-white">
                        <div class="text-subtitle2">TOTALES</div>
                    </q-card-section>
                    <q-separator />
                    <q-table :data="dataTotales" row-key="name" hide-header hide-bottom>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                <q-td key="valor" :props="props">{{ functionFormatPrice(props.row.valor) }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card>
            </div>

            <div class="col-12 col-md-3">
                <q-card bordered class="my-card shadow-0 bg-grey-1 full-width">
                    <q-card-section class="bg-primary text-white">
                        <div class="text-subtitle2">SALDOS</div>
                    </q-card-section>
                    <q-separator />
                    <q-table :data="dataSaldos" row-key="name" hide-header hide-bottom>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                <q-td key="valor" :props="props">{{ functionFormatPrice(props.row.valor) }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card>
            </div>

            <div class="col-12 col-md-3">
                <q-card bordered class="my-card shadow-0 bg-grey-1 full-width">
                    <q-card-section class="bg-primary text-white">
                        <div class="text-subtitle2">FACTOR DE AJUSTE ANUAL</div>
                    </q-card-section>
                    <q-separator />
                    <q-table :data="dataAjusteAnual" row-key="name" hide-header hide-bottom>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                <q-td key="valor" :props="props">{{ formatPriceSix(props.row.valor) }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card>
            </div>

            <div class="col-12 col-md-3">
                <q-card flat bordered class="my-card shadow-0 bg-grey-1 full-width">
                    <q-card-section class="bg-primary text-white">
                        <div class="text-subtitle2">INPC's</div>
                    </q-card-section>
                    <q-separator />
                    <q-table :data="dataInpc" row-key="name" hide-header hide-bottom>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                <q-td key="valor" :props="props">{{ props.row.valor }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card>
            </div>
        </div>

        <!-- CREDITOS Y DEUDAS -->
        <q-tabs v-model="tabCreditoDeudas" dense class="bg-purple-1 text-primary q-mt-md" active-color="primary"
            indicator-color="primary" align="justify">
            <q-tab name="creditos" label="Creditos" />
            <q-tab name="deudas" label="Deudas" />
        </q-tabs>

        <q-tab-panels v-model="tabCreditoDeudas" animated>
            <q-tab-panel name="creditos" class="q-pa-none q-pt-sm">
                <q-table class="shadow-0 header-tabla" :data="itemsReporteCreditos" :pagination="initialPagination"
                    :loading="loadingTabla" :columns="columnsCreditos" row-key="_id">

                    <template v-slot:loading>
                        <q-inner-loading showing color="primary" />
                    </template>

                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="mes" :props="props">{{ props.row.mes }}</q-td>
                            <q-td key="bancos" :props="props">{{ functionFormatPrice(props.row.bancos) }}</q-td>
                            <q-td key="inversiones" :props="props">{{
                    functionFormatPrice(props.row.inversiones) }}</q-td>
                            <q-td key="otrosInstrumentosFinancieros" :props="props">{{
                    functionFormatPrice(props.row.otrosInstrumentosFinancieros) }}</q-td>
                            <q-td key="clientes" :props="props">{{ functionFormatPrice(props.row.clientes) }}</q-td>
                            <q-td key="cuentasYDocumentosPorCobrar" :props="props">{{
                    functionFormatPrice(props.row.cuentasYDocumentosPorCobrar) }}</q-td>
                            <q-td key="partesRelacionadasNacionales" :props="props">{{
                    functionFormatPrice(props.row.partesRelacionadasNacionales) }}</q-td>
                            <q-td key="partesRelacionadasExtranjeros" :props="props">{{
                    functionFormatPrice(props.row.partesRelacionadasExtranjeros) }}</q-td>
                            <q-td key="otrosDeudoresDiversos" :props="props">{{
                    functionFormatPrice(props.row.otrosDeudoresDiversos) }}</q-td>
                            <q-td key="suma" :props="props">{{ functionFormatPrice(props.row.suma) }}</q-td>

                        </q-tr>
                    </template>
                </q-table>
            </q-tab-panel>

            <q-tab-panel name="deudas">
                <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla"
                    :columns="columnsDeudas" :data="itemsReporteDeudas" row-key="_id">

                    <template v-slot:loading>
                        <q-inner-loading showing color="primary" />
                    </template>

                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="mes" :props="props">{{ props.row.mes }}</q-td>
                            <q-td key="proveedores" :props="props">{{ functionFormatPrice(props.row.proveedores)
                                }}</q-td>
                            <q-td key="cuentasPorPagarACortoPlazo" :props="props">{{
                    functionFormatPrice(props.row.cuentasPorPagarACortoPlazo) }}</q-td>
                            <q-td key="instrumentosFinancierosCortoP" :props="props">{{
                    functionFormatPrice(props.row.instrumentosFinancierosCortoP) }}</q-td>
                            <q-td key="acreedoresDiversosCortoPlazoN" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDiversosCortoPlazoN) }}</q-td>
                            <q-td key="acreedoresDiversosCortoPlazoE" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDiversosCortoPlazoE) }}</q-td>
                            <q-td key="acreedoresDiversosCortoPlazoNPR" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDiversosCortoPlazoNPR) }}</q-td>
                            <q-td key="acreedoresDiversosCortoPlazoEPR" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDiversosCortoPlazoEPR) }}</q-td>
                            <q-td key="otrosAcreedoresDiversosCortoP" :props="props">{{
                    functionFormatPrice(props.row.otrosAcreedoresDiversosCortoP) }}</q-td>
                            <q-td key="otrosPasivosCortoPlazo" :props="props">{{
                    functionFormatPrice(props.row.otrosPasivosCortoPlazo) }}</q-td>
                            <q-td key="acreedoresDivLargoPlazoN" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDivLargoPlazoN) }}</q-td>
                            <q-td key="acreedoresDivLargoPlazoE" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDivLargoPlazoE) }}</q-td>
                            <q-td key="acreedoresDivLargoPlazoNPR" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDivLargoPlazoNPR) }}</q-td>
                            <q-td key="acreedoresDivLargoPlazoEPR" :props="props">{{
                    functionFormatPrice(props.row.acreedoresDivLargoPlazoEPR) }}</q-td>
                            <q-td key="otrosAcreedoresDiversosLargoPlazo" :props="props">{{
                    functionFormatPrice(props.row.otrosAcreedoresDiversosLargoPlazo) }}</q-td>
                            <q-td key="cuentasPorPagarLargoPlazo" :props="props">{{
                    functionFormatPrice(props.row.cuentasPorPagarLargoPlazo) }}</q-td>
                            <q-td key="instrumentosFinancierosLargoP" :props="props">{{
                    functionFormatPrice(props.row.instrumentosFinancierosLargoP) }}</q-td>
                            <q-td key="otrosPasivosLargoPlazo" :props="props">{{
                                functionFormatPrice(props.row.otrosPasivosLargoPlazo) }}</q-td>
                            <q-td key="aportacionesFuturoAumentoCapital" :props="props">{{
                                functionFormatPrice(props.row.aportacionesFuturoAumentoCapital) }}</q-td>
                            <q-td key="suma" :props="props">{{ functionFormatPrice(props.row.suma) }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
            </q-tab-panel>
        </q-tab-panels>



    </q-page>
</template>

<script>
import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { AjusteAnualPDF } from '../../PDF/CalculoAjusteAnualInflacionPDF.js'
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx';

export default {
    components: {},
    data() {
        return {

            tipoConsulta: 'Anual',

            itemsselectedAnio: ['2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '2024',
            selectedMes: { label: 'ENERO', value: 1 },

            dataTotales: [
                { nombre: 'Total Créditos', valor: 0 },
                { nombre: 'Total Deudas', valor: 0 },
                { nombre: 'Meses del Ejercicio', valor: 0 },
            ],

            dataSaldos: [
                { nombre: 'Saldo promedio anual de créditos', valor: 0 },
                { nombre: 'Saldo promedio anual de deudas', valor: 0 },
                { nombre: 'Diferencia', valor: 0 },
            ],

            dataAjusteAnual: [
                { nombre: 'Factor de Ajuste Anual', valor: 0 },
                { nombre: 'Ajuste Anual por Inflación Acumulable', valor: 0 },
            ],

            dataInpc: [
                { nombre: 'INPC Reciente', valor: 0 },
                { nombre: 'INPC Antiguo', valor: 0 },
            ],

            tabCreditoDeudas: 'creditos',
            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 13
            },

            itemsReporteCreditos: [],
            columnsCreditos: [
                { name: 'mes', align: 'center', label: 'Mes', field: 'mes', sortable: true },
                { name: 'bancos', align: 'center', label: 'Bancos', field: 'bancos', sortable: true },
                { name: 'inversiones', align: 'center', label: 'Inversiones', field: 'inversiones', sortable: true, },
                { name: 'otrosInstrumentosFinancieros', align: 'center', label: 'Otros Instrumentos Financieros', field: 'otrosInstrumentosFinancieros', sortable: true, },
                { name: 'clientes', align: 'center', label: 'Clientes', field: 'clientes', sortable: true, },
                { name: 'cuentasYDocumentosPorCobrar', align: 'center', label: 'Cuentas y documentos por cobrar', field: 'cuentasYDocumentosPorCobrar', sortable: true, },
                { name: 'partesRelacionadasNacionales', align: 'center', label: 'Partes Relacionadas Nacionales', field: 'partesRelacionadasNacionales', sortable: true, },
                { name: 'partesRelacionadasExtranjeros', align: 'center', label: 'Partes Relacionadas Extranjeros', field: 'partesRelacionadasExtranjeros', sortable: true, },
                { name: 'otrosDeudoresDiversos', align: 'center', label: 'Otros deudores diversos', field: 'otrosDeudoresDiversos', sortable: true, },
                { name: 'suma', align: 'center', label: 'Suma', field: 'suma', sortable: true, },
            ],

            itemsReporteDeudas: [],
            columnsDeudas: [
                { name: 'mes', align: 'center', label: 'Mes', field: 'mes', sortable: true },
                { name: 'proveedores', align: 'center', label: 'Proveedores', field: 'proveedores', sortable: true },
                { name: 'cuentasPorPagarACortoPlazo', align: 'center', label: 'Cuentas por pagar a corto plazo', field: 'cuentasPorPagarACortoPlazo', sortable: true, },
                { name: 'instrumentosFinancierosCortoP', align: 'center', label: 'Instrumentos Financieros Corto Plazo', field: 'instrumentosFinancierosCortoP', sortable: true, },
                { name: 'acreedoresDiversosCortoPlazoN', align: 'center', label: 'Acreedores Diversos corto plazo Nacionales', field: 'acreedoresDiversosCortoPlazoN', sortable: true, },
                { name: 'acreedoresDiversosCortoPlazoE', align: 'center', label: 'Acreedores Diversos corto plazo Extranjeros', field: 'acreedoresDiversosCortoPlazoE', sortable: true, },
                { name: 'acreedoresDiversosCortoPlazoNPR', align: 'center', label: 'Acreedores Diversos Corto Plazo Extranjero Parte Relacionada', field: 'acreedoresDiversosCortoPlazoNPR', sortable: true, },
                { name: 'acreedoresDiversosCortoPlazoEPR', align: 'center', label: 'Acreedores Diversos Corto Plazo Nacional Parte Relacionada', field: 'acreedoresDiversosCortoPlazoEPR', sortable: true, },
                { name: 'otrosAcreedoresDiversosCortoP', align: 'center', label: 'Otros Acreedores diversos corto plazo', field: 'otrosAcreedoresDiversosCortoP', sortable: true, },
                { name: 'otrosPasivosCortoPlazo', align: 'center', label: 'Otro Pasivos Corto plazo', field: 'otrosPasivosCortoPlazo', sortable: true, },
                { name: 'acreedoresDivLargoPlazoN', align: 'center', label: 'Acreedores Diversos Largo Plazo Nacionales', field: 'acreedoresDivLargoPlazoN', sortable: true, },
                { name: 'acreedoresDivLargoPlazoE', align: 'center', label: 'Acreedores Diversos Largo Plazo Extranjeros', field: 'acreedoresDivLargoPlazoE', sortable: true, },
                { name: 'acreedoresDivLargoPlazoNPR', align: 'center', label: 'Acreedores Diversos Largo Plazo Extranjero Parte Relacionada', field: 'acreedoresDivLargoPlazoNPR', sortable: true, },
                { name: 'acreedoresDivLargoPlazoEPR', align: 'center', label: 'Acreedores Diversos Largo Plazo Nacional Parte Relacionada', field: 'acreedoresDivLargoPlazoEPR', sortable: true, },
                { name: 'otrosAcreedoresDiversosLargoPlazo', align: 'center', label: 'Otros Acreedores diversos largo plazo', field: 'otrosAcreedoresDiversosLargoPlazo', sortable: true, },
                { name: 'cuentasPorPagarLargoPlazo', align: 'center', label: 'Cuentas por pagar largo plazo', field: 'cuentasPorPagarLargoPlazo', sortable: true, },
                { name: 'instrumentosFinancierosLargoP', align: 'center', label: 'Instrumentos FInancieros Largo Plazo', field: 'instrumentosFinancierosLargoP', sortable: true, },
                { name: 'otrosPasivosLargoPlazo', align: 'center', label: 'Otros Pasivos largo plazo', field: 'otrosPasivosLargoPlazo', sortable: true, },
                { name: 'aportacionesFuturoAumentoCapital', align: 'center', label: 'Aportaciones para futuro aumento de capital', field: 'aportacionesFuturoAumentoCapital', sortable: true, },
                { name: 'suma', align: 'center', label: 'Suma', field: 'suma', sortable: true, },
            ],



            arraydeudas: [],
            sumaCreditos: 0,
            inpcAntiguo: 0,
            mesesEjercicio: 0,
            mesReciente: '',
            inpcReciente: 0,
            listInpc: [],
            promedioAnualCreditos: 0,
            promedioAnualDeudas: 0,
            diferencia: 0,
            factAjusteAnual: 0,
            factAjusteAnualIA: 0,
            sumaDeudas: 0,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        empresa() {
            return this.$store.state.empresaStore;
        },
    },
    created() {
    },
    methods: {
        async GetReportes() {
            await this.GetReporte()
            await this.GetReporteDeudas(this.arraydeudas)
        },
        mesAIndice(mesSeleccionado) {
            const nombresMeses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

            if (!isNaN(mesSeleccionado) && mesSeleccionado.length === 2) {
                mesSeleccionado = nombresMeses[parseInt(mesSeleccionado, 10) - 1];
            } else {
                mesSeleccionado = mesSeleccionado.toLowerCase();
            }

            return nombresMeses.indexOf(mesSeleccionado);
        },

        filtrarDesdeMesSeleccionado(mesSeleccionado) {
            const indiceInicio = mesAIndice(mesSeleccionado);

            if (indiceInicio !== -1) {
                return meses.slice(indiceInicio);
            } else {
                return []; // Retornar lista vacía si el mes no es válido
            }
        },
        async GetReporte() {

            if (this.tipoConsulta == 'Anual') {
                this.selectedMes.label = "ENERO"
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Ajuste Anual...', messageColor: 'white' })
            this.itemsReporteCreditos = []
            try {
                let respuesta = await axios.get('ReportesEspeciales/AjusteAnual/erp_' + this.token.rfc + '/' + this.selectedAnio)

                // var array = respuesta.data.reporte
                // this.arraydeudas = respuesta.data.reporteD

                var array = [];
                const indiceInicio = this.mesAIndice(this.selectedMes.label);

                if (indiceInicio !== -1) {
                    this.arraydeudas = respuesta.data.reporteD.slice(indiceInicio);
                    array = respuesta.data.reporte.slice(indiceInicio);
                } else {
                    this.arraydeudas = [];
                    array = []
                }

                const sumaBancos = array.map(item => item.bancos).reduce((prev, curr) => prev + curr, 0);
                const sumaInversiones = array.map(item => item.inversiones).reduce((prev, curr) => prev + curr, 0);
                const sumaOtrosInstrumentosFinancieros = array.map(item => item.otrosInstrumentosFinancieros).reduce((prev, curr) => prev + curr, 0);
                const sumaClientes = array.map(item => item.clientes).reduce((prev, curr) => prev + curr, 0);
                const sumaCuentasYDocumentosPorCobrar = array.map(item => item.cuentasYDocumentosPorCobrar).reduce((prev, curr) => prev + curr, 0);
                const sumaPartesRelacionadasNacionales = array.map(item => item.partesRelacionadasNacionales).reduce((prev, curr) => prev + curr, 0);
                const sumaPartesRelacionadasExtranjeros = array.map(item => item.partesRelacionadasExtranjeros).reduce((prev, curr) => prev + curr, 0);
                const sumaotrosDeudoresDiversos = array.map(item => item.otrosDeudoresDiversos).reduce((prev, curr) => prev + curr, 0);
                const totalCreditos = array.map(item => item.suma).reduce((prev, curr) => prev + curr, 0);
                var sumatoria = {
                    mes: 'Suma', bancos: sumaBancos,
                    inversiones: sumaInversiones,
                    otrosInstrumentosFinancieros: sumaOtrosInstrumentosFinancieros,
                    clientes: sumaClientes,
                    cuentasYDocumentosPorCobrar: sumaCuentasYDocumentosPorCobrar,
                    partesRelacionadasNacionales: sumaPartesRelacionadasNacionales,
                    partesRelacionadasExtranjeros: sumaPartesRelacionadasExtranjeros,
                    otrosDeudoresDiversos: sumaotrosDeudoresDiversos,
                    suma: totalCreditos
                }
                this.sumaCreditos = totalCreditos
                array.push(sumatoria);

                this.itemsReporteCreditos = array
                this.$q.loading.hide()

            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetReporteDeudas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Deudas...', messageColor: 'white' })

            try {
                var array = this.arraydeudas
                const sumaProveedores = array.map(item => item.proveedores).reduce((prev, curr) => prev + curr, 0);
                const sumaCuentasPorPagarACortoPlazo = array.map(item => item.cuentasPorPagarACortoPlazo).reduce((prev, curr) => prev + curr, 0);
                const sumaInstrumentosFinancierosCortoP = array.map(item => item.instrumentosFinancierosCortoP).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDiversosCortoPlazoN = array.map(item => item.acreedoresDiversosCortoPlazoN).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDiversosCortoPlazoE = array.map(item => item.acreedoresDiversosCortoPlazoE).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDiversosCortoPlazoNPR = array.map(item => item.acreedoresDiversosCortoPlazoNPR).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDiversosCortoPlazoEPR = array.map(item => item.acreedoresDiversosCortoPlazoEPR).reduce((prev, curr) => prev + curr, 0);
                const sumaOtrosAcreedoresDiversosCortoP = array.map(item => item.otrosAcreedoresDiversosCortoP).reduce((prev, curr) => prev + curr, 0);
                const sumaOtrosPasivosCortoPlazo = array.map(item => item.otrosPasivosCortoPlazo).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDivLargoPlazoN = array.map(item => item.acreedoresDivLargoPlazoN).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDivLargoPlazoE = array.map(item => item.acreedoresDivLargoPlazoE).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDivLargoPlazoNPR = array.map(item => item.acreedoresDivLargoPlazoNPR).reduce((prev, curr) => prev + curr, 0);
                const sumaAcreedoresDivLargoPlazoEPR = array.map(item => item.acreedoresDivLargoPlazoEPR).reduce((prev, curr) => prev + curr, 0);
                const sumaOtrosAcreedoresDiversosLargoPlazo = array.map(item => item.otrosAcreedoresDiversosLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                const sumaCuentasPorPagarLargoPlazo = array.map(item => item.cuentasPorPagarLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                const sumaInstrumentosFinancierosLargoP = array.map(item => item.instrumentosFinancierosLargoP).reduce((prev, curr) => prev + curr, 0);
                const sumaOtrosPasivosLargoPlazo = array.map(item => item.otrosPasivosLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                const sumaAportacionesFuturoAumentoCapital = array.map(item => item.aportacionesFuturoAumentoCapital).reduce((prev, curr) => prev + curr, 0);
                const totalDeudas = array.map(item => item.suma).reduce((prev, curr) => prev + curr, 0);
                var sumatoria = {
                    mes: 'suma', proveedores: sumaProveedores,
                    cuentasPorPagarACortoPlazo: sumaCuentasPorPagarACortoPlazo,
                    instrumentosFinancierosCortoP: sumaInstrumentosFinancierosCortoP,
                    acreedoresDiversosCortoPlazoN: sumaAcreedoresDiversosCortoPlazoN,
                    acreedoresDiversosCortoPlazoE: sumaAcreedoresDiversosCortoPlazoE,
                    acreedoresDiversosCortoPlazoNPR: sumaAcreedoresDiversosCortoPlazoNPR,
                    acreedoresDiversosCortoPlazoEPR: sumaAcreedoresDiversosCortoPlazoEPR,
                    otrosAcreedoresDiversosCortoP: sumaOtrosAcreedoresDiversosCortoP,
                    otrosPasivosCortoPlazo: sumaOtrosPasivosCortoPlazo,
                    acreedoresDivLargoPlazoN: sumaAcreedoresDivLargoPlazoN,
                    acreedoresDivLargoPlazoE: sumaAcreedoresDivLargoPlazoE,
                    acreedoresDivLargoPlazoNPR: sumaAcreedoresDivLargoPlazoNPR,
                    acreedoresDivLargoPlazoEPR: sumaAcreedoresDivLargoPlazoEPR,
                    otrosAcreedoresDiversosLargoPlazo: sumaOtrosAcreedoresDiversosLargoPlazo,
                    cuentasPorPagarLargoPlazo: sumaCuentasPorPagarLargoPlazo,
                    instrumentosFinancierosLargoP: sumaInstrumentosFinancierosLargoP,
                    otrosPasivosLargoPlazo: sumaOtrosPasivosLargoPlazo,
                    aportacionesFuturoAumentoCapital: sumaAportacionesFuturoAumentoCapital,
                    suma: totalDeudas
                }

                this.sumaDeudas = totalDeudas
                array.push(sumatoria);
                this.itemsReporteDeudas = array

                await this.GetInpc();

                // VERIFICAMOS SI ESTA SELECCIONADO EL RADIO BUTTON PARA CONSULTAR TODO UN AÑO O SOLO UNOS MESES
                // EN CASO DE QUE SEA TODO EL AÑO
                if (this.tipoConsulta === 'Anual' || this.selectedMes.label === 'ENERO') {

                    // INPC ANTIGUO
                    let ObjInpcA = this.listInpc.find(x => x.anio == (this.selectedAnio - 1))
                    let ObjMesA = 'diciembre';
                    this.inpcAntiguo = ObjInpcA[ObjMesA];

                    // INPC RECIENTE
                    let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                    let fechaA = fechaActual.split('-')

                    if (this.selectedAnio === fechaA[0]) {
                        this.mesesEjercicio = fechaA[1]
                        this.mesReciente = this.getLongMonthName(fechaA[1])
                    } else {
                        this.mesReciente = 'diciembre'
                        this.mesesEjercicio = '12'
                    }

                    while (this.inpcReciente === 0) {
                        this.mesReciente = this.getLongMonthName(this.mesesEjercicio)
                        let ObjInpcB = this.listInpc.find(x => x.anio == this.selectedAnio)
                        this.inpcReciente = ObjInpcB[this.mesReciente];
                    }

                    // EN CASO DE QUE SEAN SOLO UNOS MESES
                } else {
                    // INPC ANTIGUO
                    // let mesInpAntiguo = '';
                    // if ((this.selectedMes.value) - 1 < 10) {
                    //     mesInpAntiguo = '0' + (this.selectedMes.value) 
                    // }

                    this.mesAntiguo = this.getLongMonthName(this.selectedMes.value)

                    let ObjInpcA = this.listInpc.find(x => x.anio == this.selectedAnio)
                    let ObjMesA = this.mesAntiguo;
                    // console.log(this.mesAntiguo);
                    // console.log(ObjInpcA);
                    this.inpcAntiguo = ObjInpcA[ObjMesA];

                    // INPC RECIENTE
                    let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                    let fechaA = fechaActual.split('-')

                    console.log('1', this.selectedAnio);
                    console.log('2', fechaA[0]);
                    console.log('2', fechaA[1]);

                    if (this.selectedAnio === fechaA[0]) {
                        this.mesesEjercicio = fechaA[1]
                        this.mesReciente = this.getLongMonthName(fechaA[1])
                    } else {
                        this.mesReciente = 'diciembre'
                        console.log(this.selectedMes.value)
                        this.mesesEjercicio = 13 - this.selectedMes.value
                    }

                    while (this.inpcReciente === 0) {

                        this.mesReciente = this.getLongMonthName('12')

                        let ObjInpcB = this.listInpc.find(x => x.anio == this.selectedAnio)
                        this.inpcReciente = ObjInpcB[this.mesReciente];

                        // if (this.mesesEjercicio < 10) {
                        //     this.mesesEjercicio = this.mesesEjercicio - 1
                        //     this.mesesEjercicio = '0' + this.mesesEjercicio
                        // } else {
                        //     this.mesesEjercicio = this.mesesEjercicio - 1
                        // }
                    }
                }

                await this.CalculaTotales();
                await this.CalculaSaldos();
                await this.CalculaFactorAjusteAnual();
                await this.CalculasINPC();
                this.$q.loading.hide()

            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async GetInpc() {
            try {
                let respuesta = await axios.get('ReportesEspeciales/GetInpc/')
                this.listInpc = respuesta.data;
            } catch (error) {
                console.log(error)
            }
        },

        async CalculaTotales() {
            // console.log(this.mesesEjercicio)
            this.dataTotales = [
                { nombre: 'Total Créditos', valor: this.sumaCreditos },
                { nombre: 'Total Deudas', valor: this.sumaDeudas },
                { nombre: 'Meses del Ejercicio', valor: this.mesesEjercicio }]
        },

        async CalculaSaldos() {
            this.promedioAnualDeudas = this.sumaDeudas / this.mesesEjercicio
            this.promedioAnualCreditos = this.sumaCreditos / this.mesesEjercicio
            this.diferencia = this.promedioAnualDeudas - this.promedioAnualCreditos

            this.dataSaldos = [
                { nombre: 'Saldo promedio anual de créditos', valor: this.promedioAnualCreditos },
                { nombre: 'Saldo promedio anual de deudas', valor: this.promedioAnualDeudas },
                { nombre: 'Diferencia', valor: this.diferencia },
            ]
        },

        async CalculaFactorAjusteAnual() {
            let fact = (this.inpcReciente / this.inpcAntiguo) - 1
            this.factAjusteAnual = Math.round(fact * 1000000) / 1000000
            let ajusteAnualIA = this.diferencia * this.factAjusteAnual
            console.log(ajusteAnualIA)
            this.factAjusteAnualIA = Math.abs(ajusteAnualIA)
            // console.log(fact)
            console.log(this.factAjusteAnualIA)

            if (ajusteAnualIA < 0) {
                this.dataAjusteAnual = [
                    { nombre: 'Factor de Ajuste Anual', valor: this.factAjusteAnual },
                    { nombre: 'Ajuste Anual por Inflación Deducible', valor: this.factAjusteAnualIA },
                ]
            } else {
                this.dataAjusteAnual = [
                    { nombre: 'Factor de Ajuste Anual', valor: this.factAjusteAnual },
                    { nombre: 'Ajuste Anual por Inflación Acumulable', valor: this.factAjusteAnualIA },
                ]
            }
        },

        async CalculasINPC() {
            this.dataInpc = [
                { nombre: 'INPC Reciente', valor: this.inpcReciente },
                { nombre: 'INPC Antiguo', valor: this.inpcAntiguo },
            ]
        },

        getLongMonthName(date) {
            const numero = Number(date) - 1
            const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
            console.log(monthNames[numero])
            return monthNames[numero];
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US');
        },
        functionFormatPrice(value) {
            let val = (value / 1).toFixed(2)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatPriceSix(value) {
            let val = parseFloat(value).toFixed(6); // Formatea a seis decimales
            return val.replace(/(\d)(?=(\d{3})+\.)/g, "$1,"); // Agrega comas como separadores de miles
        },
        async ExportarPDF() {
            // console.log(this.empresa)
            let Name = ''
            if (this.tipoConsulta == 'Anual') {
                Name = 'ENERO A DICIEMBRE DEL ' + this.selectedAnio
            } else {
                Name = this.selectedMes.label + ' A DICIEMBRE DEL ' + this.selectedAnio
            }

            const pdfBase64 = await AjusteAnualPDF(this.itemsReporteDeudas, this.itemsReporteCreditos, this.token, this.sumaCreditos, this.sumaDeudas, this.mesesEjercicio, this.inpcAntiguo, this.inpcReciente, this.formatPriceSix(this.factAjusteAnualIA), this.factAjusteAnual, this.diferencia, this.promedioAnualCreditos, this.promedioAnualDeudas, Name);

            const fileName = 'CALCULO AJUSTE ANUAL POR INFLACIÓN DE ' + Name + '.pdf'
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },
        ExportExcel() {
            const lista = this.itemsReporteCreditos.map(objeto => Object.values(objeto));

            const libroTrabajo = XLSX.utils.book_new();

            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['CALCULO AJUSTE ANUAL INFLACIÓN'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DE ' + this.selectedAnio + ' ' + this.selectedMes.label],
                ['CRÉDITOS'],
                [],
                ['MES', 'BANCOS', 'INVERSIONES', 'OTROS INSTRUMENTOS FINANCIEROS', 'CLIENTES', 'CUENTAS Y DOCUMENTOS POR COBRAR', 'PARTES RELACIONADAS NACIONALES', 'PARTES RELACIONADAS EXTRANJEROS', 'OTROS DEUDORES DIVERSOS', 'SUMA'],
                ...lista.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                    { t: 'n', v: registro[3], z: '#,##0.00' },
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                    { t: 'n', v: registro[7], z: '#,##0.00' },
                    { t: 'n', v: registro[8], z: '#,##0.00' },
                    { t: 'n', v: registro[9], z: '#,##0.00' },
                ]),
                ,
            ]);


            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 5, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 5, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 5, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'CRÉDITOS');


            const listaa = this.itemsReporteDeudas.map(objeto => Object.values(objeto));


            const hojaCalculo2 = XLSX.utils.aoa_to_sheet([
                ['CALCULO AJUSTE ANUAL INFLACIÓN'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DE ' + this.selectedAnio + ' ' + this.selectedMes.label],
                ['DEUDAS'],
                [],
                ['MES',
                    'PROVEEDORES',
                    'CUENTAS POR PAGAR A CORTO PLAZO',
                    'INSTRUMENTOS FINANCIEROS A CORTO PLAZO',
                    'ACREEDORES DIVERSOS A CORTO PLAZO NACIONALES',
                    'ACREEDORES DIVERSOS CORTO PLAZO EXTRANJEROS',
                    'ACREEDORES DIVERSOS CORTO PLAZO EXTRANJERO PARTE RELACIONADA',
                    'ACREEDORES DIVERSOS CORTO PLAZO NACIONAL PARTE RELACIONADA',
                    'OTROS ACREEDORES DIVERSOS CORTO PLAZO',
                    'OTROS PASIVOS CORTO PLAZO',
                    'ACREEDORES DIVERSOS LARGO PLAZO NACIONALES',
                    'ACREEDORES DIVERSOS LARGO PLAZO EXTRANJEROS',
                    'ACREEDORES DIVERSOS LARGO PLAZO EXTRANJEROS PARTE RELACIONADA',
                    'ACREEDORES DIVERSOS LARGO PLAZO NACIONAL PARTE RELACIONADA',
                    'OTROS ACREEDORES DIVERSOS LARGO PLAZO',
                    'CUENTAS POR PAGAR LARGO PLAZO',
                    'INSTRUMENTOS FINANCIEROS LARGO PLAZO',
                    'OTROS PASIVOS LARGO PLAZO',
                    'APORTACIONES PARA FUTURO AUMENTO DE CAPITAL',
                    'SUMA'],
                ...listaa.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                    { t: 'n', v: registro[3], z: '#,##0.00' },
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                    { t: 'n', v: registro[7], z: '#,##0.00' },
                    { t: 'n', v: registro[8], z: '#,##0.00' },
                    { t: 'n', v: registro[9], z: '#,##0.00' },
                    { t: 'n', v: registro[10], z: '#,##0.00' },
                    { t: 'n', v: registro[11], z: '#,##0.00' },
                    { t: 'n', v: registro[12], z: '#,##0.00' },
                    { t: 'n', v: registro[13], z: '#,##0.00' },
                    { t: 'n', v: registro[14], z: '#,##0.00' },
                    { t: 'n', v: registro[15], z: '#,##0.00' },
                    { t: 'n', v: registro[16], z: '#,##0.00' },
                    { t: 'n', v: registro[17], z: '#,##0.00' },
                    { t: 'n', v: registro[18], z: '#,##0.00' },
                    { t: 'n', v: registro[19], z: '#,##0.00' },
                ]),
                ,
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo2['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 5, r: 0 } }];
            hojaCalculo2['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 5, r: 1 } });
            hojaCalculo2['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 5, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo2, 'DEUDAS');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'Calculo_Ajuste_Anual_Inflación_' + this.selectedAnio
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        }
    }
}
</script>

<style lang="sass" scoped>
.my-custom-toggle
  border: 1px solid #662e91
</style>