<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-8">
                <div class=" text-h5">Reporte DIOT</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="getDiot()">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-text-box-check-outline"
                            @click="exportToTxtFile(diot)">
                            <q-tooltip>
                                Generar TXT
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :data="diot" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" row-key="_id">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="tipo_tercero" :props="props">{{ props.row.tipo_tercero }}</q-td>
                    <q-td key="tipo_operacion" :props="props">{{ props.row.tipo_operacion }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="numeroIdFiscal" :props="props">{{ props.row.numeroIdFiscal }}
                        <q-popup-edit v-if="props.row.rfc == 'XEXX010101000'" v-model="props.row.numeroIdFiscal"
                            title="Editar el número de ID Fiscal" auto-save v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="numeroExtranjero" :props="props">{{ props.row.numeroExtranjero }}
                        <q-popup-edit v-if="props.row.rfc == 'XEXX010101000'" v-model="props.row.numeroExtranjero"
                            title="Editar el nombre del extranjero" auto-save v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="paisResidencia" :props="props">{{ props.row.paisResidencia }}
                        <q-popup-edit v-if="props.row.rfc == 'XEXX010101000'" v-model="props.row.paisResidencia"
                            title="Editar el país de residencia" auto-save v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="nacionalidad" :props="props">{{ props.row.nacionalidad }}
                        <q-popup-edit v-if="props.row.rfc == 'XEXX010101000'" v-model="props.row.nacionalidad"
                            title="Editar la nacionalidad" auto-save v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="iva16" :props="props">{{ props.row.iva16 }}</q-td>
                    <q-td key="base15" :props="props">{{ props.row.base15 }}</q-td>
                    <q-td key="base1516NoAcreditable" :props="props">{{ props.row.base1516NoAcreditable }}</q-td>
                    <q-td key="base1011" :props="props">{{ props.row.base1011 }}</q-td>
                    <q-td key="base10" :props="props">{{ props.row.base10 }}</q-td>
                    <q-td key="iva8" :props="props">{{ props.row.iva8 }}</q-td>
                    <q-td key="base10u11NoAcreditable" :props="props">{{ props.row.base10u11NoAcreditable }}</q-td>
                    <q-td key="ivaNoAcreditable" :props="props">{{ props.row.ivaNoAcreditable }}</q-td>
                    <q-td key="importacion1516" :props="props">{{ props.row.importacion1516 }}</q-td>
                    <q-td key="importacion1516NoAcreditable" :props="props">{{ props.row.importacion1516NoAcreditable
                        }}</q-td>
                    <q-td key="importacion1011" :props="props">{{ props.row.importacion1011 }}</q-td>
                    <q-td key="importacion1011NoAcreditable" :props="props">{{ props.row.importacion1011NoAcreditable
                        }}</q-td>
                    <q-td key="importacionBaseExento" :props="props">{{ props.row.importacionBaseExento }}</q-td>
                    <q-td key="base0" :props="props">{{ props.row.base0 }}</q-td>
                    <q-td key="baseExento" :props="props">{{ props.row.baseExento }}</q-td>
                    <q-td key="ivaRetenido" :props="props">{{ props.row.ivaRetenido }}</q-td>
                    <q-td key="ivaDevoluciones" :props="props">{{ props.row.ivaDevoluciones }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),

            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            items: [],
            columns: [
                { name: 'tipo_tercero', align: 'center', label: 'Tipo Tercero', field: 'tipo_tercero', sortable: true },
                { name: 'tipo_operacion', align: 'center', label: 'Operación', field: 'tipo_operacion', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'numeroIdFiscal', align: 'center', label: 'No. de ID Fiscal', field: 'numeroIdFiscal', sortable: true },
                { name: 'numeroExtranjero', align: 'center', label: 'Nombre del Extranjero', field: 'numeroExtranjero', sortable: true },
                { name: 'paisResidencia', align: 'center', label: 'Pais de Residencia', field: 'paisResidencia', sortable: true },
                { name: 'nacionalidad', align: 'center', label: 'Nacionalidad', field: 'nacionalidad', sortable: true },
                { name: 'iva16', align: 'center', label: 'Base 15 o 16', field: 'iva16', sortable: true },
                { name: 'base15', align: 'center', label: 'Base 15', field: 'base15', sortable: true },
                { name: 'base1516NoAcreditable', align: 'center', label: 'Base 15 o 16 no acreditable', field: 'base1516NoAcreditable', sortable: true },
                { name: 'base1011', align: 'center', label: 'Base 10 o 11', field: 'base1011', sortable: true },
                { name: 'base10', align: 'center', label: 'Base 10', field: 'base10', sortable: true },
                { name: 'iva8', align: 'center', label: 'Región fronteriza', field: 'iva8', sortable: true },
                { name: 'base10u11NoAcreditable', align: 'center', label: 'Base 10 u 11 no acreditable', field: 'base10u11NoAcreditable', sortable: true },
                { name: 'ivaNoAcreditable', align: 'center', label: 'IVA no acreditable', field: 'ivaNoAcreditable', sortable: true },
                { name: 'importacion1516', align: 'center', label: 'Importación base 15 o 16', field: 'importacion1516', sortable: true },
                { name: 'importacion1516NoAcreditable', align: 'center', label: 'Importación base 15 o 16 no acreditable', field: 'importacion1516NoAcreditable', sortable: true },
                { name: 'importacion1011', align: 'center', label: 'Importación base 10 u 11', field: 'importacion1011', sortable: true },
                { name: 'importacion1011NoAcreditable', align: 'center', label: 'Importación base 10 u 11 no acreditable', field: 'importacion1011NoAcreditable', sortable: true },
                { name: 'importacionBaseExento', align: 'center', label: 'Importación base exento', field: 'importacionBaseExento', sortable: true },
                { name: 'base0', align: 'center', label: 'Base 0', field: 'base0', sortable: true },
                { name: 'baseExento', align: 'center', label: 'Base exento', field: 'baseExento', sortable: true },
                { name: 'ivaRetenido', align: 'center', label: 'IVA Retenido', field: 'ivaRetenido', sortable: true },
                { name: 'ivaDevoluciones', align: 'center', label: 'IVA Devoluciones', field: 'ivaDevoluciones', sortable: true },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },
        diot() {
            return this.$store.state.listDiotStore.filter(x =>
                x.numeroIdFiscal != '' ||
                x.numeroExtranjero != '' ||
                x.paisResidencia != '' ||
                x.nacionalidad != '' ||
                x.iva16 != '' ||
                x.base15 != '' ||
                x.base1516NoAcreditable != '' ||
                x.base1011 != '' ||
                x.base10 != '' ||
                x.iva8 != '' ||
                x.base10u11NoAcreditable != '' ||
                x.ivaNoAcreditable != '' ||
                x.importacion1516 != '' ||
                x.importacion1516NoAcreditable != '' ||
                x.importacion1011 != '' ||
                x.importacion1011NoAcreditable != '' ||
                x.importacionBaseExento != '' ||
                x.base0 != '' ||
                x.baseExento != '' ||
                x.ivaRetenido != '' ||
                x.ivaDevoluciones != '');
        },
    },
    created() {
    },
    methods: {
        async getDiot() {
            this.loadingTabla = true
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')
            // this.$store.state.listDiotStore = []
            try {
                let response = await axios.get("ReportesEspeciales/GetReporteDIOT/erp_" + this.token.rfc + '/' + fI + '/' + fF + '/' + this.token.rfc);
                console.log(response.data)
                this.$store.state.listDiotStore = response.data;
                for (var i = 0; i < this.$store.state.listDiotStore.length; i++) {
                    this.diot[i].numeroIdFiscal = "";
                    this.diot[i].numeroExtranjero = "";
                    this.diot[i].paisResidencia = "";
                    this.diot[i].nacionalidad = "";
                    if (this.diot[i].iva16 != 0) {
                        this.diot[i].iva16 = Math.round(this.diot[i].iva16);
                    } else {
                        this.diot[i].iva16 = ""
                    }
                    this.diot[i].base1516 = "";
                    this.diot[i].base15 = "";
                    this.diot[i].base1516NoAcreditable = "";
                    this.diot[i].base1011 = "";
                    this.diot[i].base10 = "";
                    if (this.diot[i].iva8 != 0) {
                        this.diot[i].iva8 = Math.round(this.diot[i].iva8);
                    } else {
                        this.diot[i].iva8 = ""
                    }
                    this.diot[i].base10u11NoAcreditable = "";
                    this.diot[i].ivaNoAcreditable = "";
                    this.diot[i].importacion1516 = "";
                    this.diot[i].importacion1516NoAcreditable = "";
                    this.diot[i].importacion1011 = "";
                    this.diot[i].importacion1011NoAcreditable = "";
                    this.diot[i].importacionBaseExento = "";
                    if (this.diot[i].base0 != 0) {
                        this.diot[i].base0 = Math.round(this.diot[i].base0);
                    } else {
                        this.diot[i].base0 = ""
                    }
                    // this.diot[i].base0 = "";
                    if (this.diot[i].baseExento != 0) {
                        this.diot[i].baseExento = Math.round(this.diot[i].baseExento);
                    } else {
                        this.diot[i].baseExento = ""
                    }
                    // this.diot[i].baseExento = "";
                    this.diot[i].ivaRetenido = "";
                    this.diot[i].ivaDevoluciones = "";
                }
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        exportToTxtFile(data) {
            // Convertir la lista de datos en una cadena de texto con cada dato separado por "|"
            const textToSave = data.map(item => Object.values(item).join('|')).join('\n').trim();

            // Crear un elemento de enlace y configurarlo para descargar el archivo
            let fI = moment(this.fechaI).format('YYYY-MM-DD')
            let fF = moment(this.fechaF).format('YYYY-MM-DD')

            const link = document.createElement('a');
            const fileName = 'DIOT_' + this.token.rfc + '_DEL_' + fI + '_AL_' + fF + '.txt';

            link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(textToSave)}`);
            link.setAttribute('download', fileName);
            link.style.display = 'none';

            // Agregar el enlace al DOM y simular un clic para descargar el archivo
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace del DOM
            document.body.removeChild(link);
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
    }
}
</script>

<style></style>