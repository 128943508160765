import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const CatalogoCuentasPdf = async (objCuentas, objEmpresa, objColor) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = objColor

            //LOGO
            if (objEmpresa.logo.base64 != '') {
                doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('Catálogo de cuentas', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });

            doc.setFontSize(9);
            doc.text('Num. cuenta', 10, yCabecera += 16, { align: 'left' });
            doc.text('Descripción', 30, yCabecera, { align: 'left' });
            // doc.text('Naturaleza', 120, yCabecera, { align: 'left' });
            doc.text('Cuenta SAT', 120, yCabecera, { align: 'left' });

            // Dibujar la barra de color
            const widthT = 205;
            const heightT = 1;
            doc.setFillColor(color);
            doc.rect(5, yCabecera += 3, widthT, heightT, 'F');
            doc.setFontSize(1);
            doc.setTextColor("#fff");

            // RECORREMOS LA LISTA DE CUENTAS
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");

            yCabecera += 5;
            for (let c of objCuentas) {
                let descripcion_ = doc.splitTextToSize(c.descripcion, 86);
                let descripcion_height = doc.getTextDimensions(descripcion_).h;

                let numCuentaSat_ = doc.splitTextToSize(c.numCuentaSat.cuenta, 86);
                let numCuentaSat_height = doc.getTextDimensions(numCuentaSat_).h;

                doc.text(c.numCuenta, 10, yCabecera, { align: 'left' });
                doc.text(descripcion_, 30, yCabecera, { align: 'left' });
                // doc.text(c.naturaleza, 120, yCabecera, { align: 'left' });
                doc.text(numCuentaSat_, 120, yCabecera, { align: 'left' });

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 30;
                let dif = yCabecera > espacioDisponible

                let mayor = descripcion_height;
                if (numCuentaSat_height > descripcion_height) {
                    mayor = numCuentaSat_height;
                }
                if (dif) {
                    doc.addPage();
                    yCabecera = 10;
                } else {
                    mayor += 1
                    yCabecera += mayor;
                }
            }
            // for (let m of ObjAuxiliar) {
            //     // let yLista = yCabecera + 2;
            //     doc.text(FormatoFechaSinHora(m.fecha), 10, yCabecera, { align: 'left' });
            //     doc.text(m.tipo.substring(0, 1) + ' | ' + m.numPoliza, 34, yCabecera, { align: 'left' });
            //     doc.text(formatoNumerico(m.cargo), 190, yCabecera, { align: 'right' });
            //     doc.text(formatoNumerico(m.abono), 210, yCabecera, { align: 'right' });

            //     let descripcionAsiento_ = doc.splitTextToSize(m.descripcionAsiento, 50);
            //     let descripcionAsiento_height = doc.getTextDimensions(descripcionAsiento_).h;
            //     doc.text(descripcionAsiento_, 44, yCabecera, { align: 'left' });

            //     let descripcionPoliza__ = doc.splitTextToSize(m.descripcionPoliza, 64);
            //     let descripcionPoliza___Height = doc.getTextDimensions(descripcionPoliza__).h;
            //     doc.text(descripcionPoliza__, 104, yCabecera, { align: 'left' });
            // }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                let fechaE = new Date();
                doc.text(FormatoFechaSinHora(fechaE), 10, doc.internal.pageSize.getHeight() - 10, { align: 'left' });
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { CatalogoCuentasPdf };