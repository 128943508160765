<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOG DEL AUXILIAR -->
        <q-dialog v-model="dialogAuxiliar" transition-show="scale" transition-hide="scale" maximized>
            <auxiliar @closeDialogAuxiliar="dialogAuxiliar = false"></auxiliar>
        </q-dialog>

        <!-- DIALOGO PARA GENERAR POLIZAS DESDE XML -->
        <q-dialog v-model="dialogGenerarXML" persistent transition-show="scale" transition-hide="scale">
            <BalanzaCe @cierraVentana="dialogGenerarXML = false"></BalanzaCe>
        </q-dialog>

        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-4">
                <div class=" text-h5">Balanza</div>
            </div>
            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>

                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>

                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <div class="col-12 col-md-4">
                <q-select dense bottom-slots filled v-model="nivelBalanza" :options="itemNivelBalanza"
                    label="Tipo de Póliza">

                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetBalanza('A')">
                            <q-tooltip>
                                Generar balanza
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetBalanza('B')">
                            <q-tooltip>
                                Generar balanza Neto
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcelDos">
                            <q-tooltip>
                                Generar Excel
                            </q-tooltip>
                        </q-btn>
                        <!-- <q-btn flat round color="green-10" icon="mdi-microsoft-excel" @click="ExportExcelChafa()">
                            <q-tooltip>
                                Generar Excel Chafa
                            </q-tooltip>
                        </q-btn> -->
                        <q-btn flat round color="primary" icon="mdi-xml" @click="abrirGenerarXml()">
                            <q-tooltip>
                                Generar XML
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-printer" @click="descargarBalanzaPDF()">
                            <q-tooltip>
                                Generar PDF
                            </q-tooltip>
                        </q-btn>

                    </template>
                </q-select>
            </div>
        </div>

        <q-table class="shadow-0 header-tabla my-sticky-dynamic" :pagination="initialPagination" :loading="loadingTabla"
            :columns="columns" row-key="_id" :filter="filter" :data="itemBalanza">

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:top-right>
                <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <q-icon slot="append" name="search" />
                </q-input>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="red-6" rounded flat dense icon="mdi-bookmark-multiple"
                            @click="VerAuxiliar(props.row)">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Ver Auxiliar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="noCuenta" :props="props">{{ props.row.noCuenta }}</q-td>
                    <q-td key="tipoCuenta" :props="props">{{ props.row.tipoCuenta }}</q-td>
                    <q-td key="nombreCuenta" :props="props">{{ props.row.nombreCuenta }}</q-td>

                    <template v-if="tipoBalanza === 'A'">
                        <q-td key="debeInicial" :props="props">{{ formatCurrency(props.row.debeInicial) }}</q-td>
                        <q-td key="haberInicial" :props="props">{{ formatCurrency(props.row.haberInicial) }}</q-td>
                    </template>

                    <template v-if="tipoBalanza === 'B'">
                        <q-td key="saldoInicial" :props="props">{{ formatCurrency(props.row.saldoInicial) }}</q-td>
                    </template>
                    <q-td key="cargo" :props="props">{{ formatCurrency(props.row.cargo) }}</q-td>
                    <q-td key="abono" :props="props">{{ formatCurrency(props.row.abono) }}</q-td>

                    <template v-if="tipoBalanza === 'A'">
                        <q-td key="debeFinal" :props="props">{{ formatCurrency(props.row.debeFinal) }}</q-td>
                        <q-td key="haberFinal" :props="props">{{ formatCurrency(props.row.haberFinal) }}</q-td>
                    </template>

                    <template v-if="tipoBalanza === 'B'">
                        <q-td key="saldoFinal" :props="props">{{ formatCurrency(props.row.saldoFinal) }}</q-td>
                    </template>
                </q-tr>
            </template>
        </q-table>
        <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
            <q-toolbar>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumDebeInicial) }}</div>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumHaberInicial) }}</div>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumCargo) }}</div>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumAbono) }}</div>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumDebeFinal) }}</div>
                <q-space></q-space>
                <div class="text-h6">{{ formatCurrency(sumHaberFinal) }}</div>
                <q-space></q-space>
            </q-toolbar>
        </q-footer>
    </q-page>
</template>

<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import * as XLSX from 'xlsx';
import { BalanzaPDF } from '../../PDF/BalanzaPDF.js'
import { BalanzaInicialFinalPdf } from '../../PDF/BalanzaInicialFinalPdf.js';
import Auxiliar from '../Auxiliar/Auxiliar.vue'
import BalanzaCe from './BalanzaCe.vue';

export default {
    components: {
        Auxiliar,
        BalanzaCe,
    },
    data() {
        return {
            filter: '',

            fechaI: new Date(),
            fechaF: new Date(),

            nivelBalanza: 'Todos',
            itemNivelBalanza: ['Todos', '1', '2', '3'],

            columns: [],

            columnsA: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'name' },
                { name: 'noCuenta', align: 'left', label: 'No. Cuenta', field: 'noCuenta' },
                { name: 'tipoCuenta', align: 'left', label: 'tipoCuenta', field: 'tipoCuenta' },
                { name: 'nombreCuenta', align: 'left', label: 'Nombre Cuenta', field: 'nombreCuenta' },
                { name: 'debeInicial', align: 'center', label: 'Debe', field: 'debeInicial', style: 'text-align: right;' },
                { name: 'haberInicial', align: 'center', label: 'Haber', field: 'haberInicial', style: 'text-align: right;' },
                { name: 'cargo', align: 'center', label: 'Cargos', field: 'cargo', headerClasses: 'bg-primary text-white', style: 'text-align: right;' },
                { name: 'abono', align: 'center', label: 'Abonos', field: 'abono', headerClasses: 'bg-primary text-white', style: 'text-align: right;' },
                { name: 'debeFinal', align: 'center', label: 'Debe', field: 'debeFinal', style: 'text-align: right;' },
                { name: 'haberFinal', align: 'center', label: 'Haber', field: 'haberFinal', style: 'text-align: right;' },
            ],

            columnsB: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'name' },
                { name: 'noCuenta', align: 'left', label: 'No. Cuenta', field: 'noCuenta' },
                { name: 'tipoCuenta', align: 'left', label: 'tipoCuenta', field: 'tipoCuenta' },
                { name: 'nombreCuenta', align: 'left', label: 'Nombre Cuenta', field: 'nombreCuenta' },
                { name: 'saldoInicial', align: 'center', label: 'Saldo inicial', field: 'saldoInicial', style: 'text-align: right;' },
                { name: 'cargo', align: 'center', label: 'Cargos', field: 'cargo', headerClasses: 'bg-primary text-white', style: 'text-align: right;' },
                { name: 'abono', align: 'center', label: 'Abonos', field: 'abono', headerClasses: 'bg-primary text-white', style: 'text-align: right;' },
                { name: 'saldoFinal', align: 'center', label: 'Saldo final', field: 'saldoFinal', style: 'text-align: right;' },
            ],

            itemBalanza: [],
            itemBalanzaTodos: [],
            loadingTabla: false,
            initialPagination: {
                page: 1,
                rowsPerPage: 10
            },

            dialogGenerarXML: false,
            itemsAnios: ['2024', '2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],
            selectedAnio: '2024',
            selectedMes: { label: 'ENERO', value: 1 },

            itemsTipoEnvio: [
                { label: 'N | Normal', value: 'N' },
                { label: 'C | Complementaria ', value: 'C' }
            ],
            tipoEnvio: { label: 'N | Normal', value: 'N' },
            fechaUM: new Date(),

            //SUMATORIA FINAL
            sumDebeInicial: 0,
            sumHaberInicial: 0,
            sumCargo: 0,
            sumAbono: 0,
            sumDebeFinal: 0,
            sumHaberFinal: 0,

            //AUXILIAR
            dialogAuxiliar: false,

            tipoBalanza: '',
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        empresa() {
            return this.$store.state.empresaStore;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        fehaModificacionMasked() {
            moment.locale('es-mx');
            return this.fechaUM ? moment(this.fechaUM).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {
    },
    methods: {
        abrirGenerarXml() {
            this.dialogGenerarXML = true;
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async GetBalanza(tipo) {
            this.itemBalanza = [];
            this.columns = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando balanza...', messageColor: 'white' })
            try {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                // console.log(fechaI, fechaF)
                let response = await axios.get('Reportes/GetBalanzaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF)
                let x = response.data;
                console.log(x);
                this.itemBalanza = [...x]
                this.itemBalanzaTodos = [...x]

                //MOSTRAMOS AL NIVEL SELECCIONADO
                let nivelB = 99;
                if (this.nivelBalanza === 'Todos') {
                    nivelB = 99
                } else {
                    nivelB = this.nivelBalanza
                }

                if (tipo === 'A') {
                    this.columns = [...this.columnsA];
                    let niveles = this.itemBalanza.filter(o => o.nivel <= nivelB)
                    this.itemBalanza = [...niveles];
                    this.tipoBalanza = "A";
                    await this.SumatoriaFinal();
                }

                if (tipo === 'B') {
                    this.columns = [...this.columnsB];
                    let niveles = this.itemBalanza.filter(o => o.nivel <= nivelB)
                    this.itemBalanza = [...niveles];
                    for (let a of this.itemBalanza) {
                        a.saldoInicial = a.debeInicial + a.haberInicial;
                        a.saldoFinal = a.debeFinal + a.haberFinal;
                    }
                    this.tipoBalanza = "B";
                    await this.SumatoriaFinal();
                }

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async SumatoriaFinal() {
            let cuentas = this.itemBalanzaTodos.filter(o => o.tipoCuenta === 'R')
            let sumDebeInicial = cuentas.reduce((a, b) => a + b.debeInicial, 0);
            let sumHaberInicial = cuentas.reduce((a, b) => a + b.haberInicial, 0);
            let sumCargo = cuentas.reduce((a, b) => a + b.cargo, 0);
            let sumAbono = cuentas.reduce((a, b) => a + b.abono, 0);
            let sumDebeFinal = cuentas.reduce((a, b) => a + b.debeFinal, 0);
            let sumHaberFinal = cuentas.reduce((a, b) => a + b.haberFinal, 0);

            this.sumDebeInicial = sumDebeInicial;
            this.sumHaberInicial = sumHaberInicial;
            this.sumCargo = sumCargo;
            this.sumAbono = sumAbono;
            this.sumDebeFinal = sumDebeFinal;
            this.sumHaberFinal = sumHaberFinal;
        },

        async VerAuxiliar(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Auxiliar...', messageColor: 'white' })
            try {
                this.$store.state.auxuliarStore = {
                    listaAuxiliarStore: [],
                    numeroCuenta: ''
                };
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let numCuentaUnico = item.numCuentaUnico
                let response = await axios.get('Reportes/GetAuxiliarAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + numCuentaUnico);

                //ASIGNAMOS EL NUMERO DE ITEM
                let lista = [...response.data];
                let itemC = 1;
                lista.forEach(e => {
                    e.item = itemC;
                    itemC++;
                });
                this.$store.state.auxuliarStore.listaAuxiliarStore = lista
                console.log(response.data)
                this.$store.state.auxuliarStore.numeroCuenta = item.noCuenta + ' | ' + item.nombreCuenta
                this.$store.state.auxuliarStore.fechaI = this.fechaI
                this.$store.state.auxuliarStore.fechaF = this.fechaF
                this.$store.state.auxuliarStore.cuenta = {
                    cargoInicial: item.debeInicial,
                    abonoInicial: item.haberInicial,
                    cargo: item.cargo,
                    abono: item.abono,
                    cargoFinal: item.debeFinal,
                    abonoFinal: item.haberFinal,
                }
                this.dialogAuxiliar = true;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async descargarBalanzaPDF() {
            if (this.itemBalanza.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero la balanza',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            if (this.tipoBalanza == "A") {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                const pdfBase64 = await BalanzaPDF(this.itemBalanza, this.itemBalanzaTodos, this.empresa, fechaI, fechaF);
                const fileName = 'Balanza_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
                const link = document.createElement("a");
                link.href = pdfBase64;
                link.download = fileName;
                link.click();
            } else {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                const pdfBase64 = await BalanzaInicialFinalPdf(this.itemBalanza, this.empresa, fechaI, fechaF);
                const fileName = 'Balanza_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
                const link = document.createElement("a");
                link.href = pdfBase64;
                link.download = fileName;
                link.click();
            }
        },

        ExportExcel() {
            if (this.itemBalanza.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero la balanza',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const data = [...this.itemBalanza];
            const columnsToInclude = ['noCuenta', 'nombreCuenta', 'debeInicial', 'haberInicial', 'cargo', 'abono', 'debeFinal', 'haberFinal'];
            var filteredData = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );

            let preSumas = ['', '', '', '', '', ''];
            let sumas = ['Sumas:', '', 0, 0, 0, 0, 0, 0];
            filteredData.push(preSumas);
            filteredData.push(sumas);

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);

            // Añadir descripciones
            const descriptions = [
                'BALANZA DE COMPROBACIÓN',
                this.token.rfc + '| ' + this.token.empresa,
                'DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF),
                '',
            ];

            descriptions.forEach((desc, index) => {
                worksheet[XLSX.utils.encode_cell({ c: 0, r: index })] = { t: 's', v: desc };
            });

            // Borrar contenido de celdas B1 a H2
            const cellsToClear = [1, 2, 3, 4, 5, 6, 7]; // Índices de las celdas B1 a H1
            for (let rowIndex = descriptions.length - 4; rowIndex <= descriptions.length - 1; rowIndex++) {
                cellsToClear.forEach((colIndex) => {
                    worksheet[XLSX.utils.encode_cell({ c: colIndex, r: rowIndex })] = { t: 's', v: '' };
                });
            }

            // Añadir encabezados
            const columnHeaders = [
                'Num. Cuenta',
                'Cuenta',
                'Debe Inicial',
                'Haber Inicial',
                'Cargo',
                'Abono',
                'Debe Final',
                'Haber Final',
            ];

            columnHeaders.forEach((header, index) => {
                worksheet[XLSX.utils.encode_cell({ c: index, r: descriptions.length })] = { t: 's', v: header };
            });

            // Combinar celdas A1 a H1 y centrar texto
            worksheet['!merges'] = worksheet['!merges'] || [];
            worksheet['!merges'].push({ s: { c: 0, r: 0 }, e: { c: 7, r: 0 } });
            worksheet['A1'].s = { alignment: { horizontal: 'center' } };

            // Combinar celdas A2 a H2 y centrar texto
            worksheet['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 7, r: 1 } });
            worksheet['A2'].s = { alignment: { horizontal: 'center' } };

            // Combinar celdas A2 a H2 y centrar texto
            worksheet['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 7, r: 2 } });
            worksheet['A3'].s = { alignment: { horizontal: 'center' } };

            // Añadir datos a partir del renglón después de los encabezados
            console.log(filteredData);
            filteredData.forEach((row, rowIndex) => {
                columnsToInclude.forEach((column, columnIndex) => {
                    worksheet[XLSX.utils.encode_cell({ c: columnIndex, r: descriptions.length + rowIndex + 1 })] = { t: 's', v: row[column] };
                });
            });

            // Establecer formato decimal para las últimas seis columnas
            for (let row = 5; row < filteredData.length + 1; row++) {
                for (let col = 2; col <= 7; col++) {
                    const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                    if (worksheet[cellRef]) {
                        worksheet[cellRef].t = 'n'; // Tipo numérico para asegurar formato decimal
                        worksheet[cellRef].z = '#,##0.00'; // Formato decimal con dos decimales
                    }
                }
            }

            // Añadir la hoja al libro
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'BALANZA DE COMPROBACIÓN ' + this.token.rfc + '_' + this.token.empresa + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(workbook, nombreArchivo + '.xlsx');


        },

        ExportExcelDos() {
            if (this.itemBalanza.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero la balanza',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const data = [...this.itemBalanza];
            const columnsToInclude = ['noCuenta', 'nombreCuenta', 'debeInicial', 'haberInicial', 'cargo', 'abono', 'debeFinal', 'haberFinal'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            // console.log(arrayDeValores)
            const libroTrabajo = XLSX.utils.book_new();
            // Crear una hoja de cálculo
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['BALANZA DE COMPROBANCION'],
                [this.token.rfc + '| ' + this.token.empresa],
                ['DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)],
                [],
                [
                    'Num. Cuenta',
                    'Cuenta',
                    'Debe Inicial',
                    'Haber Inicial',
                    'Cargo',
                    'Abono',
                    'Debe Final',
                    'Haber Final',
                ],
                ...arrayDeValores.map((registro, index) => [
                    registro[0],
                    registro[1],
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                    { t: 'n', v: registro[3], z: '#,##0.00' },
                    { t: 'n', v: registro[4], z: '#,##0.00' },
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                    { t: 'n', v: registro[7], z: '#,##0.00' },
                ]),
                ['', '', '', '', '', '', '', ''],
                ['Sumas: ', '',
                    { t: 'n', v: this.sumDebeInicial, z: '#,##0.00' },
                    { t: 'n', v: this.sumHaberInicial, z: '#,##0.00' },
                    { t: 'n', v: this.sumCargo, z: '#,##0.00' },
                    { t: 'n', v: this.sumAbono, z: '#,##0.00' },
                    { t: 'n', v: this.sumDebeFinal, z: '#,##0.00' },
                    { t: 'n', v: this.sumHaberFinal, z: '#,##0.00' },
                ],
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 7, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 7, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 7, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'BALANZA DE COMPROBACIÓN ' + this.token.rfc + '_' + this.token.empresa + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        async descargaComprobante() {
            const pdfBase64 = this.$store.state.pdfStore.base64;
            const fileName = this.$store.state.pdfStore.nombre + ".pdf";
            const link = document.createElement("a");
            link.href = "data:application/pdf;base64," + pdfBase64;
            link.download = fileName;
            link.click();
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },

        ExportExcelChafa() {
            let lista = this.itemBalanza
            const workbook = XLSX.utils.book_new();

            const sheetOtros = XLSX.utils.json_to_sheet(lista);
            XLSX.utils.book_append_sheet(workbook, sheetOtros, 'CONCEPTOS');

            XLSX.writeFile(workbook, 'lista chafa.xlsx');
        },

    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>
<style lang="sass">
.my-sticky-dynamic
  /* height or max-height is important */
  height: 610px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: #fff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>