<template>
    <q-page class=" q-pa-lg ">

        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class=" text-h5">Estado de Situación Financiera</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-3">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetEstadoDeSituacionFinanciera">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcel">
                            <q-tooltip>
                                Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip>
                                PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>

        <!-- lISTA DE ESTADO DE SITUACION FINANCIERA -->
        <div class="row q-col-gutter-sm">
            <div class="col-12 col-md-6">
                <q-list class="rounded-borders">
                    <!-- ACTIVO CIRCULANTE -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Activo Circulante
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumActivoCirculante) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsActivoCirculante" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- ACTIVO FIJO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Activo Fijo
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumActivoFijo) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsActivoFijo"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- ACTIVO DIFERIDO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Activo Diferido
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumActivoDiferido) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsActivoDiferido" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>
            <div class="col-12 col-md-6">
                <q-list class="rounded-borders">
                    <!-- PASIVO CIRCULANTE -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Pasivo Circulante
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumPasivoCirculante) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsPasivoCirculante" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- PASIVO A LARGO PLAZO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Pasivo a largo plazo
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumPasivoLargoPlazo) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsPasivoLargoPlazo" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- PASIVO DIFERIDO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Pasivo Diferido
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumPasivoDiferido) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns"
                            :data="itemsPasivoDiferido" hide-header hide-bottom :rows-per-page-options="[0]"
                            :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- CAPITAL -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-opened>
                        <template v-slot:header>
                            <q-item-section>
                                Capital
                            </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumCapital) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="itemsCapital"
                            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="importe" :props="props">{{ formatCurrency(props.row.importe) }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>
        </div>
        <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
            <q-toolbar>
                <q-space></q-space>
                <div style="font-size: 18px; margin-right: 12px;">Total Activo: </div>
                <div class="text-h6">{{ formatCurrency(sumTotalActivo) }}</div>
                <q-space></q-space>
                <div style="font-size: 18px; margin-right: 12px;">Total Pasivo + Capital: </div>
                <div class="text-h6">{{ formatCurrency(sumTotalPasivoCapital) }}</div>
                <q-space></q-space>
            </q-toolbar>
        </q-footer>
    </q-page>
</template>
<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { EstadoSituacionFinancieraPDF } from '../../PDF/EstadoSituacionFinancieraPDF.js'
import * as XLSX from 'xlsx';

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            itemsActivoCirculante: [],
            itemsActivoFijo: [],
            itemsActivoDiferido: [],
            itemsPasivoCirculante: [],
            itemsPasivoLargoPlazo: [],
            itemsPasivoDiferido: [],
            itemsCapital: [],

            columns: [
                { name: 'descripcion', align: 'center', label: 'Descripcion', field: 'descripcion', style: 'text-align: left;' },
                { name: 'importe', align: 'center', label: 'Importe', field: 'importe', style: 'text-align: right;' },
            ],

            pagination: {
                rowsPerPage: 0
            },

            expansion: true,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        sumActivoCirculante() {
            return this.itemsActivoCirculante.reduce((a, b) => a + b.importe, 0);
        },

        sumActivoFijo() {
            return this.itemsActivoFijo.reduce((a, b) => a + b.importe, 0);
        },

        sumActivoDiferido() {
            return this.itemsActivoDiferido.reduce((a, b) => a + b.importe, 0);
        },

        sumPasivoCirculante() {
            return this.itemsPasivoCirculante.reduce((a, b) => a + b.importe, 0);
        },

        sumPasivoLargoPlazo() {
            return this.itemsPasivoLargoPlazo.reduce((a, b) => a + b.importe, 0);
        },

        sumPasivoDiferido() {
            return this.itemsPasivoDiferido.reduce((a, b) => a + b.importe, 0);
        },

        sumCapital() {
            return this.itemsCapital.reduce((a, b) => a + b.importe, 0);
        },

        sumTotalActivo() {
            let a = this.itemsActivoCirculante.reduce((a, b) => a + b.importe, 0);
            let b = this.itemsActivoFijo.reduce((a, b) => a + b.importe, 0);
            let c = this.itemsActivoDiferido.reduce((a, b) => a + b.importe, 0);

            return a + b + c;
        },

        sumTotalPasivoCapital() {
            let a = this.itemsPasivoCirculante.reduce((a, b) => a + b.importe, 0);
            let b = this.itemsPasivoLargoPlazo.reduce((a, b) => a + b.importe, 0);
            let c = this.itemsPasivoDiferido.reduce((a, b) => a + b.importe, 0);
            let d = this.itemsCapital.reduce((a, b) => a + b.importe, 0);
            return a + b + c + d;
        },

    },
    created() {

    },
    methods: {
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async GetEstadoDeSituacionFinanciera() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando Estado de Situación Financiera...', messageColor: 'white' });
            try {
                this.itemsActivoCirculante = [];
                this.itemsActivoFijo = [];
                this.itemsActivoDiferido = [];
                this.itemsPasivoCirculante = [];
                this.itemsPasivoLargoPlazo = [];
                this.itemsPasivoDiferido = [];
                this.itemsCapital = [];
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let response = await axios.get('Reportes/GetEstadoDeSituacionFinancieraAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                console.log(response.data);
                let respuesta = response.data;

                //CREAMOS EL REPORTE
                this.itemsActivoCirculante = respuesta.filter(x => x.subTipo === 'ACTIVO CIRCULANTE')
                this.itemsActivoFijo = respuesta.filter(x => x.subTipo === 'ACTIVO FIJO')
                this.itemsActivoDiferido = respuesta.filter(x => x.subTipo === 'ACTIVO DIFERIDO')
                this.itemsPasivoCirculante = respuesta.filter(x => x.subTipo === 'PASIVO CIRCULANTE')
                this.itemsPasivoLargoPlazo = respuesta.filter(x => x.subTipo === 'PASIVO A LARGO PLAZO')
                this.itemsPasivoDiferido = respuesta.filter(x => x.subTipo === 'PASIVO DIFERIDO')
                this.itemsCapital = respuesta.filter(x => x.subTipo === 'CAPITAL')
                this.$q.loading.hide()
            } catch (error) {
                cosole.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            let activoCirculante = [...this.itemsActivoCirculante];
            let activoFijo = [...this.itemsActivoFijo];
            let activoDiferido = [...this.itemsActivoDiferido];
            let pasivoCirculante = [...this.itemsPasivoCirculante];
            let pasivoLargoPlazo = [...this.itemsPasivoLargoPlazo];
            let pasivoDiferido = [...this.itemsPasivoDiferido];
            let capital = [...this.itemsCapital];

            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await EstadoSituacionFinancieraPDF(activoCirculante, activoFijo, activoDiferido, pasivoCirculante, pasivoLargoPlazo, pasivoDiferido, capital, this.empresa, fechaI, fechaF);
            const fileName = 'Estado_de_Situación_Financiera_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async ExportExcel() {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);

            const columnsToInclude = ['descripcion', 'importe'];
            const dataActivoCirculante = [...this.itemsActivoCirculante];
            const dataActivoFijo = [...this.itemsActivoFijo];
            const dataActivoDiferido = [...this.itemsActivoDiferido];
            const dataPasivoCirculante = [...this.itemsPasivoCirculante]
            const dataPasivoLargoPlazo = [...this.itemsPasivoLargoPlazo]
            const dataPasivoDiferido = [...this.itemsPasivoDiferido]
            const dataCapital = [...this.itemsCapital]

            //EXTRAEMOS LOS DATOS QUE NECESITAMOS
            const datosActivoCirculante = dataActivoCirculante.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosActivoFijo = dataActivoFijo.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosActivoDiferido = dataActivoDiferido.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosPasivoCirculante = dataPasivoCirculante.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosPasivoLargoPlazo = dataPasivoLargoPlazo.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosPasivoDiferido = dataPasivoDiferido.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const datosCapital = dataCapital.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );

            //CREAMOS LOS ARRAY DE VALORES, SIN OBJETOS
            const arrayActivoCirculante = datosActivoCirculante.map(objeto => Object.values(objeto));
            const arrayActivoFijo = datosActivoFijo.map(objeto => Object.values(objeto));
            const arrayActivoDiferido = datosActivoDiferido.map(objeto => Object.values(objeto));
            const arrayPasivoCirculante = datosPasivoCirculante.map(objeto => Object.values(objeto));
            const arrayPasivoLargoPlazo = datosPasivoLargoPlazo.map(objeto => Object.values(objeto));
            const arrayPasivoDiferido = datosPasivoDiferido.map(objeto => Object.values(objeto));
            const arrayCapital = datosCapital.map(objeto => Object.values(objeto));

            // VALORES DE LAS SUMATORIAS
            const sumaActivo = this.sumActivoCirculante + this.sumActivoFijo + this.sumActivoDiferido;
            const sumaActivoYCapital = this.sumPasivoCirculante + this.sumPasivoLargoPlazo + this.sumPasivoDiferido + this.sumCapital;

            // console.log(arrayDeValores)
            const libroTrabajo = XLSX.utils.book_new();

            //CREAMOS LA HOJA CON TODO EL CONTENIDO
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['ESTADO DE SITUACIÓN FINANCIERA'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)],
                [],
                ['ACTIVO'],
                ['ACTIVO CIRCULANTE', { t: 'n', v: this.sumActivoCirculante, z: '#,##0.00' }],
                ...arrayActivoCirculante.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['ACTIVO FIJO', { t: 'n', v: this.sumActivoFijo, z: '#,##0.00' }],
                ...arrayActivoFijo.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['ACTIVO DIFERIDO', { t: 'n', v: this.sumActivoDiferido, z: '#,##0.00' }],
                ...arrayActivoDiferido.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['TOTAL ACTIVO', { t: 'n', v: sumaActivo, z: '#,##0.00' }],
                ['--------------------', '--------------------'],
                ['PASIVO'],
                ['PASIVO CIRCULANTE', { t: 'n', v: this.sumPasivoCirculante, z: '#,##0.00' }],
                ...arrayPasivoCirculante.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['PASIVO LARGO PLAZO', { t: 'n', v: this.sumPasivoLargoPlazo, z: '#,##0.00' }],
                ...arrayPasivoLargoPlazo.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['PASIVO DIFERIDO', { t: 'n', v: this.sumPasivoDiferido, z: '#,##0.00' }],
                ...arrayPasivoDiferido.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['CAPITAL', { t: 'n', v: this.sumCapital, z: '#,##0.00' }],
                ...arrayCapital.map((registro, index) => [
                    registro[0],
                    { t: 'n', v: registro[1], z: '#,##0.00' },
                ]),
                [],
                ['TOTAL PASIVO + CAPITAL', { t: 'n', v: sumaActivoYCapital, z: '#,##0.00' }],
                ['--------------------', '--------------------'],
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 3, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 3, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 3, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'ESTADO DE SITUACIÓN FINANCIERA ' + this.empresa.rfc + '_' + this.empresa.nombre + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },
    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>