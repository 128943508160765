<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class=" text-h5">Estado de Resultados</div>
            </div>
            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>
            <!-- FECHA FINAL -->
            <div class="col-12 col-md-3">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetEstadoDeResultados">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportarExcelDos">
                            <q-tooltip>
                                Generar Excel
                            </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip>
                                Generar PDF
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :columns="columns" row-key="_id" :data="itemsEstadoDeResultados"
            hide-header hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="signo" :props="props">
                        <!-- {{ props.row.signo }} -->
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.signo }}</b>
                        <span v-else>{{ props.row.signo }}</span>
                    </q-td>
                    <q-td key="descripcion" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ props.row.descripcion }}</b>
                        <span v-else>{{ props.row.descripcion }}</span>
                    </q-td>
                    <q-td key="importe" :props="props">
                        <b style="font-size:18px" v-if="props.row.signo === '='">{{ formatCurrency(props.row.importe)
                            }}</b>
                        <span v-else>{{ formatCurrency(props.row.importe) }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { EstadoResultadosPDF } from '../../PDF/EstadoResultadosPDF.js'
import * as XLSX from 'xlsx';

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            banderaBonificacion: false,
            banderaCierre: false,

            pagination: {
                rowsPerPage: 0
            },
            columns: [
                { name: 'signo', align: 'center', label: 'Signo', field: 'signo', style: 'text-align: left;' },
                { name: 'descripcion', align: 'center', label: 'Descripcion', field: 'descripcion', style: 'text-align: left;' },
                { name: 'importe', align: 'center', label: 'Importe', field: 'importe', style: 'text-align: right;' },
            ],
            itemsEstadoDeResultados: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {
    },
    methods: {

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            if (value != '-') {
                // let val = (value/1).toFixed(2)
                // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                return '';
            }
        },

        async GetEstadoDeResultados() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando balanza...', messageColor: 'white' })
            try {
                this.itemsEstadoDeResultados = [];
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                let response = await axios.get('Reportes/GetEstadoResultadosAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + this.banderaBonificacion + '/' + this.banderaCierre);
                let respuesta = response.data;
                console.log(respuesta)

                //CREAMOS LA ESTRUCTURA DE LA TABLA
                let salto = { signo: '', descripcion: '', importe: '-' };

                //INGRESOS
                let ingresos = respuesta.filter(x => x.tipo === 'INGRESOS')
                let sumIngresos = ingresos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objIngresos = { signo: '+', descripcion: 'Ingresos netos', importe: sumIngresos }
                this.itemsEstadoDeResultados.push(objIngresos);

                // COSTOS
                let costos = respuesta.filter(x => x.tipo === 'COSTOS')
                let sumCosto = costos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objCosto = { signo: '-', descripcion: 'Costo de ventas', importe: sumCosto }
                this.itemsEstadoDeResultados.push(objCosto);

                //UTILIDAD BRUTA
                let objUtilidadBruta = { signo: '=', descripcion: 'UTILIDAD BRUTA', importe: sumIngresos - sumCosto }
                this.itemsEstadoDeResultados.push(objUtilidadBruta);
                this.itemsEstadoDeResultados.push(salto);

                //GASTOS GENERALES
                let gastos = respuesta.filter(x => x.tipo === 'GASTOS')
                let sumGastosGenerales = gastos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objGastosGenerales = { signo: '-', descripcion: 'Gastos generales', importe: sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objGastosGenerales);

                //UTILIDAD DE OPERACION
                let objUtilidadDeOperacion = { signo: '=', descripcion: 'UTILIDAD DE OPERACIÓN', importe: objUtilidadBruta.importe - sumGastosGenerales }
                this.itemsEstadoDeResultados.push(objUtilidadDeOperacion);
                this.itemsEstadoDeResultados.push(salto);

                //RESULTADO INTEGRAL DE FINANCIAMIENTO
                let resultado = respuesta.filter(x => x.tipo === 'RESULTADO')
                let sumResultadoIntegral = resultado.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objResultadoIntegral = { signo: '-', descripcion: 'Resultado integral de financiamiento', importe: sumResultadoIntegral }
                this.itemsEstadoDeResultados.push(objResultadoIntegral);

                //PARTICIPACIONES
                let participaciones = respuesta.filter(x => x.tipo === 'PARTICIPACIONES')
                let sumParticipaciones = participaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objParticipaciones = { signo: '+', descripcion: 'Participación en asociadas', importe: sumParticipaciones }
                this.itemsEstadoDeResultados.push(objParticipaciones);

                //UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD
                let objUtilidadImpuestos = { signo: '=', descripcion: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD', importe: objUtilidadDeOperacion.importe - sumResultadoIntegral + sumParticipaciones }
                this.itemsEstadoDeResultados.push(objUtilidadImpuestos);
                this.itemsEstadoDeResultados.push(salto);

                //IMPUESTOS A LA UTILIDAD
                let Impuestos = respuesta.filter(x => x.tipo === 'IMPUESTOS')
                let sumImpuestos = Impuestos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objImpuestos = { signo: '-', descripcion: 'Impuestos a la utilidad', importe: sumImpuestos }
                this.itemsEstadoDeResultados.push(objImpuestos);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let objUtilidadOperaciones = { signo: '=', descripcion: 'UTILIDAD DE OPERACIONES CONTINUAS', importe: objUtilidadImpuestos.importe - sumImpuestos }
                this.itemsEstadoDeResultados.push(objUtilidadOperaciones);
                this.itemsEstadoDeResultados.push(salto);

                //OPERACIONES DISCONTINUADAS
                let operaciones = respuesta.filter(x => x.tipo === 'OPERACIONES')
                let sumDiscontinuadas = operaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                let objDiscontinuadas = { signo: '-', descripcion: 'Operaciones discontinuadas', importe: sumDiscontinuadas }
                this.itemsEstadoDeResultados.push(objDiscontinuadas);

                //UTILIDAD DE OPERACIONES CONTINUAS
                let desc_ = 'UTILIDAD NETA'
                if (objUtilidadOperaciones.importe - sumDiscontinuadas < 0) {
                    desc_ = 'PÉRDIDA NETA'
                }
                let objUtilidadNeta = { signo: '=', descripcion: desc_, importe: objUtilidadOperaciones.importe - sumDiscontinuadas }
                this.itemsEstadoDeResultados.push(objUtilidadNeta);

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            if (this.itemsEstadoDeResultados.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el estado de resultados',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await EstadoResultadosPDF(this.itemsEstadoDeResultados, this.empresa, fechaI, fechaF);
            const fileName = 'Estado_de_Resultados_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async ExportarExcel() {
            if (this.itemsEstadoDeResultados.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero la balanza',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const data = [...this.itemsEstadoDeResultados];
            const columnsToInclude = ['signo', 'descripcion', 'importe'];
            const filteredData = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);

            // Añadir descripciones
            const descriptions = [
                'ESTADO DE RESULTADOS',
                this.empresa.rfc + '| ' + this.empresa.nombre,
                'DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF),
                '',
            ];

            descriptions.forEach((desc, index) => {
                worksheet[XLSX.utils.encode_cell({ c: 0, r: index })] = { t: 's', v: desc };
            });

            // // Borrar contenido de celdas B1 a H2
            // const cellsToClear = [1, 2, 3]; // Índices de las celdas B1 a H1
            // for (let rowIndex = descriptions.length - 4; rowIndex <= descriptions.length - 1; rowIndex++) {
            //     cellsToClear.forEach((colIndex) => {
            //         worksheet[XLSX.utils.encode_cell({ c: colIndex, r: rowIndex })] = { t: 's', v: '' };
            //     });
            // }

            // Añadir encabezados
            const columnHeaders = [
                'Signo',
                'Descripción',
                'Importe',
            ];

            columnHeaders.forEach((header, index) => {
                worksheet[XLSX.utils.encode_cell({ c: index, r: descriptions.length })] = { t: 's', v: header };
            });

            // Combinar celdas A1 a H1 y centrar texto
            worksheet['!merges'] = worksheet['!merges'] || [];
            worksheet['!merges'].push({ s: { c: 0, r: 0 }, e: { c: 2, r: 0 } });
            worksheet['A1'].s = { alignment: { horizontal: 'center' } };

            // Combinar celdas A2 a H2 y centrar texto
            worksheet['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 2, r: 1 } });
            worksheet['A2'].s = { alignment: { horizontal: 'center' } };

            // Combinar celdas A2 a H2 y centrar texto
            worksheet['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 2, r: 2 } });
            worksheet['A3'].s = { alignment: { horizontal: 'center' } };

            // Añadir datos a partir del renglón después de los encabezados
            filteredData.forEach((row, rowIndex) => {
                columnsToInclude.forEach((column, columnIndex) => {
                    worksheet[XLSX.utils.encode_cell({ c: columnIndex, r: descriptions.length + rowIndex + 1 })] = { t: 's', v: row[column] };
                });
            });

            // Establecer formato decimal para las últimas seis columnas
            for (let row = 5; row < filteredData.length + 1; row++) {
                for (let col = 2; col <= 2; col++) {
                    const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                    if (worksheet[cellRef]) {
                        worksheet[cellRef].t = 'n'; // Tipo numérico para asegurar formato decimal
                        worksheet[cellRef].z = '#,##0.00'; // Formato decimal con dos decimales
                    }
                }
            }

            // Añadir la hoja al libro
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'ESTADO DE RESULTADOS ' + this.empresa.rfc + '_' + this.empresa.nombre + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(workbook, nombreArchivo + '.xlsx');
        },

        async ExportarExcelDos() {
            if (this.itemsEstadoDeResultados.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Genere primero el estado de resultados',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            let data = [...this.itemsEstadoDeResultados];
            for (let d in data) {
                if (d.signo === '=') {
                    d.importe = '';
                }
            }
            const columnsToInclude = ['signo', 'descripcion', 'importe'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            // console.log(arrayDeValores)
            const libroTrabajo = XLSX.utils.book_new();
            // Crear una hoja de cálculo
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['ESTADO DE RESULTADOS'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)],
                [],
                [
                    'Signo',
                    'Descripción',
                    'Importe',
                ],
                ...arrayDeValores.map((registro, index) => [
                    registro[0],
                    registro[1],
                    { t: 'n', v: registro[2], z: '#,##0.00' },
                ]),
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 2, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 2, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 2, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'ESTADO DE RESULTADOS ' + this.empresa.rfc + '_' + this.empresa.nombre + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },
    }
}
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>