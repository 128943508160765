import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const LibroDiarioPDF = async (objLibroDiario, objEmpresa, fechaI, fechaF) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#662e91'

            //LOGO
            if (objEmpresa.logo.base64 != '') {
                doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('LIBRO DIARIO', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });

            // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            let periodo = 'PERIODO DEL ' + FormatoFechaSinHora(fechaI) + ' AL ' + FormatoFechaSinHora(fechaF);
            doc.text(periodo, 210, yCabecera += 5, { align: 'right' });


            for (let a of objLibroDiario) {
                //INSERTMOS EL SALTO DE PAGINA
                let espacioCabecera = doc.internal.pageSize.height - 50;
                let difCabecera = yCabecera > espacioCabecera

                if (difCabecera) {
                    doc.addPage();
                    yCabecera = 10;
                } else {

                }

                //DIBUJAMOS LA BARRA
                const widthT = 205;
                const heightT = 8;
                doc.setFillColor(color);
                doc.rect(5, yCabecera + 5, widthT, heightT, 'F');
                doc.setFontSize(10);
                doc.setTextColor("#fff");
                doc.setFont('helvetica', 'bold');
                doc.text('Tipo', 18, yCabecera += 10, { align: 'center' });
                doc.text('Fecha', 41, yCabecera, { align: 'center' });
                doc.text('Descripción', 57, yCabecera, { align: 'left' });
                doc.text('Total Cargo', 180, yCabecera, { align: 'right' });
                doc.text('Total Abono', 208, yCabecera, { align: 'right' });


                doc.setTextColor("#000");
                doc.setFontSize(9);
                doc.setFont('helvetica', 'normal');
                let descripcionPoliza = a.descripcion;
                let descripcionPoliza_ = doc.splitTextToSize(descripcionPoliza, 96);
                let descripcionPolizaHeight = doc.getTextDimensions(descripcionPoliza_).h;
                doc.text(a.tipo + ' ' + a.numPoliza, 18, yCabecera += 7, { align: 'center' });
                doc.setFont('helvetica', 'bold');
                doc.text(FormatoFechaSinHora(a.fecha), 41, yCabecera, { align: 'center' });
                doc.setFont('helvetica', 'normal');
                doc.text(descripcionPoliza_, 57, yCabecera, { align: 'left' });
                doc.setFont('helvetica', 'bold');
                doc.text(formatoNumerico(a.totalCargo), 180, yCabecera, { align: 'right' });
                doc.text(formatoNumerico(a.totalAbono), 208, yCabecera, { align: 'right' });
                doc.setFont('helvetica', 'normal');
                yCabecera += descripcionPolizaHeight - 4

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yCabecera > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yCabecera = 10;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(5, yCabecera + 2, 210, yCabecera + 2);
                }

                yCabecera += 6;
                for (let d of a.asientos) {
                    doc.setFontSize(9);
                    let descripcionCuenta = d.descripcionCuenta;
                    let descripcionCuenta_ = doc.splitTextToSize(descripcionCuenta, 130);
                    let descripcionCuentaHeight = doc.getTextDimensions(descripcionCuenta_).h;
                    doc.text(d.numCuenta, 10, yCabecera, { align: 'left' });
                    doc.text(descripcionCuenta_, 30, yCabecera, { align: 'left' });
                    doc.text(formatoNumerico(d.cargo), 180, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.abono), 208, yCabecera, { align: 'right' });
                    yCabecera += descripcionCuentaHeight + 1;

                    let espacioDisponible = doc.internal.pageSize.height - 20;
                    let dif = yCabecera > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yCabecera = 10;
                        // console.log("A")
                    } else {
                        // console.log("B")
                    }
                }
                yCabecera = yCabecera - 4
            }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { LibroDiarioPDF };