<template>
  <div class="q-ml-xl q-mr-lg">
    <q-dialog v-model="dialogAviso" transition-show="scale" transition-hide="scale">
      <q-card>
        <q-card-section>
          <!-- <div class="text-h6">Aviso importante</div> -->
          <div class="text-h5">Novedades y actualizaciones</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="text-center q-my-xl">
      <img class="logo-home" alt="Contago logo" src="../assets/autoconta.jpg">
    </div>

    <div class="row	flex-center q-pr-xl q-pl-xl q-pt-xl">

      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer; width: 160px;" @click="irPolizas()">
          <q-icon name="mdi-file-document-multiple-outline" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Pólizas</div>
        </span>
      </div>

      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irReportes">
          <q-icon name="mdi-file-chart-outline" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Reportes</div>
        </span>
      </div>

      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;"
          @click="irReportesEspeciales()">
          <q-icon name="mdi-file-chart-check-outline q-pb-xs" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h7 text-weight-bolder" style="color: aliceblue;">Reportes Especiales</div>
        </span>
      </div>

      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irCatalogCuentas">
          <q-icon name="mdi-clipboard-list-outline" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Catálogo</div>
        </span>
      </div>
      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irBancos">
          <q-icon name="mdi-bank" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Bancos</div>
        </span>
      </div>
      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irDescargas">
          <q-icon name="mdi-arrow-down-bold-box" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Descargas</div>
        </span>
      </div>

      <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
        <span class="q-pa-lg shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;"
          @click="irComprobantesContabilizados()">
          <q-icon name="mdi-list-status q-pb-xs" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h7 text-weight-bolder" style="color: aliceblue;">Comprobantes Contabilizados</div>
        </span>
      </div>

      <!-- <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #662e91; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irDescargasViejo">
          <q-icon name="mdi-arrow-down-bold-box" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Descargas WS</div>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      menuOptions: [
        { name: 'Opción 1', icon: 'mdi-home' },
        { name: 'Opción 2', icon: 'mdi-account' },
        { name: 'Opción 3', icon: 'mdi-settings' }
      ],
      dialogAviso: false,
    };
  },

  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    this.GetEmpresa();
  },
  methods: {
    irReportesEspeciales() {
      this.$router.push({ name: "EstadoCostoProduccionVenta" })
    },

    irReportes() {
      this.$router.push({ name: 'Balanza' });
    },

    irPolizas() {
      this.$store.state.listaPolizasStore = []
      this.$router.push({ name: 'Polizas' });
    },

    irCatalogCuentas() {
      this.$store.state.listaCatalogoCuentasStore = []
      this.$router.push({ name: 'CatalogoCuentas' });
    },

    irBancos() {
      this.$router.push({ name: 'Bancos' })
    },
    irDescargasViejo() {
      this.$router.push({ name: "Descargas" })
    },
    irDescargas() {
      this.$router.push({ name: "DescargasScraper" })
    },

    irComprobantesContabilizados() {
      this.$router.push({ name: "ComprobantesContabilizados" })
    },

    async GetEmpresa() {
      try {
        let response = await axios.get('Empresa/GetEmpresa/erp_' + this.token.rfc + '/' + this.token.rfc);
        console.log(response)
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error);
      }
    },

    DescargaHerramienta() {
      try {
        const rutaArchivo = '/CargaXml.zip';
        const enlaceTemporal = document.createElement('a');
        enlaceTemporal.href = rutaArchivo;
        enlaceTemporal.download = 'CargaXml.zip';
        document.body.appendChild(enlaceTemporal);
        enlaceTemporal.click();
        document.body.removeChild(enlaceTemporal);
      } catch (error) {
        console.log(erro)
      }
    },

  }
};
</script>

<style>
.logo-home {
  max-width: 35%;
  height: auto;
}

.main-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.menu-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>