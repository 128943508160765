import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const MayoresPdf = async (objMovimientos, objEmpresa, objColor, objAño) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = objColor

            //LOGO
            if (objEmpresa.logo.base64 != '') {
                doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('REPORTE DE MAYORES', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
            doc.text("PERIODO " + objAño, 210, yCabecera += 5, { align: 'right' });

            doc.setFontSize(9);
            yCabecera += 21
            doc.text('Mes', 10, yCabecera, { align: 'left' });
            doc.text('Saldo inicial', 60, yCabecera, { align: 'right' });
            doc.text('Cargos', 90, yCabecera, { align: 'right' });
            doc.text('Abonos', 120, yCabecera, { align: 'right' });
            doc.text('Saldo final', 150, yCabecera, { align: 'right' });
            doc.text('Acumulados', 180, yCabecera - 4, { align: 'right' });
            doc.text('Cargos', 180, yCabecera, { align: 'right' });
            doc.text('Acumulados', 210, yCabecera - 4, { align: 'right' });
            doc.text('Abonos', 210, yCabecera, { align: 'right' });

            // Dibujar la barra de color
            const widthT = 205;
            const heightT = 1;
            doc.setFillColor(color);
            doc.rect(5, yCabecera += 3, widthT, heightT, 'F');
            doc.setFontSize(1);
            doc.setTextColor("#fff");

            // RECORREMOS LA LISTA DE CUENTAS
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.setTextColor("#000");

            yCabecera += 5;
            for (let m of objMovimientos) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(9);
                doc.text(m.numeroCuenta + " | " + m.nombrecuenta, 10, yCabecera, { align: 'left' });

                doc.setFont('helvetica', 'normal');
                doc.setFontSize(8);
                for (let d of m.detalles) {
                    yCabecera += 4;
                    doc.text(d.mes, 10, yCabecera, { align: 'left' });
                    doc.text(formatoNumerico(d.saldoInicial), 60, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.cargos), 90, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.abonos), 120, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.saldoFinal), 150, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.acumuladosCargos), 180, yCabecera, { align: 'right' });
                    doc.text(formatoNumerico(d.acumuladosAbonos), 210, yCabecera, { align: 'right' });
                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 30;
                    let dif = yCabecera > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yCabecera = 10;
                    } else {
                        // yCabecera += 2;
                    }
                }

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 30;
                let dif = yCabecera > espacioDisponible
                if (dif) {
                    doc.addPage();
                    yCabecera = 10;
                } else {
                    yCabecera += 5;
                }
            }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                let fechaE = new Date();
                doc.text(FormatoFechaSinHora(fechaE), 10, doc.internal.pageSize.getHeight() - 10, { align: 'left' });
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { MayoresPdf };