<template>
    <q-card>
        <!-- MUESTRA PDF -->
        <q-dialog v-model="dialogPoliza" transition-show="scale" transition-hide="scale" full-width>
            <poliza @cierraVentana="dialogPoliza = false"></poliza>
        </q-dialog>
        <!-- MUESTA DIALOG PARA REASIGNAR ASIENTO -->
        <q-dialog v-model="dialogReasigna" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title><span class="text-weight-bold">Reasignar Asiento</span></q-toolbar-title>
                </q-toolbar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-select dense filled label="Número de Cuenta" v-model="selectCuenta" option-label="cuenta"
                                @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas" use-input
                                input-debounce="0">
                            </q-select>
                        </div>
                        <div class="col-12">
                            <q-input dense filled
                                label="Descripción del asiento (Dejar en blanco si no se requiere modificar la descripción)"
                                v-model="descripcionAsiento"></q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cancelar" color="primary" v-close-popup />
                    <q-btn flat label="Aplicar Cambios" color="primary" @click="PutAsientos" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                        <q-tooltip>
                            Cerrar
                        </q-tooltip>
                    </q-btn>
                    Auxiliar {{ items.numeroCuenta }}
                </q-toolbar-title>
                <q-btn flat round color="primary" icon="mdi-swap-horizontal" dense @click="OpenDialogReasignaPoliza">
                    <q-tooltip>
                        Reasignar asiento
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-file-pdf-box" dense @click="ExportPdf">
                    <q-tooltip>
                        PDF
                    </q-tooltip>
                </q-btn>
                <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcel">
                    <q-tooltip>
                        Generar Excel
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section style="max-height: 800px; min-height: 400px;" class="scroll">
            <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :columns="columns" row-key="item"
                :filter="filter" :data="items.listaAuxiliarStore" selection="multiple"
                :selected.sync="selectedMovimientos">
                <template v-slot:top-left>
                    <div>
                        <div class="text-h6">Cargo: {{ formatCurrency(sumCargoS) }}</div>
                        <div class="text-h6">Abono: {{ formatCurrency(sumAbonoS) }}</div>
                    </div>
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                        <q-icon slot="append" name="search" />
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red-6" rounded flat dense icon="mdi-file-document-outline"
                                @click="VerPoliza(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Póliza</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                        <q-td key="numPoliza" :props="props">{{ props.row.numPoliza }}</q-td>
                        <q-td key="descripcionPoliza" :props="props">{{ props.row.descripcionPoliza }}</q-td>
                        <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                        <q-td key="numCuenta" :props="props">{{ props.row.numCuenta }}</q-td>
                        <q-td key="descripcionAsiento" :props="props">{{ props.row.descripcionAsiento }}</q-td>
                        <q-td key="cargo" :props="props">{{ formatCurrency(props.row.cargo) }}</q-td>
                        <q-td key="abono" :props="props">{{ formatCurrency(props.row.abono) }}</q-td>
                        <!-- <q-td key="item" :props="props">{{ props.row.item }}</q-td> -->
                    </q-tr>
                </template>
            </q-table>
            <q-footer class="bg-dark" dark style="height: 40px; margin-top: 30px;">
                <q-toolbar>
                    <q-space></q-space>
                    <div class="text-h6">{{ formatCurrency(sumCargo) }}</div>
                    <q-space></q-space>
                    <div class="text-h6">{{ formatCurrency(sumAbono) }}</div>
                </q-toolbar>
            </q-footer>
        </q-card-section>
    </q-card>
</template>
<script>

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import * as XLSX from 'xlsx';
import Poliza from '../../Polizas/Poliza.vue'
import { AuxiliarPdf } from '../../PDF/AuxiliarCuenta.js'

export default {
    components: {
        Poliza
    },
    data() {
        return {
            initialPagination: {
                page: 1,
                rowsPerPage: 10
            },
            filter: '',
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'name' },
                { name: 'tipo', align: 'left', label: 'Tipo', field: 'tipo' },
                { name: 'numPoliza', align: 'left', label: 'Num. Póliza', field: 'numPoliza' },
                { name: 'descripcionPoliza', align: 'left', label: 'Descripcion de la Póliza', field: 'descripcionPoliza' },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'Fecha', style: 'text-align: right;' },
                { name: 'numCuenta', align: 'left', label: 'Num. Cuenta', field: 'numCuenta' },
                { name: 'descripcionAsiento', align: 'left', label: 'Descripción del Asiento', field: 'descripcionAsiento' },
                { name: 'cargo', align: 'right', label: 'Cargo', field: 'Cargo', style: 'text-align: right;' },
                { name: 'abono', align: 'right', label: 'Abono', field: 'Abono', style: 'text-align: right;' },
                // { name: 'item', align: 'right', label: 'Item', field: 'item', style: 'text-align: right;' },
                // { name: '_id', align: 'left', label: 'id', field: '_id' },
            ],
            dialogPoliza: false,

            //REASIGNAR POLIZA
            dialogReasigna: false,
            itemsCatalogoCuentas: [],
            selectCuenta: null,
            filtroCatalogoCuentas: null,
            descripcionAsiento: '',
            selectedMovimientos: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

        items() {
            return this.$store.state.auxuliarStore;
        },

        itemsTable() {
            return this.$store.state.auxuliarStore.listaAuxiliarStore
        },

        sumCargo() {
            let lista = this.$store.state.auxuliarStore.listaAuxiliarStore
            let suma = lista.reduce((a, b) => a + b.cargo, 0);
            return suma;
        },

        sumAbono() {
            let lista = this.$store.state.auxuliarStore.listaAuxiliarStore
            let suma = lista.reduce((a, b) => a + b.abono, 0);
            return suma;
        },

        sumCargoS() {
            let suma = this.selectedMovimientos.reduce((a, b) => a + b.cargo, 0);
            return suma;
        },

        sumAbonoS() {
            let suma = this.selectedMovimientos.reduce((a, b) => a + b.abono, 0);
            return suma;
        },
    },
    created() {
        this.GetCuentas();
    },
    methods: {
        async GetCuentas() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                let catalogo = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente')
                let a = catalogo.shift();
                this.itemsCatalogoCuentas = catalogo;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        cierraVentana() {
            this.$emit('closeDialogAuxiliar')
        },

        async VerPoliza(item) {
            // console.log(item)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando Póliza...', messageColor: 'white' });
            try {
                let response = await axios.get("Polizas/GetPolizas/erp_" + this.token.rfc + '/' + item.idPoliza);
                console.log(response.data)
                let x = response.data;
                this.$store.state.polizaStore = { ...x }
                this.$store.state.indexPolizas = -1
                this.dialogPoliza = true;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportPdf() {
            let fechaI = this.items.fechaI;
            let fechaF = this.items.fechaF
            let cuenta = {
                numCuenta: this.items.numeroCuenta,
                cargoInicial: this.items.cuenta.cargoInicial,
                abonoInicial: this.items.cuenta.abonoInicial,
                cargo: this.items.cuenta.cargo,
                abono: this.items.cuenta.abono,
                cargoFinal: this.items.cuenta.cargoFinal,
                abonoFinal: this.items.cuenta.abonoFinal,
            }
            console.log(cuenta)
            const pdfBase64 = await AuxiliarPdf(this.items.listaAuxiliarStore, this.empresa, fechaI, fechaF, cuenta);
            const fileName = 'Mayores_y_auxiliares_cuenta_' + cuenta.numCuenta + '_del_' + this.FormatoFechaSinHora(fechaI) + '_al_' + this.FormatoFechaSinHora(fechaF) + ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        ExportExcel() {
            let cuenta = {
                numCuenta: this.items.numeroCuenta,
                cargoInicial: this.items.cuenta.cargoInicial,
                abonoInicial: this.items.cuenta.abonoInicial,
                cargo: this.items.cuenta.cargo,
                abono: this.items.cuenta.abono,
                cargoFinal: this.items.cuenta.cargoFinal,
                abonoFinal: this.items.cuenta.abonoFinal,
            }
            let fechaI = this.items.fechaI;
            let fechaF = this.items.fechaF
            let data = [...this.items.listaAuxiliarStore];
            const columnsToInclude = ['tipo', 'numPoliza', 'descripcionPoliza', 'fecha', 'descripcionAsiento', 'cargo', 'abono'];
            const datos = data.map(item =>
                columnsToInclude.reduce((acc, column) => {
                    acc[column] = item[column];
                    return acc;
                }, {})
            );
            const arrayDeValores = datos.map(objeto => Object.values(objeto));
            const libroTrabajo = XLSX.utils.book_new();
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ['MAYORES Y AUXILIARES'],
                [this.empresa.rfc + '| ' + this.empresa.nombre],
                ['DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)],
                [],
                [
                    '',
                    '',
                    '',
                    '',
                    'Debe',
                    'Haber',
                    'Cargo',
                    'Abono',
                    'Debe',
                    'Haber',
                ],
                [
                    cuenta.numCuenta,
                    '',
                    '',
                    '',
                    { t: 'n', v: cuenta.cargoInicial, z: '#,##0.00' },
                    { t: 'n', v: cuenta.abonoInicial, z: '#,##0.00' },
                    { t: 'n', v: cuenta.cargo, z: '#,##0.00' },
                    { t: 'n', v: cuenta.abono, z: '#,##0.00' },
                    { t: 'n', v: cuenta.cargoFinal, z: '#,##0.00' },
                    { t: 'n', v: cuenta.abonoFinal, z: '#,##0.00' },
                ],
                ['FECHA', 'PÓLIZA', 'REFERENCIA', 'DESCRIPCIÓN'],
                ...arrayDeValores.map((registro, index) => [
                    this.FormatoFechaSinHora(registro[3]),
                    registro[0].substring(0, 1) + ' | ' + registro[1],
                    registro[4],
                    registro[2],
                    '',
                    '',
                    { t: 'n', v: registro[5], z: '#,##0.00' },
                    { t: 'n', v: registro[6], z: '#,##0.00' },
                ]),

            ]);
            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo['!merges'] = [{ s: { r: 0, c: 0 }, e: { c: 2, r: 0 } }];
            hojaCalculo['!merges'].push({ s: { c: 0, r: 1 }, e: { c: 2, r: 1 } });
            hojaCalculo['!merges'].push({ s: { c: 0, r: 2 }, e: { c: 2, r: 2 } });
            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

            // Guardar el libro como un archivo XLSX
            const nombreArchivo = 'MAYORES Y AUXILIARES ' + this.empresa.rfc + '_' + this.empresa.nombre + ' DEL ' + this.FormatoFechaSinHora(fechaI) + ' AL ' + this.FormatoFechaSinHora(fechaF)
            XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
            return fechaFormateada.toUpperCase();
        },

        OpenDialogReasignaPoliza() {
            this.dialogReasigna = true;
        },

        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        async PutAsientos() {
            if (!this.selectCuenta) {
                this.$q.notify({
                    type: 'warning', message: 'Seleccione la cuenta para continuar.', actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando cambios...', messageColor: 'white' })
            try {
                for (let a of this.selectedMovimientos) {
                    const databaseName = 'erp_' + this.token.rfc;
                    const _id = a.idPoliza;
                    const indice = a.indice;
                    let cuenta = this.selectCuenta;

                    let descripcion = a.descripcionAsiento
                    cuenta.descripcionAsiento = descripcion;

                    let indice_ = this.items.listaAuxiliarStore.findIndex(i => i.idPoliza === _id);
                    this.items.listaAuxiliarStore.splice(indice_, 1);
                    let response = await axios.put("Polizas/PutAsientoAuxiliarAsync/" + databaseName + '/' + _id + '/' + indice, cuenta);
                }

                this.$q.notify({
                    type: 'positive', message: 'Asientos asignados con áxito, consulte nuevamente la balanza para validar los movimientos realizados', actions: [{ label: 'Cerrar', color: 'white', handler: () => { } }
                    ]
                })
                this.dialogReasigna = false;
                this.selectedMovimientos = [];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

    }
}
</script>