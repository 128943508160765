import { render, staticRenderFns } from "./DescargasMetadata.vue?vue&type=template&id=53b17eae&"
import script from "./DescargasMetadata.vue?vue&type=script&lang=js&"
export * from "./DescargasMetadata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QInput,QIcon,QPopupProxy,QDate,QBtn,QTime,QSelect,QTooltip,QTable,QTr,QTd});qInstall(component, 'directives', {ClosePopup});
