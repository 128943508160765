<template>
    <div>
        <!-- DIALOG PARA VER LOS PDF -->
        <q-dialog v-model="dialogPDF" transition-show="scale" transition-hide="scale" maximized>
            <visor-pdf ref="miComponenteHijo" @CloseDialogPdf="CloseDialogPdf"></visor-pdf>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-lg">Estas seguro de {{ this.accion }} la Póliza.?</span>
                </q-card-section>

                <!-- <q-card-actions align="right"> -->
                <q-card-actions>
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-space></q-space>
                    <q-btn flat label="Aceptar" color="primary" @click="PutEstadoPoliza()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <q-card class="my-card">
            <q-toolbar>
                <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                    @click="CierraDialog">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cerrar</q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    <span class="text-weight-bold">Validar comprobantes cancelados</span>
                </q-toolbar-title>
            </q-toolbar>
            <q-separator color="primary" inset size="4px" />
            <q-card-section>
                <div class="row q-col-gutter-sm">
                    <!-- FECHA INICIAL -->
                    <div class="col-12 col-md-4">
                        <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event"
                            outlined dense>
                            <template v-slot:before>
                                <q-icon name="event" color="primary" />
                            </template>
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="fechaI" @input="UltimoDiaMes">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined
                            dense>
                            <template v-slot:before>
                                <q-icon name="event" color="primary" />
                            </template>
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="fechaF">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-btn flat round color="primary" icon="mdi-magnify-minus-outline" @click="GetCancelados()">
                            <q-tooltip>
                                Buscar cancelados
                            </q-tooltip>
                        </q-btn>
                    </div>
                </div>
            </q-card-section>
            <q-table :filter="filter" class="my-sticky-column-table my-sticky-column-table-sec shadow-0 header-tabla"
                :loading="loadingTabla" :data="itemPolizas" :columns="columns" row-key="_id"
                :pagination="initialPagination">

                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>

                <!-- <template v-slot:body-selection="scope">
                    <q-checkbox :value="scope.selectedPolizas" />
                </template> -->

                <template v-slot:top>
                    <q-input filled dense debounce="300" class="full-width" v-model="filter" placeholder="Filtrar...">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                        <!-- <template v-slot:after>
                            <q-btn round dense flat icon="mdi-text-search" @click="OpenDialogDiltradoAvanzado()">
                                <q-tooltip>
                                    Filtrado avanzado
                                </q-tooltip>
                            </q-btn>
                        </template> -->
                    </q-input>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">

                        <!-- <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td> -->
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red-6" rounded flat dense icon="mdi-file-pdf-box"
                                @click="verPoliza(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">PDF</q-tooltip>
                            </q-btn>
                            <q-btn v-show="props.row.estatus != 'Validada'" size="md" color="orange" rounded flat dense
                                icon="mdi-delete" @click="confirm(props.row, 'eliminar')">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn v-show="props.row.estatus == 'Activo' || props.row.estatus == 'Validada'" size="md"
                                color="negative" rounded flat dense icon="mdi-book-cancel"
                                @click="confirm(props.row, 'cancelar')">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Cancelar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                        <q-td key="numPoliza" :props="props">
                            <q-chip :class="props.row.estatus">
                                {{ props.row.numPoliza }}
                                <q-tooltip>
                                    {{ props.row.estatus }}
                                </q-tooltip>
                            </q-chip>
                        </q-td>
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                        <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    </q-tr>
                </template>

                <template v-slot:bottom-row>
                    <q-tr>
                        <q-td colspan="100%">
                            <q-chip color="negative" text-color="white" icon="mdi-cancel">
                                Cancelada
                            </q-chip>
                            <q-chip color="blue" text-color="white" icon="mdi-checkbox-marked-circle-outline">
                                Activo
                            </q-chip>
                            <q-chip color="green" text-color="white" icon="mdi-star-check">
                                Validada
                            </q-chip>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
            <q-card-actions>
                <div>
                    <p>
                        En este apartado, se muestran las pólizas que cntiene uno o mas cmprobantes cancelados, pero que
                        al momento
                        de contabilizar se encontraban Vigentes.
                    </p>
                    <p>
                        Sugerimos eliminar la póliza y contabilizar nuevamente, con el fin de no incluir coprobantes
                        cancelados
                        dentro de los registros contables.
                    </p>
                </div>
            </q-card-actions>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import visorPdf from '../PDF/VisorPDF.vue';

export default {
    components: {
        visorPdf
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            itemPolizas: [],
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'numPoliza', align: 'center', label: 'No. Póliza', field: 'numPoliza', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
            ],
            filter: '',
            loadingTabla: false,
            initialPagination: {
                sortBy: 'tipo',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            dialogPDF: false,
            dialogConfirm: false,
            accion: '',

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {

    },
    methods: {
        CierraDialog() {
            this.$emit("cierraCancelaXml");
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        async GetCancelados() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando...', messageColor: 'white' })
            this.itemsPolizas = [];
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD')
                let fF = moment(this.fechaF).format('YYYY-MM-DD')
                let response = await axios.get("Reportes/GetCanceladosEnPolizasAsync/erp_" + this.token.rfc + '/' + fI + '/' + fF);
                console.log(response.data);
                this.itemPolizas = [...response.data];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
            this.$q.loading.hide()
        },

        async verPoliza(item) {
            // console.log(item);
            let id = item._id;
            let poliza = {}
            try {
                let response = await axios.get("Polizas/GetPolizas/erp_" + this.token.rfc + '/' + id);
                poliza = { ...response.data }
            } catch (error) {
                console.write(error);
            }

            this.dialogPDF = true;
            this.$nextTick(() => {
                // console.log(item)
                this.$store.state.vistaPreviaStore.color = "19775C"
                this.$store.state.vistaPreviaStore.tipo = "POLIZA"
                this.$refs.miComponenteHijo.VerPolizaPdf(poliza);
            });
        },

        CloseDialogPdf() {
            this.dialogPDF = false;
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        confirm(item, accion) {
            this.accion = accion
            this.$store.state.polizaStore = item
            this.dialogConfirm = true

            console.log(item);
        },

        async PutEstadoPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Cambiando estado de la Póliza. Espere...', messageColor: 'white' })


            let poliza = this.$store.state.polizaStore
            let estado = ''
            if (this.accion == 'eliminar') {
                estado = 'Eliminado'
            }
            else {
                estado = 'Cancelado'
            }
            try {
                let response = await axios.put('Polizas/PutEstadoPoliza/erp_' + this.token.rfc + '/' + poliza._id + '/' + estado)
                // console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                if (this.accion == 'eliminar') {
                    let indice = this.itemPolizas.indexOf(poliza)
                    this.itemPolizas.splice(indice, 1)
                    this.$store.state.listaPolizasStore.splice(indice, 1)
                }
                // SI SE CANCELA SOLO EDITAMOS EL OBJETO
                else {
                    poliza.estatus = 'Cancelado'
                    let indice = this.$store.state.listaPolizasStore.findIndex(x => x._id === poliza._id);
                    Object.assign(this.$store.state.listaPolizasStore[indice], poliza)
                }

                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El estado de la Póliza ha cambiado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

    },
}
</script>

<style>
.my-card {
    width: 100%;
}

.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}

.Activo {
    background: #2196F3 !important;
    color: #fff !important;
}

.Cancelado {
    background: #C10015 !important;
    color: #fff !important;
}

.Validada {
    background: #4CAF50 !important;
    color: #fff !important;
}
</style>