<template>
    <q-card style="max-width: 800px;">
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Catálogo de cuentas en XML
                </q-toolbar-title>
                <q-btn dense round color="green" icon="mdi-file-xml-box" class="q-mr-sm" @click="CreateXml()">
                    <q-tooltip>
                        Generar XML
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section>
            <div class="row q-col-gutter-sm">
                <!-- VERSION -->
                <div class="col-12 ">
                    <q-input v-model="version" dense filled label="Versión" readonly />
                </div>
                <!-- RFC -->
                <div class="col-12 ">
                    <q-input v-model="token.rfc" dense filled label="RFC" readonly />
                </div>
                <!-- MES -->
                <div class="col-12 ">
                    <q-select v-model="selectMes" dense filled :options="optionsMes" label="Mes"
                        option-label="descripcion" />
                </div>
                <!-- AÑO -->
                <div class="col-12 ">
                    <q-select v-model="selectAño" dense filled :options="optionsAño" label="Año" />
                </div>
            </div>
        </q-card-section>
        <q-card-actions>
        </q-card-actions>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {
    data() {
        return {
            version: "1.3",
            selectMes: null,
            selectAño: null,
            optionsMes: [
                { clave: "01", descripcion: "ENERO" },
                { clave: "02", descripcion: "FEBRERO" },
                { clave: "03", descripcion: "MARZO" },
                { clave: "04", descripcion: "ABRIL" },
                { clave: "05", descripcion: "MAYO" },
                { clave: "06", descripcion: "JUNIO" },
                { clave: "07", descripcion: "JULIO" },
                { clave: "08", descripcion: "AGOSTO" },
                { clave: "09", descripcion: "SEPTIEMBRE" },
                { clave: "10", descripcion: "OCTUBRE" },
                { clave: "11", descripcion: "NOVIEMBRE" },
                { clave: "12", descripcion: "DICIEMBRE" }
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore
        },

        optionsAño() {
            let response = [];
            const fechaActual = new Date();
            const añoActual = fechaActual.getFullYear();
            for (let x = 0; x < 6; x++) {
                let menos = añoActual - x;
                response.push(menos)
            }
            return response;
        },

    },
    created() {

    },
    methods: {
        CreateXml() {
            console.log(this.itemsCatalogoCuentas);
            if (!this.selectMes) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el mes',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!this.selectAño) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el año',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            const mes = this.selectMes.clave;
            const año = this.selectAño;
            const rfc = this.token.rfc;

            var cabecera = '<catalogocuentas:Catalogo xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:catalogocuentas="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas" xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas/CatalogoCuentas_1_3.xsd" Anio="' + año + '" Mes="' + mes + '" RFC="' + rfc + '" Version="1.3">'

            var pie = '</catalogocuentas:Catalogo>'
            var detalles = ''
            for (var c of this.itemsCatalogoCuentas) {
                // console.log(c)
                if (c.nivel == 1) {
                    let cuenta = '<catalogocuentas:Ctas Natur="' + c.naturaleza + '" Nivel="1" Desc="' + c.descripcion + '" NumCta="' + c.numCuenta + '" CodAgrup="' + c.numCuenta + '"/>'
                    detalles = detalles + cuenta + '\n'
                }
                else {
                    let splitCuenta = c.numCuenta.split('.');
                    let agrupador = c.numCuentaSat.numCuenta;
                    let subCuenta = ''
                    let contador = splitCuenta.length
                    let nombre = c.descripcion;
                    for (var s = 0; s < contador - 1; s++) {
                        subCuenta = subCuenta + splitCuenta[s] + '.'
                    }
                    subCuenta = subCuenta.substring(0, subCuenta.length - 1)
                    nombre = nombre.replaceAll('&', '&amp;');
                    nombre = nombre.replaceAll('“', '&quot;');
                    nombre = nombre.replaceAll('<', '&lt;');
                    nombre = nombre.replaceAll('>', '&gt;');
                    nombre = nombre.replaceAll('‘', '&apos;');

                    let cuenta = '<catalogocuentas:Ctas Natur="' + c.naturaleza + '" Nivel="' + c.nivel + '" SubCtaDe="' + subCuenta + '" Desc="' + nombre + '" NumCta="' + c.numCuenta + '" CodAgrup="' + agrupador + '"/>'
                    detalles = detalles + cuenta + '\n'
                }
            }
            let nombreArchivo = rfc + año + mes + 'CT'
            this.downloadFile(cabecera + '\n' + detalles + '\n' + pie, nombreArchivo)
        },

        downloadFile(archivo, nombre) {
            let filename = nombre + '.xml';
            let text = archivo;

            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    }
}
</script>