import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import MainPage from '../components/Inicio/MainPage.vue'
import Configuracion from '../components/Configuracion/Configuracion.vue'

import MainDescargas from '../components/Descargas/MainDescargas.vue'
import Descargas from '../components/Descargas/Descargas.vue'
import DescargasUUID from '../components/Descargas/DescargasUUID.vue'
import DescargasXMLS from '../components/Descargas/DescargasXMLS.vue'
import DescargasMetadata from '../components/Descargas/DescargasMetadata.vue'

import MainReportesEspeciales from '../components/ReportesEspeciales/MainReportesEspeciales.vue'
import CalculoAjusteAnualInflacion from '../components/ReportesEspeciales/CalculoAjusteAnualInflacion/CalculoAjusteAnualInflacion.vue'
import ConciliacionContableFiscal from '../components/ReportesEspeciales/ConcialiacionContableFiscal/ConciliacionContableFiscal.vue'
import Depreciacion from '../components/ReportesEspeciales/Depreciacion/Depreciacion.vue'
import DevolucionesIva from '../components/ReportesEspeciales/DevolucionesIva/DevolucionesIva.vue'
import Diot from '../components/ReportesEspeciales/Diot/Diot.vue'
import EstadoCostoProduccionVenta from '../components/ReportesEspeciales/EstadoCostoProduccionVenta/EstadoCostoProduccionVenta.vue'
import FlujoEfectivo from '../components/ReportesEspeciales/FlujoEfectivo/FlujoEfectivo.vue'
import ProvisionalesIsr from '../components/ReportesEspeciales/ProvisionalesIsr/ProvisionalesIsr.vue'
import ProvisionalesIva from '../components/ReportesEspeciales/ProvisionalesIva/ProvisionalesIva.vue'

import MainReportes from '../components/Reportes/MainReportes.vue'
import Balanza from '../components/Reportes/Balanza/Balanza.vue'
import EstadoResultados from '../components/Reportes/EstadoResultados/EstadoResultados.vue'
import EstadoSituacionFinanciera from '../components/Reportes/EstadoSituacionFinanciera/EstadoSituacionFinanciera.vue'
import EstadoSituacionFinancieraAnual from '../components/Reportes/EstadoSituacionFinanciera/EstadoSituacionFinancieraAnual.vue'
import LibroDiario from '../components/Reportes/LibroDiario/LibroDiario.vue'
import RelacionesFinancieras from '../components/Reportes/RelacionesFinancieras/RelacionesFinancieras.vue'
import Mayores from '../components/Reportes/Mayores/Mayores.vue'

import Polizas from '../components/Polizas/ListaPolizas.vue'
import CatalogoCuentas from '../components/Catalogo/ListaCatalogoCuentas.vue'
import Bancos from '../components/Bancos/ListaBancos.vue'

import MainDescargasScraper from '../components/DescargasScraper/MainDescargasScraper.vue'
import DescargasScraperXMLS from '../components/DescargasScraper/DescargasScraperXMLS.vue'

import MainComprobantesContabilizados from '../components/Comprobantes/MainComprobantesContabilizados.vue';
import ComprobantesContabilizados from '../components/Comprobantes/ComprobantesContabilizados.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: MainPage, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true },
    children: [
      { path: 'Home', component: Home, name: 'Home', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'Configuracion', component: Configuracion, name: 'Configuracion', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: '/Polizas', name: 'Polizas', component: Polizas, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: '/CatalogoCuentas', name: 'CatalogoCuentas', component: CatalogoCuentas, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: '/Bancos', name: 'Bancos', component: Bancos, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
    ],
  },
  {
    path: '/MainDescargas', component: MainDescargas, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true },
    children: [
      { path: 'Configuracion', component: Configuracion, name: 'Configuracion', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'Descargas', component: Descargas, name: 'Descargas', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      // { path: 'DescargasXMLS', component: DescargasXMLS, name: 'DescargasXMLS', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      // { path: 'DescargasUUID', component: DescargasUUID, name: 'DescargasUUID', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      // { path: 'DescargasMetadata', component: DescargasMetadata, name: 'DescargasMetadata', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
    ],
  },
  {
    path: '/MainReportesEspeciales', component: MainReportesEspeciales, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true },
    children: [
      { path: 'CalculoAjusteAnualInflacion', component: CalculoAjusteAnualInflacion, name: 'CalculoAjusteAnualInflacion', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'ConciliacionContableFiscal', component: ConciliacionContableFiscal, name: 'ConciliacionContableFiscal', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'Depreciacion', component: Depreciacion, name: 'Depreciacion', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'DevolucionesIva', component: DevolucionesIva, name: 'DevolucionesIva', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'Diot', component: Diot, name: 'Diot', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'EstadoCostoProduccionVenta', component: EstadoCostoProduccionVenta, name: 'EstadoCostoProduccionVenta', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'FlujoEfectivo', component: FlujoEfectivo, name: 'FlujoEfectivo', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'ProvisionalesIsr', component: ProvisionalesIsr, name: 'ProvisionalesIsr', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'ProvisionalesIva', component: ProvisionalesIva, name: 'ProvisionalesIva', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
    ]
  },
  {
    path: '/MainReportes', component: MainReportes, meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true },
    children: [
      { path: 'Balanza', component: Balanza, name: 'Balanza', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'EstadoResultados', component: EstadoResultados, name: 'EstadoResultados', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'EstadoSituacionFinanciera', component: EstadoSituacionFinanciera, name: 'EstadoSituacionFinanciera', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'EstadoSituacionFinancieraAnual', component: EstadoSituacionFinancieraAnual, name: 'EstadoSituacionFinancieraAnual', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'LibroDiario', component: LibroDiario, name: 'LibroDiario', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'RelacionesFinancieras', component: RelacionesFinancieras, name: 'RelacionesFinancieras', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'Mayores', component: Mayores, name: 'Mayores', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },


    ]
  },
  { path: '/about', name: 'About', component: () => import('../views/About.vue') },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  {
    path: '/MainDescargasScraper', component: MainDescargasScraper, meta: { Administrador: true, Gasolinero: true },
    children: [
      { path: 'Configuracion', component: Configuracion, name: 'Configuracion', meta: { Administrador: true, Gasolinero: true } },
      { path: 'DescargasScraper', component: Descargas, name: 'DescargasScraper', meta: { Administrador: true, Gasolinero: true } },
      { path: 'DescargasScraperXMLS', component: DescargasScraperXMLS, name: 'DescargasScraperXMLS', meta: { Administrador: true, Gasolinero: true } },
      { path: 'DescargasXMLS', component: DescargasXMLS, name: 'DescargasXMLS', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },
      { path: 'DescargasMetadata', component: DescargasMetadata, name: 'DescargasMetadata', meta: { Administrador: true, Auxiliar: true, Responsable: true, Gasolinero: true } },

    ],
  },
  {
    path: '/MainComprobantesContabilizados', component: MainComprobantesContabilizados, meta: { Administrador: true, Gasolinero: true },
    children: [
      { path: 'ComprobantesContabilizados', component: ComprobantesContabilizados, name: 'ComprobantesContabilizados', meta: { Administrador: true, Gasolinero: true } },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol == 'Gasolinero') {
    if (to.matched.some(record => record.meta.Gasolinero)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol == 'Auxiliar') {
    if (to.matched.some(record => record.meta.Auxiliar)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol == 'Responsable') {
    if (to.matched.some(record => record.meta.Responsable)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
