<template>
    <q-page class="q-pa-lg">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-7">
                <div class="text-h5">Relaciones Financieras</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-3">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify" @click="GetBalanza">
                            <q-tooltip> Buscar </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-microsoft-excel" @click="ExportExcel">
                            <q-tooltip> Excel </q-tooltip>
                        </q-btn>
                        <q-btn flat round color="primary" icon="mdi-file-pdf-box" @click="ExportarPdf">
                            <q-tooltip> PDF </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>

        <!-- LISTA DE RELACIONES FINANCIERAS -->
        <div class="row q-col-gutter-sm">
            <div class="col-12">
                <q-list class="rounded-borders">
                    <!-- LIQUIDEZ -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Liquidez </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumLiquidez) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Activo Circulante ({{
                    formatCurrency(sumActivoCirculante) }}) / Pasivo Circulante ({{
                    formatCurrency(sumPasivoCirculante) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumLiquidez)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa disponde de {{
                    formatCurrency(sumLiquidez) }} de activo circulante para garantizar cada
                                            peso de deuda a corto plazo.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- SOLVENCIA ECONOMICA -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Solvencia Económica </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumSolvenciaEconomica) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Activo Circulante sin
                                        Almacén ({{
                    formatCurrency(sumActivoCirculante_SinInventario) }}) / Pasivo Circulante ({{
                    formatCurrency(sumPasivoCirculante) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumSolvenciaEconomica)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">Por cada peso de Pasivo Total la empresa
                                            se encuentra financiada por {{
                    formatCurrency(sumSolvenciaEconomica) }}.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- ESTABILIDAD -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Estabilidad </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumEstabilidad) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Pasivo Total ({{
                    formatCurrency(sumPasivoTotal) }}) / Capital Contable ({{
                    formatCurrency(sumCapital) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumEstabilidad)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">Cada peso de Capital Contable, se
                                            encuentra reflejado por un {{
                    formatCurrency(sumEstabilidad) }} en las deudas que tiene la empresa.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- LIQUIDEZ O PAGO INMEDIATO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Liquidez ó Pago Inmediato </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumLiquidezOPagoInmediato) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Activo Circulante
                                        de Efectivo y Bancos ({{
                    formatCurrency(sumActivoEfectivoYBancos) }}) / Pasivo Circulante ({{
                    formatCurrency(sumPasivoCirculante) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumLiquidezOPagoInmediato)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa disponde de {{
                    formatCurrency(sumLiquidezOPagoInmediato) }} de solvencia para garantizar
                                            cada pago de deuda a corto plazo.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- ENDEUDAMIENTO -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Endeudamiento </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumEndeudamiento) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Pasivo Total ({{
                    formatCurrency(sumPasivoTotal) }}) / Activo Total ({{
                    formatCurrency(sumActivoTotal) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumEndeudamiento)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa se encuentra en un valor de
                                            {{
                    formatCurrency(sumEndeudamiento) }} de endeudamiento sobre sus recursos
                                            propios.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- INVERSION Y CAPITAL CONTABLE -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Inversión y Capital Contable </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumInversionYCapitalContable) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Capital
                                        Contable ({{
                    formatCurrency(sumCapital) }}) / Activo Total ({{
                    formatCurrency(sumActivoTotal) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumInversionYCapitalContable)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa esta invirtiendo adecuadamente
                                            {{
                    formatCurrency(sumInversionYCapitalContable) }} por cada peso en activos
                                            para la sociedad.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- PROTECCION AL PASIVO CIRCULANTE -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Protección al Pasivo Circulante </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumProteccionAlPasivoCirculante) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Capital
                                        Contable ({{
                    formatCurrency(sumCapital) }}) / Pasivo Circulante ({{
                    formatCurrency(sumPasivoCirculante) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumProteccionAlPasivoCirculante)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa cuenta con {{
                    formatCurrency(sumProteccionAlPasivoCirculante) }} para solventar cada peso
                                            de deuda a corto plazo.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- MARGEN DE UTILIDAD -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Margen de Utilidad </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumMargenDeUtilidad) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Utilidad Neta ({{
                    formatCurrency(sumUtilidadNeta) }}) / Ventas Netas ({{
                    formatCurrency(sumVentasNetas) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumMargenDeUtilidad)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa es productiva {{
                    formatCurrency(sumMargenDeUtilidad) }} respecto a las ventas.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                    <!-- RENTABILIDAD DE LA INVERSION -->
                    <q-expansion-item header-class="bg-grey-3" class="q-mb-md" switch-toggle-side expand-separator
                        icon="perm_identity" default-closed>
                        <template v-slot:header>
                            <q-item-section> Rentabilidad de la Inversión </q-item-section>
                            <q-item-section side>
                                {{ formatCurrency(sumRentabilidadDeLaInversion) }}
                            </q-item-section>
                        </template>
                        <q-table class="shadow-0" full-width row-key="name" :columns="columns" :data="rows" hide-header
                            hide-bottom :rows-per-page-options="[0]" :pagination.sync="pagination">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="descripcion" :props="props">Utilidad Neta
                                        ({{
                                        formatCurrency(sumUtilidadNeta) }}) / Activo Total ({{
                                        formatCurrency(sumActivoTotal) }})</q-td>
                                </q-tr>
                                <template v-if="!isNaN(sumRentabilidadDeLaInversion)">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">La empresa esta generando {{
                                            formatCurrency(sumRentabilidadDeLaInversion) }} de utilidad sobre el total
                                            de activos.</q-td>
                                    </q-tr>
                                </template>
                            </template>
                        </q-table>
                    </q-expansion-item>
                </q-list>
            </div>
        </div>
    </q-page>
</template>
<script>
import { format } from "date-fns";
import { parse } from "date-fns";
import { endOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";
import axios from "axios";
import { QSpinnerCube } from "quasar";
import { RelacionesFinancierasPDF } from "../../PDF/RelacionesFinancierasPDF.js";
import * as XLSX from "xlsx";

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),

            items: [], //Todos

            itemsActivoTotal: [], //101-199
            itemsActivoCirculante: [], //101-149
            itemsActivoEfectivoYBancos: [], //101-102
            itemsActivoInventario: [], //115

            itemsPasivoTotal: [], //201-299
            itemsPasivoCirculante: [], //201-249

            itemsCapital: [], //301-399

            itemsVentasNetas: [], //401-402

            itemsIngresosAux: [], //401-403
            itemsCostosAux: [], //501-504 //601-607 //610-611 //613-614 //701-704
            itemsParticipacionesAux: [], //608-609

            columns: [
                {
                    name: "descripcion",
                    align: "center",
                    label: "Descripcion",
                    field: "descripcion",
                    style: "text-align: left;",
                },
            ],

            rows: [{ descripcion: "detalle" }],

            pagination: {
                rowsPerPage: 0,
            },

            expansion: true,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        empresa() {
            return this.$store.state.empresaStore;
        },

        fehaIMasked() {
            moment.locale("es-mx");
            return this.fechaI ? moment(this.fechaI).format("DD/MMMM/yyyy") : "";
        },

        fechaFMasked() {
            moment.locale("es-mx");
            return this.fechaF ? moment(this.fechaF).format("DD/MMMM/yyyy") : "";
        },

        sumActivoTotal() {
            return this.itemsActivoTotal.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumActivoCirculante() {
            return this.itemsActivoCirculante.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumActivoEfectivoYBancos() {
            return this.itemsActivoEfectivoYBancos.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumActivoInventario() {
            return this.itemsActivoInventario.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumPasivoTotal() {
            return this.itemsPasivoTotal.reduce((a, b) => a - b.debeFinal + b.haberFinal, 0);
        },

        sumPasivoCirculante() {
            return this.itemsPasivoCirculante.reduce((a, b) => a - b.debeFinal + b.haberFinal, 0);
        },

        sumCapitalContable() {
            return this.itemsCapital.reduce((a, b) => a - b.debeFinal + b.haberFinal, 0);
        },

        sumUtilidadNeta() {
            return this.sumResultadoEjercicio;
        },

        sumVentasNetas() {
            return this.itemsVentasNetas.reduce((a, b) => a - b.debeFinal + b.haberFinal, 0);
        },

        sumIngresosAux() {
            return this.itemsIngresosAux.reduce((a, b) => a - b.debeFinal + b.haberFinal, 0);
        },

        sumCostosAux() {
            return this.itemsCostosAux.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumParticipacionesAux() {
            return this.itemsParticipacionesAux.reduce((a, b) => a + b.debeFinal - b.haberFinal, 0);
        },

        sumResultadoEjercicio() {
            let a1 = this.sumIngresosAux;
            let a2 = this.sumCostosAux;
            let a3 = this.sumParticipacionesAux;
            return (a1 - a2 + a3);
        },

        sumCapital() {
            let a1 = this.sumCapitalContable;
            let a2 = this.sumResultadoEjercicio;
            return (a1 + a2);
        },

        sumActivoCirculante_SinInventario() {
            let a1 = this.sumActivoCirculante;
            let a2 = this.sumActivoInventario;
            return (a1 - a2);
        },

        sumLiquidez() {
            let a = this.sumActivoCirculante;
            let p = this.sumPasivoCirculante;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumSolvenciaEconomica() {
            let a = this.sumActivoCirculante_SinInventario;
            let p = this.sumPasivoCirculante;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumEstabilidad() {
            let a = this.sumPasivoTotal;
            let p = this.sumCapital;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumLiquidezOPagoInmediato() {
            let a = this.sumActivoEfectivoYBancos;
            let p = this.sumPasivoCirculante;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumEndeudamiento() {
            let a = this.sumPasivoTotal;
            let p = this.sumActivoTotal;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumInversionYCapitalContable() {
            let a = this.sumCapital;
            let p = this.sumActivoTotal;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumProteccionAlPasivoCirculante() {
            let a = this.sumCapital;
            let p = this.sumPasivoCirculante;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumMargenDeUtilidad() {
            let a = this.sumUtilidadNeta;
            let p = this.sumVentasNetas;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },

        sumRentabilidadDeLaInversion() {
            let a = this.sumUtilidadNeta;
            let p = this.sumActivoTotal;

            if (p != 0) {
                return a / p;
            }
            return NaN;
        },
    },
    created() { },
    methods: {
        FormatDate(value) {
            let fecha_a = value.replace("T", " ");
            let fecha_b = fecha_a.replace("Z", "");
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(
                cadenaFechaConHora,
                "yyyy-MM-dd HH:mm:ss",
                new Date()
            );
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll("/", "-");
            const fecha = parse(fechaI, "yyyy-MM-dd", new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        formatCurrency(value) {
            return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },

        async GetBalanza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando Relaciones Financieras...', messageColor: 'white' })
            try {
                let fechaI = new Date(this.fechaI);
                let fechaF = new Date(this.fechaF);
                fechaI = format(fechaI, 'yyyy-MM-dd');
                fechaF = format(fechaF, 'yyyy-MM-dd');
                // console.log(fechaI, fechaF)
                let response = await axios.get('Reportes/GetBalanzaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF)
                let respuesta = response.data;
                // console.log(respuesta);

                this.items = respuesta.filter(
                    (x) => x.nivel === 1
                );

                this.itemsActivoTotal = this.items.filter(
                    (x) => x.noCuenta[0] === '1'
                );

                this.itemsActivoCirculante = this.itemsActivoTotal.filter(
                    (x) => x.noCuenta[1] === '0' || x.noCuenta[1] === '1' || x.noCuenta[1] === '2' || x.noCuenta[1] === '3' || x.noCuenta[1] === '4'
                );

                this.itemsActivoEfectivoYBancos = this.itemsActivoCirculante.filter(
                    (x) => x.noCuenta === "101" || x.noCuenta === "102"
                );

                this.itemsActivoInventario = this.itemsActivoCirculante.filter(
                    (x) => x.noCuenta === "115"
                );

                this.itemsPasivoTotal = this.items.filter(
                    (x) => x.noCuenta[0] === '2'
                );

                this.itemsPasivoCirculante = this.itemsPasivoTotal.filter(
                    (x) => x.noCuenta[1] === '0' || x.noCuenta[1] === '1' || x.noCuenta[1] === '2' || x.noCuenta[1] === '3' || x.noCuenta[1] === '4'
                );

                this.itemsCapital = this.items.filter(
                    (x) => x.noCuenta[0] === '3'
                );

                this.itemsIngresosAux = this.items.filter(
                    (x) => x.noCuenta === "401" || x.noCuenta === "402" || x.noCuenta === "403"
                );

                this.itemsVentasNetas = this.itemsIngresosAux.filter(
                    (x) => x.noCuenta === "401" || x.noCuenta === "402"
                );

                this.itemsCostosAux = this.items.filter(
                    (x) => x.noCuenta === "501" || x.noCuenta === "502" || x.noCuenta === "503" || x.noCuenta === "504" || x.noCuenta === "601" || x.noCuenta === "602" || x.noCuenta === "603" || x.noCuenta === "604" || x.noCuenta === "605" || x.noCuenta === "606" || x.noCuenta === "607" || x.noCuenta === "610" || x.noCuenta === "611" || x.noCuenta === "613" || x.noCuenta === "614" || x.noCuenta === "701" || x.noCuenta === "702" || x.noCuenta === "703" || x.noCuenta === "704"
                );

                this.itemsParticipacionesAux = this.items.filter(
                    (x) => x.noCuenta === "608" || x.noCuenta === "609"
                );

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async ExportarPdf() {
            const dataLiquidez = [this.sumActivoCirculante, this.sumPasivoCirculante, this.sumLiquidez];
            const dataSolvenciaEconomica = [this.sumActivoCirculante_SinInventario, this.sumPasivoCirculante, this.sumSolvenciaEconomica];
            const dataEstabilidad = [this.sumPasivoTotal, this.sumCapital, this.sumEstabilidad];
            const dataLiquidezOPagoInmediato = [this.sumActivoEfectivoYBancos, this.sumPasivoCirculante, this.sumLiquidezOPagoInmediato];
            const dataEndeudamiento = [this.sumPasivoTotal, this.sumActivoTotal, this.sumEndeudamiento];
            const dataInversionYCapitalContable = [this.sumCapital, this.sumActivoTotal, this.sumInversionYCapitalContable];
            const dataProteccionAlPasivoCirculante = [this.sumCapital, this.sumPasivoCirculante, this.sumProteccionAlPasivoCirculante];
            const dataMargenDeUtilidad = [this.sumUtilidadNeta, this.sumVentasNetas, this.sumMargenDeUtilidad];
            const dataRentabilidadDeLaInversion = [this.sumUtilidadNeta, this.sumActivoTotal, this.sumRentabilidadDeLaInversion];

            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);
            const pdfBase64 = await RelacionesFinancierasPDF(
                dataLiquidez,
                dataSolvenciaEconomica,
                dataEstabilidad,
                dataLiquidezOPagoInmediato,
                dataEndeudamiento,
                dataInversionYCapitalContable,
                dataProteccionAlPasivoCirculante,
                dataMargenDeUtilidad,
                dataRentabilidadDeLaInversion,
                this.empresa,
                fechaI,
                fechaF
            );
            const fileName =
                "Relaciones_Financieras_del_" +
                this.FormatoFechaSinHora(fechaI) +
                "_al_" +
                this.FormatoFechaSinHora(fechaF) +
                ".pdf";
            const link = document.createElement("a");
            link.href = pdfBase64;
            link.download = fileName;
            link.click();
        },

        async ExportExcel() {
            let fechaI = new Date(this.fechaI);
            let fechaF = new Date(this.fechaF);

            const columnsToInclude = ["descripcion", "importe"];

            // console.log(arrayDeValores)
            const libroTrabajo = XLSX.utils.book_new();

            //CREAMOS LA HOJA CON TODO EL CONTENIDO
            const hojaCalculo = XLSX.utils.aoa_to_sheet([
                ["RELACIONES FINANCIERAS"],
                [this.empresa.rfc + "| " + this.empresa.nombre],
                [
                    "DEL " +
                    this.FormatoFechaSinHora(fechaI) +
                    " AL " +
                    this.FormatoFechaSinHora(fechaF),
                ],
                [],
                ["LIQUIDEZ", "$", { t: "n", v: this.sumLiquidez, z: "#,##0.00" }],
                [
                    "Activo Circulante (" + this.formatCurrency(this.sumActivoCirculante) + ") / Pasivo Circulante (" + this.formatCurrency(this.sumPasivoCirculante) + ")"
                ],
                [
                    "La empresa disponde de " + this.formatCurrency(this.sumLiquidez) + " de activo circulante para garantizar cada peso de deuda a corto plazo.",
                ],
                [],
                ["SOLVENCIA ECONOMICA", "$", { t: "n", v: this.sumSolvenciaEconomica, z: "#,##0.00" }],
                [
                    "Activo Circulante sin Almacén (" + this.formatCurrency(this.sumActivoCirculante_SinInventario) + ") / Pasivo Circulante (" + this.formatCurrency(this.sumPasivoCirculante) + ")"
                ],
                [
                    "Por cada peso de Pasivo Total la empresa se encuentra financiada por " + this.formatCurrency(this.sumSolvenciaEconomica) + ".",
                ],
                [],
                ["ESTABILIDAD", "$", { t: "n", v: this.sumEstabilidad, z: "#,##0.00" }],
                [
                    "Pasivo Total (" + this.formatCurrency(this.sumPasivoTotal) + ") / Capital Contable (" + this.formatCurrency(this.sumCapital) + ")"
                ],
                [
                    "Cada peso de Capital Contable, se encuentra reflejado por un " + this.formatCurrency(this.sumEstabilidad) + " en las deudas que tiene la empresa.",
                ],
                [],
                ["LIQUIDEZ O PAGO INMEDIATO", "$", { t: "n", v: this.sumLiquidezOPagoInmediato, z: "#,##0.00" }],
                [
                    "Activo Circulante de Efectivo y Bancos (" + this.formatCurrency(this.sumActivoEfectivoYBancos) + ") / Pasivo Circulante (" + this.formatCurrency(this.sumPasivoCirculante) + ")"
                ],
                [
                    "La empresa disponde de " + this.formatCurrency(this.sumLiquidezOPagoInmediato) + " de solvencia para garantizar cada pago de deuda a corto plazo.",
                ],
                [],
                ["ENDEUDAMIENTO", "$", { t: "n", v: this.sumEndeudamiento, z: "#,##0.00" }],
                [
                    "Pasivo Total (" + this.formatCurrency(this.sumPasivoTotal) + ") / Activo Total (" + this.formatCurrency(this.sumActivoTotal) + ")"
                ],
                [
                    "La empresa se encuentra en un valor de " + this.formatCurrency(this.sumEndeudamiento) + " de endeudamiento sobre sus recursos propios.",
                ],
                [],
                ["INVERSION Y CAPITAL CONTABLE", "$", { t: "n", v: this.sumInversionYCapitalContable, z: "#,##0.00" }],
                [
                    "Capital Contable (" + this.formatCurrency(this.sumCapital) + ") / Activo Total (" + this.formatCurrency(this.sumActivoTotal) + ")"
                ],
                [
                    "La empresa esta invirtiendo adecuadamente " + this.formatCurrency(this.sumInversionYCapitalContable) + " por cada peso en activos para la sociedad.",
                ],
                [],
                ["PROTECCION AL PASIVO CIRCULANTE", "$", { t: "n", v: this.sumProteccionAlPasivoCirculante, z: "#,##0.00" }],
                [
                    "Capital Contable (" + this.formatCurrency(this.sumCapital) + ") / Pasivo Circulante (" + this.formatCurrency(this.sumPasivoCirculante) + ")"
                ],
                [
                    "La empresa cuenta con " + this.formatCurrency(this.sumProteccionAlPasivoCirculante) + " para solventar cada peso de deuda a corto plazo.",
                ],
                [],
                ["MARGEN DE UTILIDAD", "$", { t: "n", v: this.sumMargenDeUtilidad, z: "#,##0.00" }],
                [
                    "Utilidad Neta (" + this.formatCurrency(this.sumUtilidadNeta) + ") / Ventas Netas (" + this.formatCurrency(this.sumVentasNetas) + ")"
                ],
                [
                    "La empresa es productiva " + this.formatCurrency(this.sumMargenDeUtilidad) + " respecto a las ventas.",
                ],
                [],
                ["RENTABILIDAD DE LA INVERSION", "$", { t: "n", v: this.sumRentabilidadDeLaInversion, z: "#,##0.00" }],
                [
                    "Utilidad Neta (" + this.formatCurrency(this.sumUtilidadNeta) + ") / Activo Total (" + this.formatCurrency(this.sumActivoTotal) + ")"
                ],
                [
                    "La empresa esta generando " + this.formatCurrency(this.sumRentabilidadDeLaInversion) + " de utilidad sobre el total de activos.",
                ],
                [],
                ["--------------------", "--------------------", "--------------------"],
            ]);

            // Combinar celdas A1 a H1 y centrar texto
            hojaCalculo["!merges"] = [{ s: { r: 0, c: 0 }, e: { c: 3, r: 0 } }];
            hojaCalculo["!merges"].push({ s: { c: 0, r: 1 }, e: { c: 3, r: 1 } });
            hojaCalculo["!merges"].push({ s: { c: 0, r: 2 }, e: { c: 3, r: 2 } });

            // Añadir la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, "Hoja1");

            // Guardar el libro como un archivo XLSX
            const nombreArchivo =
                "RELACIONES FINANCIERAS " +
                this.empresa.rfc +
                "_" +
                this.empresa.nombre +
                " DEL " +
                this.FormatoFechaSinHora(fechaI) +
                " AL " +
                this.FormatoFechaSinHora(fechaF);
            XLSX.writeFile(libroTrabajo, nombreArchivo + ".xlsx");
        },

        FormatoFechaSinHora(value) {
            const fecha = new Date(value);
            const fechaFormateada = format(fecha, "dd-MMMM-yyyy", { locale: es });
            return fechaFormateada.toUpperCase();
        },
    },
};
</script>

<style>
.header-tabla thead th {
    border-bottom: 8px solid #662e91;
}
</style>
