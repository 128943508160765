import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const BalanzaPDF = async (ObjBalanza, ObjBalanzaTodos, objEmpresa, fechaI, fechaF) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 15;
            let color = '#662e91'

            //LOGO
            // if (objEmpresa.logo.base64 != '') {
            //     doc.addImage(objEmpresa.logo.base64, 'PNG', xCabecera, 3, 58, 18);
            // }

            //DATOS DE LA EMPRESA Y POLIZA
            // doc.setFontSize(14);
            // doc.setFont('helvetica', 'bold');
            // doc.text('BALANZA DE COMPROBACIÓN', 210, yCabecera, { align: 'right' });
            // doc.setFontSize(10);
            // doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });

            // let periodo = 'PERIODO DEL ' + FormatoFechaSinHora(fechaI) + ' AL ' + FormatoFechaSinHora(fechaF);
            // doc.text(periodo, 210, yCabecera += 5, { align: 'right' });


            // Definir el color de la barra
            const widthT = 210;
            const heightT = 8;
            // Dibujar la barra de color
            doc.setFillColor(color);
            doc.rect(5, yCabecera + 5, widthT, heightT, 'F');
            doc.setFontSize(6);
            doc.setTextColor("#fff");
            doc.text('(Inicial)', 110, yCabecera += 11, { align: 'right' });
            doc.text('(Inicial)', 130, yCabecera, { align: 'right' });
            doc.text('(Final)', 190, yCabecera, { align: 'right' });
            doc.text('(Final)', 210, yCabecera, { align: 'right' });
            doc.setFontSize(10);
            doc.text('Cuenta', 7, yCabecera, { align: 'left' });
            doc.text('Nombre', 25, yCabecera, { align: 'left' });
            doc.text('Debe', 110, yCabecera - 3, { align: 'right' });
            doc.text('Haber', 130, yCabecera - 3, { align: 'right' });
            doc.text('Cargos', 150, yCabecera, { align: 'right' });
            doc.text('Abonos', 170, yCabecera, { align: 'right' });
            doc.text('Debe', 190, yCabecera - 3, { align: 'right' });
            doc.text('Haber', 210, yCabecera - 3, { align: 'right' });

            //RECORREMOS EL ARRAY DE LOS CONCEPTOS
            let yLista = yCabecera + 2;
            for (let c of ObjBalanza) {
                //CLAVE DEL SAT
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(8);
                doc.setTextColor("#000");

                let noCuenta = c.noCuenta
                let cuenta = c.nombreCuenta
                let debeInicial = c.debeInicial;
                let haberInicial = c.haberInicial
                let cargo = c.cargo
                let abono = c.abono
                let debeFinal = c.debeFinal
                let haberFinal = c.haberFinal

                doc.setFontSize(7);
                //doc.text(cuenta, 7, yLista += 7, { align: 'left' });
                doc.text(formatoNumerico(noCuenta), 5, yLista += 5, { align: 'left' });
                doc.text(formatoNumerico(debeInicial), 110, yLista, { align: 'right' });
                doc.text(formatoNumerico(haberInicial), 130, yLista, { align: 'right' });
                doc.text(formatoNumerico(cargo), 150, yLista, { align: 'right' });
                doc.text(formatoNumerico(abono), 170, yLista, { align: 'right' });
                doc.text(formatoNumerico(debeFinal), 190, yLista, { align: 'right' });
                doc.text(formatoNumerico(haberFinal), 210, yLista, { align: 'right' });
                doc.setFontSize(8);

                let cuenta_ = doc.splitTextToSize(cuenta, 70);
                let cuenta_Height = doc.getTextDimensions(cuenta_).h;

                doc.text(cuenta_, 25, yLista, { align: 'left' });

                yLista += cuenta_Height - 1

                //INSERTMOS EL SALTO DE PAGINA
                let espacioDisponible = doc.internal.pageSize.height - 40;
                let dif = yLista > espacioDisponible

                if (dif) {
                    doc.addPage();
                    yCabecera = 15;
                    doc.setFillColor(color);
                    doc.rect(5, yCabecera + 5, widthT, heightT, 'F');
                    doc.setFontSize(6);
                    doc.setTextColor("#fff");
                    doc.text('(Inicial)', 110, yCabecera += 11, { align: 'right' });
                    doc.text('(Inicial)', 130, yCabecera, { align: 'right' });
                    doc.text('(Final)', 190, yCabecera, { align: 'right' });
                    doc.text('(Final)', 210, yCabecera, { align: 'right' });
                    doc.setFontSize(10);
                    doc.text('Cuenta', 7, yCabecera, { align: 'left' });
                    doc.text('Nombre', 25, yCabecera, { align: 'left' });
                    doc.text('Debe', 110, yCabecera - 3, { align: 'right' });
                    doc.text('Haber', 130, yCabecera - 3, { align: 'right' });
                    doc.text('Cargos', 150, yCabecera, { align: 'right' });
                    doc.text('Abonos', 170, yCabecera, { align: 'right' });
                    doc.text('Debe', 190, yCabecera - 3, { align: 'right' });
                    doc.text('Haber', 210, yCabecera - 3, { align: 'right' });
                    yLista = yCabecera + 2;
                } else {
                    const color_ = "#662E91"
                    doc.setDrawColor(color_);
                    doc.setLineWidth(.1);
                    doc.line(5, yLista, 210, yLista);
                }
            }

            let cuentas = ObjBalanzaTodos.filter(o => o.tipoCuenta === 'R')
            let sumDebeInicial = cuentas.reduce((a, b) => a + b.debeInicial, 0);
            let sumHaberInicial = cuentas.reduce((a, b) => a + b.haberInicial, 0);
            let sumCargo = cuentas.reduce((a, b) => a + b.cargo, 0);
            let sumAbono = cuentas.reduce((a, b) => a + b.abono, 0);
            let sumDebeFinal = cuentas.reduce((a, b) => a + b.debeFinal, 0);
            let sumHaberFinal = cuentas.reduce((a, b) => a + b.haberFinal, 0);

            // CAPITAL
            let yTotalBalanza = yLista;
            let xTotalBalanza = 17;

            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FFF");
            doc.setFillColor(color);

            doc.rect(5, yTotalBalanza, 210, 9, 'F');
            doc.text('TOTALES:', 7, yTotalBalanza += 6, { align: 'left' });
            doc.text(formatoNumerico(sumDebeInicial), 110, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumHaberInicial), 130, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumCargo), 150, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumAbono), 170, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumDebeFinal), 190, yTotalBalanza, { align: 'right' });
            doc.text(formatoNumerico(sumHaberFinal), 210, yTotalBalanza, { align: 'right' });

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                let color = '#000000'
                doc.setTextColor(color);

                if (objEmpresa.logo.base64 != '') {
                    doc.addImage(objEmpresa.logo.base64, 'PNG', 5, 3, 50, 15);
                }

                //DATOS DE LA EMPRESA Y POLIZA
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('BALANZA DE COMPROBACIÓN', 210, 5, { align: 'right' });
                doc.setFontSize(10);
                doc.text(objEmpresa.rfc + ' | ' + objEmpresa.nombre, 210, 10, { align: 'right' });

                // doc.text(objEmpresa.nombre, 210, yCabecera += 5, { align: 'right' });
                let periodo = 'PERIODO DEL ' + FormatoFechaSinHora(fechaI) + ' AL ' + FormatoFechaSinHora(fechaF);
                doc.text(periodo, 210, 15, { align: 'right' });

                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { BalanzaPDF };