<template>
    <q-card style="width: 1200px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense v-close-popup>
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Póliza de Cierre
                </q-toolbar-title>
                <q-btn dense round color="blue" icon="mdi-check-bold" class="q-mr-sm" @click="GeneraPolizaCierre()"
                    v-if="!banderaGenera">
                    <q-tooltip>
                        Generar póliza de cierre
                    </q-tooltip>
                </q-btn>
                <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" v-if="banderaGenera"
                    @click="PostPolizaCierre()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section class="scroll">
            <div class="row q-col-gutter-sm">
                <div class="col-12 col-md-6">
                    <q-select dense class="full-width" filled :options="itemsAñosEjercicio" label="Año del Ejercicio"
                        option-label="objImp" v-model="selectAño" />
                </div>
                <!-- NUMERO DE CUENTA -->
                <div class="col-12 col-md-6">
                    <q-select dense filled label="Cuenta de resultado" v-model="selectCuenta" option-label="cuenta"
                        @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas" use-input input-debounce="0"
                        ref="refNumCuenta">
                        <q-badge floating rounded color="primary" :label="nombreCuentaBadge" />
                    </q-select>
                </div>
                <div class="col-12">
                    <!-- TABLA CON LOS MOVIMIENTOS -->
                    <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" :data="poliza.asientos"
                        :columns="columnsAientos" row-key="_id" :rows-per-page-options="[0]" hide-bottom>
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <!-- <q-td auto-width key="acciones">
                                    <q-btn size="md" color="red" rounded flat dense @click="eliminarAsiento(props.row)"
                                        icon="mdi-delete">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Eliminar</q-tooltip>
                                    </q-btn>
                                    <q-btn size="md" color="blue" rounded flat dense @click="editarAiento(props.row)"
                                        icon="mdi-pencil">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Editar</q-tooltip>
                                    </q-btn>
                                </q-td> -->
                                <q-td key="cuenta" :props="props">{{ props.row.cuenta.cuenta }}
                                    <q-popup-edit v-model="props.row.cuenta" title="Editar cuenta" v-slot="scope"
                                        buttons persistent>
                                        <q-select dense filled label="Número de Cuenta" v-model="scope.value"
                                            option-label="cuenta" @filter="filtroCatalogoCuenta"
                                            :options="filtroCatalogoCuentas" use-input autofocus
                                            @keyup.enter="scope.set" input-debounce="0" />
                                    </q-popup-edit>
                                </q-td>
                                <q-td key="descripcion" :props="props">
                                    {{ props.row.descripcion }}
                                    <q-popup-edit v-model="props.row.descripcion" title="Editar descripcíon del asiento"
                                        auto-save v-slot="scope">
                                        <q-input v-model="scope.value" dense autofocus counter
                                            @keyup.enter="scope.set" />
                                    </q-popup-edit>
                                </q-td>
                                <q-td key="cargo" :props="props">
                                    {{ formatCurrency(props.row.cargo) }}
                                </q-td>
                                <q-td key="abono" :props="props">
                                    {{ formatCurrency(props.row.abono) }}
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
            </div>

        </q-card-section>
        <q-card-actions>
            <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
                <q-toolbar>
                    <q-space></q-space>
                    <div class="text-h6">Total Cargo: {{ formatCurrency(totalCargo) }}</div>
                    <q-space></q-space>
                    <div class="text-h6">Total Abono: {{ formatCurrency(totalAbono) }}</div>
                    <q-space></q-space>
                </q-toolbar>
            </q-footer>
        </q-card-actions>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {
    data() {
        return {
            columns: [
                { name: 'name1', align: 'center', label: 'No. Cuenta', field: 'name', sortable: true },
                { name: 'name2', align: 'center', label: 'Nombre Cuenta', field: 'name', sortable: true },
                { name: 'name4', align: 'center', label: 'Cargo', field: 'name', sortable: true, },
                { name: 'name5', align: 'center', label: 'Abono', field: 'name', sortable: true, },
            ],
            selectAño: null,
            filtroCatalogoCuentas: null,
            selectCuenta: null,
            banderaGenera: false,

            poliza: {
                _id: "",
                tipo: "Diario",
                fecha: new Date(),
                mes: 'diciembre',
                año: '',
                descripcion: '',
                numPoliza: -1,
                asientos: [],
                listaXml: [],
                estatus: 'Activo',
                cargo: 0,
                abono: 0,
                diferencia: 0,
            },

            columnsAientos: [
                // { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'cuenta', align: 'center', label: 'Cuenta', field: 'cuenta', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'descripcion', align: 'center', label: 'Asiento', field: 'descripcion', sortable: true, classes: 'text-left', },
                { name: 'cargo', align: 'center', label: 'Cargo', field: 'cargo', sortable: true, classes: 'text-right', },
                { name: 'abono', align: 'center', label: 'Abono', field: 'abono', sortable: true, classes: 'text-right', },
            ],

            initialPagination: {
                sortBy: 'tipo',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsAñosEjercicio() {
            let arrayAños = [];
            let currentYear = new Date().getFullYear();
            for (let a = 0; a < 6; a++) {
                arrayAños.push(currentYear);
                currentYear--;
            }
            return arrayAños;
        },

        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore
        },

        nombreCuentaBadge() {
            let cuenta = ''

            try {
                let partes = this.asiento.cuenta.numCuenta.split('.')
                let cuentaPadre = partes[0]

                let indice = 0
                indice = this.catalogoPadre.findIndex(x => x.numCuenta === cuentaPadre);
                if (indice >= 0) {
                    cuenta = this.catalogoPadre[indice].cuenta
                }
            } catch (error) {
                return cuenta
            }
        },

        totalCargo() {
            return this.sum(this.poliza.asientos, 'cargo')
        },

        totalAbono() {
            return this.sum(this.poliza.asientos, 'abono')
        },

    },
    created() {
        this.getCatalogoCuentas();
    },

    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        async getCatalogoCuentas() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                let catalogo = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente')
                this.$store.state.listaCatalogoCuentasStore = catalogo;
                this.catalogoPadre = response.data.filter(f => f.nivel === 1);
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async GeneraPolizaCierre() {
            if (!this.selectAño) {
                this.$q.notify({ type: 'warning', message: `Selecccione el año para continuar` })
                return;
            }

            if (!this.selectCuenta) {
                this.$q.notify({ type: 'warning', message: `Selecccione la cuenta de resultado` })
                return;
            }

            this.poliza.fecha = this.selectAño + "-12-31";
            this.poliza.año = this.selectAño;
            this.poliza.descripcion = " Póliza de cierre ejercicio " + this.selectAño;

            const catalogo = [...this.$store.state.listaCatalogoCuentasStore]
            this.poliza.asientos = [];
            this.poliza.listaXml = [];
            let balanza = await this.GetBalanza();
            let item = 1;
            for (let x of balanza) {
                let asiento = {
                    item: item,
                    cuenta: null,
                    descripcion: "",
                    cargo: 0,
                    abono: 0,
                }
                asiento.cuenta = catalogo.find(f => f.numCuentaUnico === x.numCuentaUnico)
                if (x.haberFinal != 0) {
                    asiento.cargo = x.haberFinal;
                    asiento.abono = 0;
                } else if (x.debeFinal != 0) {
                    asiento.cargo = 0
                    asiento.abono = x.debeFinal;
                } else {
                    asiento.cargo = 0;
                    asiento.abono = 0;
                }
                this.poliza.asientos.push(asiento);
                asiento = {};
                item++;
            }

            let sumCargo = this.sum(this.poliza.asientos, 'cargo')
            let sumAbono = this.sum(this.poliza.asientos, 'abono')
            console.log(sumCargo, sumAbono);

            //AGREGAMOS A LA CUENTA DE RESULTADO
            let asientoCierre = {
                item: item,
                cuenta: this.selectCuenta,
                descripcion: "",
                cargo: 0,
                abono: 0,
            }
            if (sumCargo > sumAbono) {
                asientoCierre.cargo = 0;
                asientoCierre.abono = sumCargo - sumAbono;
            } else {
                asientoCierre.cargo = sumAbono - sumCargo;
                asientoCierre.abono = 0;
            }
            this.poliza.asientos.push(asientoCierre);
            this.banderaGenera = true;
            this.$q.loading.hide()
        },

        async PostPolizaCierre() {
            console.log(this.poliza)

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando Poliza. Espere...', messageColor: 'white' });
            try {
                let response = await axios.post('Polizas/PostPoliza/erp_' + this.token.rfc, this.poliza)
                this.poliza._id = response.data
                this.$store.state.listaPolizasStore.push(this.poliza)
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async GetBalanza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando balanza...', messageColor: 'white' })
            try {
                let fechaI = this.selectAño + "-" + "01-01"
                let fechaF = this.selectAño + "-" + "12-31";
                let response = await axios.get('Reportes/GetBalanzaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF)
                let x = response.data;
                let balanza = x.filter(f => f.numCuentaSat >= "0000000400" && f.tipoCuenta === "R")
                console.log(balanza);
                return balanza;
                // this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                // this.$q.loading.hide()
            }
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        cierraVentana() {
            // this.splitterModel = 0
            this.$emit('cierraVentanaCierre')
        },
    }
}
</script>