<template>
    <q-card style="max-width: 800px;">
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Balanza en XML
                </q-toolbar-title>
                <q-btn dense round color="green" icon="mdi-file-xml-box" class="q-mr-sm" @click="CreateXml()">
                    <q-tooltip>
                        Generar XML
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section>
            <div class="row q-col-gutter-sm">
                <!-- VERSION -->
                <div class="col-12 ">
                    <q-input v-model="version" dense filled label="Versión" readonly />
                </div>
                <!-- RFC -->
                <div class="col-12 ">
                    <q-input v-model="token.rfc" dense filled label="RFC" readonly />
                </div>
                <!-- MES -->
                <div class="col-12 ">
                    <q-select v-model="selectMes" dense filled :options="optionsMes" label="Mes"
                        option-label="descripcion" />
                </div>
                <!-- AÑO -->
                <div class="col-12 ">
                    <q-select v-model="selectAño" dense filled :options="optionsAño" label="Año" />
                </div>
                <!-- TIPO DE ENVÍO -->
                <div class="col-12 ">
                    <q-select v-model="selectTipoEnvio" dense filled :options="optionsTipoEnvio" label="Tipo de envío"
                        option-label="descripcion" />
                </div>
                <!-- FECHA ULTIMA MODIFICACION  -->
                <template v-if="selectTipoEnvio">
                    <div class="col-12" v-if="selectTipoEnvio.clave === 'C'">
                        <q-input v-model="fechaModBalMasked" filled label="Fecha modificacion última balanza"
                            class="q-mr-sm" name="event" outlined dense>
                            <template v-slot:before>
                                <q-icon name="event" color="primary" />
                            </template>
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="fechaModBal">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-input>
                    </div>
                </template>
            </div>
        </q-card-section>
        <q-card-actions>
        </q-card-actions>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import moment from 'moment';
import { format } from 'date-fns';

export default {
    data() {
        return {
            version: "1.3",
            selectMes: null,
            selectAño: null,
            optionsMes: [
                { clave: "01", descripcion: "ENERO" },
                { clave: "02", descripcion: "FEBRERO" },
                { clave: "03", descripcion: "MARZO" },
                { clave: "04", descripcion: "ABRIL" },
                { clave: "05", descripcion: "MAYO" },
                { clave: "06", descripcion: "JUNIO" },
                { clave: "07", descripcion: "JULIO" },
                { clave: "08", descripcion: "AGOSTO" },
                { clave: "09", descripcion: "SEPTIEMBRE" },
                { clave: "10", descripcion: "OCTUBRE" },
                { clave: "11", descripcion: "NOVIEMBRE" },
                { clave: "12", descripcion: "DICIEMBRE" }
            ],
            selectTipoEnvio: null,
            optionsTipoEnvio: [
                { clave: "N", descripcion: "N | NORMAL" },
                { clave: "C", descripcion: "C | COMPLEMENTARIA" },
            ],
            fechaModBal: new Date(),
            itemsBalanza: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        optionsAño() {
            let response = [];
            const fechaActual = new Date();
            const añoActual = fechaActual.getFullYear();
            for (let x = 0; x < 6; x++) {
                let menos = añoActual - x;
                response.push(menos)
            }
            return response;
        },

        fechaModBalMasked() {
            moment.locale('es-mx');
            return this.fechaModBal ? moment(this.fechaModBal).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {

    },
    methods: {
        async CreateXml() {
            if (!this.selectMes) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el mes',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!this.selectAño) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el año',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!this.selectTipoEnvio) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el tipo de envío',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            //CONSULTAMOS LA BALANZA
            await this.GetBalanza();
            const fechaModBal_ = new Date(this.fechaModBal);
            const mes = this.selectMes.clave;
            const año = this.selectAño;
            const rfc = this.token.rfc;
            const tipoEnvio = this.selectTipoEnvio.clave;
            const fechaModBal = format(fechaModBal_, 'yyyy-MM-dd');
            var cabecera = "";

            var cabecera = '<BCE:Balanza xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion/BalanzaComprobacion_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:BCE="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion" Anio="' + año + '" Mes="' + mes + '" RFC="' + rfc + '" Version="1.3" TipoEnvio="' + tipoEnvio + '">'

            var cabeceraC = '<BCE:Balanza xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion/BalanzaComprobacion_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:BCE="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion" Anio="' + año + '" Mes="' + mes + '" RFC="' + rfc + '" Version="1.3" TipoEnvio="' + tipoEnvio + '" FechaModBal="' + fechaModBal + '">'

            var pie = '</BCE:Balanza>'
            var detalles = ''

            for (var c of this.itemsBalanza) {
                const inicial = c.debeInicial + c.haberInicial;
                const final = c.debeFinal + c.haberFinal;
                let asiento = '<BCE:Ctas NumCta="' + c.noCuenta + '" SaldoIni="' + inicial + '" Debe="' + c.cargo + '" Haber="' + c.abono + '" SaldoFin="' + final + '"/>'
                detalles = detalles + asiento + '\n'
            }
            if (tipoEnvio === "C") {
                cabecera = cabeceraC;
            }
            let nombreArchivo = rfc + año + mes + "B" + tipoEnvio;
            this.downloadFile(cabecera + '\n' + detalles + '\n' + pie, nombreArchivo)
        },

        downloadFile(archivo, nombre) {
            let filename = nombre + '.xml';
            let text = archivo;

            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async GetBalanza(tipo) {
            const mes = this.selectMes.clave;
            const año = this.selectAño;
            this.itemsBalanza = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Generando balanza...', messageColor: 'white' })
            try {
                const fechaI = año + "-" + mes + "-01";
                const ultimoDia = await this.UltimoDiaDelMes(año, mes);
                const fechaF = año + "-" + mes + "-" + ultimoDia;
                let response = await axios.get('Reportes/GetBalanzaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF)
                let x = response.data;
                this.itemsBalanza = [...x]

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async UltimoDiaDelMes(año, mes) {
            let fecha = new Date(año, mes, 0);
            return fecha.getDate();
        },

    }
}
</script>