<template>
    <q-card style="max-width: 800px;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Cuenta
                </q-toolbar-title>
                <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" @click="postCuenta()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section class="scroll">
            <div class="row q-col-gutter-sm">
                <!-- <span>{{ this.numCuentaNuevo }}</span> -->
                <div class="col-12 ">
                    <q-select v-model="numCuentaNuevo" emit-value map-options option-label="cuenta"
                        @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas" use-input input-debounce="0"
                        dense filled label="Número de cuenta padre" virtual-scroll-slice-size="1" />
                </div>
                <!-- <div class="col-12 ">
                    <q-input v-model="cuenta.numCuenta" dense filled label="Número de cuenta" @change="agregarCeros()" />
                </div> -->
                <div class="col-12 ">
                    <q-input v-model="cuenta.descripcion" dense filled label="Descripción" />
                </div>
                <div class="col-12">
                    <q-select v-model="cuenta.numCuentaSat" emit-value map-options option-label="cuenta"
                        @filter="filtroCatalogoCuentaSat" :options="filtroCatalogoCuentasSat" use-input
                        input-debounce="0" dense filled label="Número de cuenta SAT" virtual-scroll-slice-size="1" />
                </div>
                <div class="col-12">
                    <q-select v-model="cuenta.naturaleza" dense filled :options="['D', 'A']" label="Naturaleza" />
                </div>
            </div>
        </q-card-section>
        <q-card-actions>
        </q-card-actions>
    </q-card>
</template>
<script>

import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {
    data() {
        return {
            numCuentaNuevo: { cuenta: '' },
            filtroCatalogoCuentas: null,
            filtroCatalogoCuentasSat: null,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        cuenta() {
            return this.$store.state.cuentaStore;
        },
        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore.filter(x => x.editable == 'SI')
        },
        itemsCatalogoCuentasSat() {
            return this.$store.state.listaCatalogoCuentasSatStore
        },
        itemsCatalogoCuentasSatTipoR() {
            return this.$store.state.listaCatalogoCuentasSatStore.filter(x => x.tipoCuenta == 'R')
        }
    },
    created() {
    },
    methods: {
        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroCatalogoCuentaSat(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentasSat = this.itemsCatalogoCuentasSatTipoR.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentasSat.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarCeros(valor) {
            const partes = valor.split('.');
            const partesConCeros = partes.map(parte => parte.padStart(10, '0'));
            const valorFormateado = partesConCeros.join('.');
            // this.cuenta.numCuentaUnico = valorFormateado;
            return valorFormateado;
        },

        crearCuentaHijo(cuenta) {

            const cuentaPadre = cuenta
            const lista = this.$store.state.listaCatalogoCuentasStore.map(objeto => objeto.numCuenta);
            // Filtrar elementos de la cuenta "10.01.01"
            let elementosCuentaPadre = lista.filter(item => item.startsWith(cuentaPadre + '.'));

            // Encontrar el último elemento en la cuenta
            const index = elementosCuentaPadre.length - 1
            const ultimoElemento = elementosCuentaPadre[index];
            // Generar un nuevo elemento
            let nuevoElemento;
            if (ultimoElemento) {
                const ultimoNumero = parseInt(ultimoElemento.split('.').pop(), 10);
                nuevoElemento = cuentaPadre + '.' + (ultimoNumero + 1).toString().padStart(2, '0');
            } else {
                nuevoElemento = cuentaPadre + '.01';
            }
            return nuevoElemento;
        },

        async postCuenta() {
            console.log(this.token)
            if (this.numCuentaNuevo.cuenta == '') {
                this.$q.notify({ type: 'negative', message: 'Seleccione una Cuenta Padre.', color: 'red' })
                return;
            }

            if (this.cuenta.descripcion == '') {
                this.$q.notify({ type: 'negative', message: 'Escriba una descripción', color: 'red' })
                return;
            }

            if (this.cuenta.numCuentaSat == '') {
                this.$q.notify({ type: 'negative', message: 'Ingrese la cuenta del SAT.', color: 'red' })
                return;
            }
            1
            if (this.cuenta.naturaleza == '') {
                this.$q.notify({ type: 'negative', message: 'Ingrese la naturaleza de la cuenta.', color: 'red' })
                return;
            }
            //console.log(this.numCuentaNuevo)

            let numeroCuenta = this.numCuentaNuevo.numCuenta

            if (numeroCuenta == null) {
                numeroCuenta = this.numCuentaNuevo
                this.cuenta.numCuenta = this.numCuentaNuevo
            } else {
                // console.log(numeroCuenta)
                this.cuenta.numCuenta = await this.crearCuentaHijo(numeroCuenta);
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando cuenta. Espere...', messageColor: 'white' })

            this.cuenta.numCuentaUnico = await this.agregarCeros(this.cuenta.numCuenta);

            const contadorPuntos = this.cuenta.numCuenta.split('.').length;
            this.cuenta.nivel = contadorPuntos
            this.cuenta.cuenta = this.cuenta.numCuenta + ' | ' + this.cuenta.descripcion
            this.cuenta.usuarioCreador = this.token.nombre;

            //console.log(this.cuenta)

            try {
                let response = await axios.post('CatalogoCuentas/PostCuenta/erp_' + this.token.rfc, this.cuenta)

                this.cuenta._id = response.data
                this.$store.state.listaCatalogoCuentasStore.push(this.cuenta)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La cuenta ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    }
}
</script>