<template>
    <q-card>
        <!-- DIALOG PARA VER LOS PDF -->
        <q-dialog v-model="dialogPDF" transition-show="scale" transition-hide="scale" maximized>
            <visor-pdf ref="miComponenteHijo" @CloseDialogPdf="CloseDialogPdf"></visor-pdf>
        </q-dialog>

        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Pólizas
                </q-toolbar-title>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section>
            <!-- TABA CON LAS POLIZAS -->
            <q-table :filter="filter" class="my-sticky-column-table my-sticky-column-table-sec shadow-0 header-tabla"
                :data="itemPolizas" :columns="columns" row-key="_id" :pagination="initialPagination">

                <template v-slot:top>
                    <q-input filled dense debounce="300" class="full-width" v-model="filter" placeholder="Filtrar...">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red-6" rounded flat dense icon="mdi-file-pdf-box"
                                @click="verPoliza(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">PDF</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                        <q-td key="numPoliza" :props="props">{{ props.row.numPoliza }}</q-td>
                        <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                        <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </q-card-section>
    </q-card>

</template>
<script>
import { parse } from 'date-fns';
import { format } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import visorPdf from '../PDF/VisorPDF.vue';
import axios from 'axios';

export default {
    components: {
        visorPdf
    },
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'numPoliza', align: 'center', label: 'No. Póliza', field: 'numPoliza', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'folioFiscal', align: 'left', label: 'Folio fiscal', field: 'folioFiscal', sortable: true },
            ],
            filter: "",
            initialPagination: {
                sortBy: 'fecha',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            dialogPDF: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemPolizas() {
            return this.$store.state.listComprobantesPolizasStore.lista;
        },

    },
    watch: {

    },

    created() {

    },

    methods: {
        FormatDate(value) {
            if (typeof value == "string") {
                let fecha_a = value.replace('T', ' ');
                let fecha_b = fecha_a.replace('Z', '');
                const cadenaFechaConHora = fecha_b;
                const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
                const formato = "dd-MMMM-yyyy";
                const configuracionLocal = { locale: es };
                let resultado = format(fecha, formato, configuracionLocal);
                return resultado;
            }
            return value;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        CloseDialogPdf() {
            this.dialogPDF = false;
        },

        async verPoliza(item) {
            console.log(item._id);
            //CONSULTAMOS PRIMERO LA POLIZA POR ID
            try {
                let response = await axios.get("Polizas/GetPolizas/erp_" + this.token.rfc + '/' + item._id);

                const poliza = response.data;
                this.dialogPDF = true;
                this.$nextTick(() => {
                    // console.log(item)
                    this.$store.state.vistaPreviaStore.color = "19775C"
                    this.$store.state.vistaPreviaStore.tipo = "POLIZA"
                    this.$refs.miComponenteHijo.VerPolizaPdf(poliza);
                });

            } catch (error) {
                console.log(error);
            }
        },

    }
}
</script>

<style lang="sass">
    .my-sticky-column-table
    
      thead tr:first-child th:first-child
        /* bg color is important for th; just specify one */
        background-color: #fff
    
      td:first-child
        background-color: #fff
    
      th:first-child,
      td:first-child
        position: sticky
        left: 0
        z-index: 1
    </style>dex: 1
</style>