<template>
    <div class="bg-primary window-height window-width row justify-center items-center">
        <div class="column">
            <div class="row">
                <!-- FORMULARIO PARA INICIAR SESION -->
                <q-card square bordered class="q-pa-lg shadow-1">
                    <q-card-section>
                        <q-form class="q-gutter-md text-center">
                            <img alt="Contago logo" src="../assets/autoconta.jpg" style="height: 100px">
                        </q-form>
                    </q-card-section>

                    <template v-if="itemsEmpresa.length == 0">
                        <q-card-section>
                            <q-form class="q-gutter-md">
                                <!-- <img alt="Contago logo" src="../assets/logo_contago_sin_fondo.png" style="height: 100px"> -->
                                <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                                <q-input square filled v-model="password" type="password" label="Contraseña"
                                    @keyup.enter="validarUsuario()" />
                            </q-form>
                        </q-card-section>
                        <q-card-actions class="q-px-md" v-if="itemsEmpresa.length == 0">
                            <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                                label="Validar Empresa" />
                            <!-- <q-btn flat color="primary" class="full-width q-mt-md" label="Crear Cuenta"
                                @click="formCrearCuenta = true" /> -->
                        </q-card-actions>
                    </template>

                    <template v-if="itemsEmpresa.length != 0">
                        <q-card-section>
                            <q-form class="q-gutter-md">
                                <q-select :options="filteredOptions" filled v-model="selectEmpresa" option-label="nombre_e"
                                    label="Empresa" use-input hide-selected fill-input @filter="filterFn"
                                    input-debounce="0">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No results
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                            </q-form>
                        </q-card-section>
                        <q-card-actions class="q-px-md">
                            <q-btn unelevated color="primary" size="lg" class="full-width" label="INICIAR SESIÓN"
                                @click="ingresar()" />
                            <q-btn flat color="primary" class="full-width q-mt-md" label="CANCELAR" @click="cancelar()" />
                        </q-card-actions>
                    </template>

                </q-card>

            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { QSpinnerGears } from 'quasar'
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            model: null,
            options: null,

            nombreU: "",
            selectEmpresa: {
                nombre_e: ''
            },
            itemsEmpresa: [],

            idUsuario: null,
            nombreUsuario: null,
            rolUsuario: null,
            empresa: null,
            empresaBase: null,
            rfc: "",
            firma: null,

            filteredOptions: [],

            formCrearCuenta: false,
            GuardandoSellos: false,
            formGuardarSellos: false,
            isPwd: false,

        }
    },
    computed: {
    },
    methods: {

        filterFn(val, update, abort) {
            update(() => {
                const needle = val.toLowerCase()
                this.filteredOptions = this.itemsEmpresa.filter(v => v.nombre_e.toLowerCase().indexOf(needle) > -1)
            })
        },

        async validarUsuario() {
            this.$q.loading.show({ message: '<b>Buscando Empresas...</b><br/><span class="text-orange text-weight-bold">Espere...</span>' })

            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            try {
                let respuesta = await axios.post("https://api-framework.contago.com.mx/api/Usuarios/ValidaAsync", { nombre: this.nombreU, password: this.password, }
                );
                this.idUsuario = respuesta.data.idUsuario_;
                this.rolUsuario = respuesta.data.rol_;
                this.firma = respuesta.data.firma_;
                this.rfc = respuesta.data.rfc_;
                this.correo = respuesta.data.correo_;
                this.listaEmpresas(respuesta.data.idUsuario_);
                this.nombreUsuario = respuesta.data.nombre_;
                console.log(respuesta.data);
                this.$q.loading.hide()
            } catch (err) {
                console.log(err.response.status);
                if (err.response.status == 400) {
                    this.$q.notify({ type: 'negative', message: "Usuario inexistente" })
                } else if (err.response.status == 404) {
                    this.$q.notify({ type: 'negative', message: "Contraseña incorrecta" })
                } else if (err.response.status == 401) {
                    this.$q.notify({ type: 'negative', message: "El usuario ya cuenta con una sesión iniciada" })
                } else {
                    this.$q.notify({ type: 'negative', message: "Datos incorrectos, verifique su usuario y contraseña" })
                }
                this.$q.loading.hide()

            }
        },
        ingresar() {
            if (this.selectEmpresa.nombre_e == '') {
                this.$q.notify({ type: 'negative', message: "Seleccione uan empresa" })
                return
            }

            this.$q.loading.show({ message: '<b>Iniciando Sesión...</b>' })

            this.empresa = this.selectEmpresa.nombre_e;
            this.empresaBase = this.selectEmpresa.nombreBase;
            this.rfc = this.selectEmpresa.rfc;

            axios
                .post("https://api-framework.contago.com.mx/api/Usuarios/Login", {
                    idUsuario: this.idUsuario,
                    nombre: this.nombreUsuario,
                    rol: this.rolUsuario,
                    empresa: this.empresa,
                    empresaBase: this.empresaBase,
                    firma: this.firma,
                    rfc: this.rfc,
                })
                .then((response) => {
                    console.log(response.data);
                    this.$q.loading.hide()

                    return response.data;
                })
                .then((data) => {
                    this.$q.loading.hide()

                    this.$store.dispatch("guardarToken", data.token);
                    this.$router.push({ name: "Home" });
                    this.nombre = "";
                    this.password = "";
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status == 500) {
                        this.$q.notify({ type: 'negative', message: error.response.data })

                    }
                    this.$q.loading.hide()

                });
        },

        listaEmpresas(item) {
            axios
                .get("https://api-framework.contago.com.mx/api/Usuarios/Empresas/" + item + "/" + "DESERIALIZADOR")
                .then((response) => {
                    this.itemsEmpresa = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        cancelar() {
            this.nombreU = ''
            this.password = ''
            this.itemsEmpresa = []
        },



    }
}
</script>
  
<style>
.q-card {
    width: 500px;
}
</style>
