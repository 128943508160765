<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">REPORTES ESPECIALES</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer show-if-above behavior="mobile" v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="320" :breakpoint="500" bordered content-class="bg-purple-1">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>

                    <!-- <q-item active clickable v-ripple @click="irEstadoCostoProduccionVenta">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-factory" />
                        </q-item-section>
                        <q-item-section>
                            Estado de costo producción y venta
                        </q-item-section>
                    </q-item> -->

                    <q-separator />

                    <q-item active clickable v-ripple @click="irDepreciacion">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-trending-down" />
                        </q-item-section>
                        <q-item-section>
                            Depreciación
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irCalculoAjusteAnualInflacion">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-calculator-variant-outline" />
                        </q-item-section>
                        <q-item-section>
                            Cálculo ajuste anual por Inflación
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irProvisionalesIva">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-database-arrow-up-outline" />
                        </q-item-section>
                        <q-item-section>
                            Provisonales de IVA
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irProvisionalesIsr">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-cash-plus" />
                        </q-item-section>
                        <q-item-section>
                            Provisionales de ISR
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <!-- <q-item active clickable v-ripple @click="irConciliacionContableFiscal">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-text-box-search-outline" />
                        </q-item-section>
                        <q-item-section>
                            Concialiación contable fiscal
                        </q-item-section>
                    </q-item>

                    <q-separator /> -->

                    <q-item active clickable v-ripple @click="irDiot">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-brightness-percent" />
                        </q-item-section>
                        <q-item-section>
                            DIOT
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irFlujoEfectivo">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-cash-sync" />
                        </q-item-section>
                        <q-item-section>
                            Flujo de efectivo
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item active clickable v-ripple @click="irDevolucionesIva">
                        <q-item-section avatar>
                            <q-icon size="sm" name="mdi-file-percent" />
                        </q-item-section>
                        <q-item-section>
                            Devoluciones de IVA
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"


export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {

        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {
        irCalculoAjusteAnualInflacion() {
            this.$router.push({ name: 'CalculoAjusteAnualInflacion' })
        },
        irConciliacionContableFiscal() {
            this.$router.push({ name: 'ConciliacionContableFiscal' })
        },
        irDepreciacion() {
            this.$router.push({ name: 'Depreciacion' })
        },
        irDevolucionesIva() {
            this.$router.push({ name: 'DevolucionesIva' })
        },
        irDiot() {
            this.$store.state.listDiotStore = []
            this.$router.push({ name: 'Diot' })
        },
        irEstadoCostoProduccionVenta() {
            this.$router.push({ name: 'EstadoCostoProduccionVenta' })
        },
        irFlujoEfectivo() {
            this.$router.push({ name: 'FlujoEfectivo' })
        },
        irProvisionalesIsr() {
            this.$router.push({ name: 'ProvisionalesIsr' })
        },
        irProvisionalesIva() {
            this.$router.push({ name: 'ProvisionalesIva' })
        },
    }
}
</script>
