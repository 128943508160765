<template>
    <q-page class=" q-pa-lg ">
        <div class="row q-col-gutter-sm q-mb-lg">
            <div class="col-12 col-md-8">
                <div class=" text-h5">Estado de Costo de Producción y Venta</div>
            </div>

            <!-- FECHA INICIAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>

            <!-- FECHA FINAL -->
            <div class="col-12 col-md-2">
                <q-input v-model="fechaFMasked" filled label="Fecha Final" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    <template v-slot:after>
                        <q-btn flat round color="primary" icon="mdi-magnify">
                            <q-tooltip>
                                Buscar
                            </q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>
        <q-table class="shadow-0 header-tabla" :pagination="initialPagination" :loading="loadingTabla" :columns="columns"
            row-key="_id">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'

export default {
    components: {},
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),

            loadingTabla: false,
            initialPagination: {
                sortBy: 'desc',
                descending: false,
                page: 2,
                rowsPerPage: 10
            },

            columns: [
                { name: 'name1', align: 'center', label: 'No. Cuenta', field: 'name', sortable: true },
                { name: 'name2', align: 'center', label: 'Nombre Cuenta', field: 'name', sortable: true },
                { name: 'name5', align: 'center', label: 'Importe', field: 'name', sortable: true, headerClasses: 'bg-primary text-white' },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

    },
    created() {
    },
    methods: {

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>

<style></style>