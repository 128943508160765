
import jsPDF from 'jspdf';
import moment from 'moment'

import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}
function functionFormatPrice(value) {
    let val = (value / 1).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
function formtaoFecha(value) {
    let fecha_a = value.replace('T', ' ');
    let fecha_b = fecha_a.replace('Z', '');
    const cadenaFechaConHora = fecha_b;
    const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formato = "dd-MMMM-yyyy";
    const configuracionLocal = { locale: es };
    let resultado = format(fecha, formato, configuracionLocal);
    return resultado;
}

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const AjusteAnualPDF = async (itemsReporteDeudas, itemsReporteCreditos, objEmpresa, sumaCreditos, sumaDeudas, mesesEjercicio, inpcAntiguo, inpcReciente, factAjusteAnualIA, factAjusteAnual, diferencia, promedioAnualCreditos, promedioAnualDeudas, Name) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                orientation: 'landscape'
            });
            var pageSize = {
                width: 595.28,
                height: 841.89
            };

            // Define los márgenes de la página (en este caso, 10mm)
            var pageMargins = {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            };

            //PRIMERA PARTE
            let xMargen = 10;
            let yPrimeraParte = 30
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('Acumulables', xMargen, yPrimeraParte, { align: 'left' });

            let color = [102, 46, 145]
            let width = 277;
            let height = 2;
            let cornerRadius = 1;

            doc.setFillColor.apply(null, color);
            doc.setDrawColor.apply(null, color);
            doc.roundedRect(xMargen, yPrimeraParte += 2, width, height, cornerRadius, cornerRadius, 'FD');

            yPrimeraParte += 8
            doc.setFontSize(12);
            doc.text('TOTALES', xMargen, yPrimeraParte, { align: 'left' });
            doc.text('SALDOS', xMargen + 65, yPrimeraParte, { align: 'left' });
            doc.text('FACTOR DE AJUSTE ANUAL', xMargen + 155, yPrimeraParte, { align: 'left' });
            doc.text("INPC's", xMargen + 230, yPrimeraParte, { align: 'left' });

            // TOTALES
            doc.setFontSize(10);
            yPrimeraParte += 5
            doc.setFont('helvetica', 'normal');
            doc.text('• Total Créditos:', xMargen, yPrimeraParte, { align: 'left' });
            doc.text('• Total Deudas:', xMargen, yPrimeraParte + 5, { align: 'left' });
            doc.text('• Meses del Ejercicio:', xMargen, yPrimeraParte + 10, { align: 'left' });

            doc.text(functionFormatPrice(sumaCreditos), xMargen + 58, yPrimeraParte, { align: 'right' });
            doc.text(functionFormatPrice(sumaDeudas), xMargen + 58, yPrimeraParte + 5, { align: 'right' });
            doc.text(mesesEjercicio, xMargen + 58, yPrimeraParte + 10, { align: 'right' });

            // SALDOS
            doc.text('• Saldo promedio anual de deudas:', xMargen + 65, yPrimeraParte, { align: 'left' });
            doc.text('• Saldo promedio anual de créditos:', xMargen + 65, yPrimeraParte + 5, { align: 'left' });
            doc.text('• Diferencia:', xMargen + 65, yPrimeraParte + 10, { align: 'left' });

            doc.text(functionFormatPrice(promedioAnualDeudas), xMargen + 146, yPrimeraParte, { align: 'right' });
            doc.text(functionFormatPrice(promedioAnualCreditos), xMargen + 146, yPrimeraParte + 5, { align: 'right' });
            doc.text(functionFormatPrice(diferencia), xMargen + 146, yPrimeraParte + 10, { align: 'right' });

            // FACTOR DE AJUSTE ANUAL
            doc.text('• Factor de Ajuste Anual:', xMargen + 155, yPrimeraParte, { align: 'left' });
            let resp = diferencia * factAjusteAnual
            if (resp < 0) {
                doc.text('• Ajuste Anual por \n Inflación Deducible:', xMargen + 155, yPrimeraParte + 5, { align: 'left' });
            } else {
                doc.text('• Ajuste Anual por \n Inflación Acumulable:', xMargen + 155, yPrimeraParte + 5, { align: 'left' });
            }

            doc.text(factAjusteAnual.toFixed(4), xMargen + 217, yPrimeraParte, { align: 'right' });
            doc.text('\n' + factAjusteAnualIA, xMargen + 217, yPrimeraParte + 5, { align: 'right' });

            // INPC's
            doc.text('• INPC Reciente:', xMargen + 230, yPrimeraParte, { align: 'left' });
            doc.text('• INPC Antiguo:', xMargen + 230, yPrimeraParte + 5, { align: 'left' });

            doc.text(inpcReciente.toFixed(3), xMargen + 277, yPrimeraParte, { align: 'right' });
            doc.text(inpcAntiguo.toFixed(3), xMargen + 277, yPrimeraParte + 5, { align: 'right' });

            // CREDITOS
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('Créditos', xMargen, yPrimeraParte + 20, { align: 'left' });

            doc.setFillColor.apply(null, color);
            doc.setDrawColor.apply(null, color);
            doc.roundedRect(xMargen, yPrimeraParte += 22, width, height, cornerRadius, cornerRadius, 'FD');

            // AQUI COMIENZA LA TABLA
            var data = [
                // CABECERA
                ['Mes', 'Bancos', 'Inversiones', 'Otros Instrumentos Financieros', 'Clientes', 'Cuentas y Documentos por Cobrar', 'Partes Relacionadas Nacionales', 'Partes Relacionadas Extranjeros', 'Otros Deudores Diversos', 'Suma'],
                // DATOS
                ['Enero', functionFormatPrice(itemsReporteCreditos[0].bancos),
                    functionFormatPrice(itemsReporteCreditos[0].inversiones
                    ),
                    functionFormatPrice(itemsReporteCreditos[0].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[0].clientes),
                    functionFormatPrice(itemsReporteCreditos[0].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[0].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[0].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[0].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[0].suma)],
                ['Febrero', functionFormatPrice(itemsReporteCreditos[1].bancos),
                    functionFormatPrice(itemsReporteCreditos[1].inversiones),
                    functionFormatPrice(itemsReporteCreditos[1].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[1].clientes),
                    functionFormatPrice(itemsReporteCreditos[1].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[1].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[1].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[1].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[1].suma)],
                ['Marzo', functionFormatPrice(itemsReporteCreditos[2].bancos),
                    functionFormatPrice(itemsReporteCreditos[2].inversiones),
                    functionFormatPrice(itemsReporteCreditos[2].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[2].clientes),
                    functionFormatPrice(itemsReporteCreditos[2].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[2].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[2].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[2].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[2].suma)],
                ['Abril', functionFormatPrice(itemsReporteCreditos[3].bancos),
                    functionFormatPrice(itemsReporteCreditos[3].inversiones),
                    functionFormatPrice(itemsReporteCreditos[3].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[3].clientes),
                    functionFormatPrice(itemsReporteCreditos[3].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[3].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[3].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[3].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[3].suma)],
                ['Mayo', functionFormatPrice(itemsReporteCreditos[4].bancos),
                    functionFormatPrice(itemsReporteCreditos[4].inversiones),
                    functionFormatPrice(itemsReporteCreditos[4].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[4].clientes),
                    functionFormatPrice(itemsReporteCreditos[4].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[4].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[4].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[4].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[4].suma)],
                ['Junio', functionFormatPrice(itemsReporteCreditos[5].bancos),
                    functionFormatPrice(itemsReporteCreditos[5].inversiones),
                    functionFormatPrice(itemsReporteCreditos[5].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[5].clientes),
                    functionFormatPrice(itemsReporteCreditos[5].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[5].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[5].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[5].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[5].suma)],
                ['Julio', functionFormatPrice(itemsReporteCreditos[6].bancos),
                    functionFormatPrice(itemsReporteCreditos[6].inversiones),
                    functionFormatPrice(itemsReporteCreditos[6].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[6].clientes),
                    functionFormatPrice(itemsReporteCreditos[6].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[6].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[6].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[6].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[6].suma)],
                ['Agosto', functionFormatPrice(itemsReporteCreditos[7].bancos),
                    functionFormatPrice(itemsReporteCreditos[7].inversiones),
                    functionFormatPrice(itemsReporteCreditos[7].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[7].clientes),
                    functionFormatPrice(itemsReporteCreditos[7].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[7].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[7].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[7].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[7].suma)],
                ['Septiembre', functionFormatPrice(itemsReporteCreditos[8].bancos),
                    functionFormatPrice(itemsReporteCreditos[8].inversiones),
                    functionFormatPrice(itemsReporteCreditos[8].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[8].clientes),
                    functionFormatPrice(itemsReporteCreditos[8].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[8].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[8].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[8].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[8].suma)],
                ['Octubre', functionFormatPrice(itemsReporteCreditos[9].bancos),
                    functionFormatPrice(itemsReporteCreditos[9].inversiones),
                    functionFormatPrice(itemsReporteCreditos[9].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[9].clientes),
                    functionFormatPrice(itemsReporteCreditos[9].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[9].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[9].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[9].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[9].suma)],
                ['Noviembre', functionFormatPrice(itemsReporteCreditos[10].bancos),
                    functionFormatPrice(itemsReporteCreditos[10].inversiones),
                    functionFormatPrice(itemsReporteCreditos[10].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[10].clientes),
                    functionFormatPrice(itemsReporteCreditos[10].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[10].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[10].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[10].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[10].suma)],
                ['Diciembre', functionFormatPrice(itemsReporteCreditos[11].bancos),
                    functionFormatPrice(itemsReporteCreditos[11].inversiones),
                    functionFormatPrice(itemsReporteCreditos[11].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[11].clientes),
                    functionFormatPrice(itemsReporteCreditos[11].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[11].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[11].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[11].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[11].suma)],
                ['Suma', functionFormatPrice(itemsReporteCreditos[12].bancos),
                    functionFormatPrice(itemsReporteCreditos[12].inversiones),
                    functionFormatPrice(itemsReporteCreditos[12].otrosInstrumentosFinancieros),
                    functionFormatPrice(itemsReporteCreditos[12].clientes),
                    functionFormatPrice(itemsReporteCreditos[12].cuentasYDocumentosPorCobrar),
                    functionFormatPrice(itemsReporteCreditos[12].partesRelacionadasNacionales),
                    functionFormatPrice(itemsReporteCreditos[12].partesRelacionadasExtranjeros),
                    functionFormatPrice(itemsReporteCreditos[12].otrosDeudoresDiversos),
                    functionFormatPrice(itemsReporteCreditos[12].suma)]
            ];
            // Definir las opciones de la tabla
            var options = {
                startY: 72
            };

            // Crear la tabla utilizando el método autoTable
            doc.autoTable({
                head: [data[0]], // Encabezados de la tabla
                body: data.slice(1), // Cuerpo de la tabla
                startY: options.startY, // Posición de inicio de la tabla
                headStyles: {
                    fillColor: [102, 46, 145],
                    halign: 'center'// Color de fondo en RGB (rojo, verde, azul)
                },
                columnStyles: {
                    1: {
                        halign: 'right' // Alineación a la derecha
                    },
                    2: {
                        halign: 'right' // Alineación a la derecha
                    },
                    3: {
                        halign: 'right' // Alineación a la derecha
                    },
                    4: {
                        halign: 'right' // Alineación a la derecha
                    },
                    5: {
                        halign: 'right' // Alineación a la derecha
                    },
                    6: {
                        halign: 'right' // Alineación a la derecha
                    },
                    7: {
                        halign: 'right' // Alineación a la derecha
                    },
                    8: {
                        halign: 'right' // Alineación a la derecha
                    },
                    9: {
                        halign: 'right' // Alineación a la derecha
                    },
                    9: { fillColor: '#eeeeee', halign: 'right' }, // Colorear la última columna en gris
                },
            });

            // NUEVA PAGINA
            doc.addPage()
            // Cambiar el tamaño de la hoja a "oficio" (216x356 mm)
            // doc.setPageSize('oficio');

            // Establecer el tamaño de página en formato oficio
            var pageSize = {
                width: 612,  // Ancho en puntos
                height: 1008  // Alto en puntos
            };

            // Define los márgenes de la página (en este caso, 10mm)
            var pageMargins = {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            };

            //SEGUNDA PARTE
            let xMargen2 = 10;
            let ySegundaParte = 30

            // DEUDAS
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('Deudas', xMargen2, ySegundaParte, { align: 'left' });

            doc.setFillColor.apply(null, color);
            doc.setDrawColor.apply(null, color);
            doc.roundedRect(xMargen2, ySegundaParte += 2, width, height, cornerRadius, cornerRadius, 'FD');

            // AQUI COMIENZA LA TABLA
            var fontSize = 8; // Tamaño de fuente deseado
            doc.setFontSize(fontSize);

            // AQUI COMIENZA LA TABLA
            var data = [
                // CABECERAS
                ['Mes', '1*', '2*', '3*', '4*', '5*', '6*', '7*', '8*', '9*', '10*', '11*', '12*', '13*', '14*', '15*', '16*', '17*', '18*', 'Suma'],
                // DATOS
                ['Enero', functionFormatPrice(itemsReporteDeudas[0].proveedores),
                    functionFormatPrice(itemsReporteDeudas[0].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[0].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[0].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[0].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[0].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[0].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[0].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[0].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[0].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[0].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[0].suma)],
                ['Febrero', functionFormatPrice(itemsReporteDeudas[1].proveedores),
                    functionFormatPrice(itemsReporteDeudas[1].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[1].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[1].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[1].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[1].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[1].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[1].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[1].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[1].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[1].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[1].suma)],
                ['Marzo', functionFormatPrice(itemsReporteDeudas[2].proveedores),
                    functionFormatPrice(itemsReporteDeudas[2].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[2].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[2].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[2].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[2].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[2].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[2].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[2].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[2].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[2].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[2].suma)],
                ['Abril', functionFormatPrice(itemsReporteDeudas[3].proveedores),
                    functionFormatPrice(itemsReporteDeudas[3].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[3].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[3].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[3].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[3].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[3].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[3].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[3].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[3].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[3].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[3].suma)],
                ['Mayo', functionFormatPrice(itemsReporteDeudas[4].proveedores),
                    functionFormatPrice(itemsReporteDeudas[4].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[4].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[4].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[4].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[4].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[4].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[4].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[4].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[4].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[4].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[4].suma)],
                ['Junio', functionFormatPrice(itemsReporteDeudas[5].proveedores),
                    functionFormatPrice(itemsReporteDeudas[5].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[5].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[5].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[5].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[5].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[5].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[5].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[5].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[5].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[5].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[5].suma)],
                ['Julio', functionFormatPrice(itemsReporteDeudas[6].proveedores),
                    functionFormatPrice(itemsReporteDeudas[6].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[6].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[6].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[6].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[6].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[6].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[6].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[6].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[6].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[6].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[6].suma)],
                ['Agosto', functionFormatPrice(itemsReporteDeudas[7].proveedores),
                    functionFormatPrice(itemsReporteDeudas[7].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[7].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[7].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[7].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[7].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[7].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[7].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[7].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[7].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[7].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[7].suma)],
                ['Septiembre', functionFormatPrice(itemsReporteDeudas[8].proveedores),
                    functionFormatPrice(itemsReporteDeudas[8].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[8].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[8].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[8].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[8].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[8].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[8].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[8].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[8].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[8].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[8].suma)],
                ['Octubre', functionFormatPrice(itemsReporteDeudas[9].proveedores),
                    functionFormatPrice(itemsReporteDeudas[9].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[9].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[9].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[9].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[9].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[9].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[9].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[9].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[9].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[9].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[9].suma)],
                ['Noviembre', functionFormatPrice(itemsReporteDeudas[10].proveedores),
                    functionFormatPrice(itemsReporteDeudas[10].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[10].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[10].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[10].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[10].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[10].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[10].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[10].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[10].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[10].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[10].suma)],
                ['Diciembre', functionFormatPrice(itemsReporteDeudas[11].proveedores),
                    functionFormatPrice(itemsReporteDeudas[11].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[11].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[11].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[11].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[11].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[11].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[11].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[11].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[11].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[11].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[11].suma)],
                ['Suma', functionFormatPrice(itemsReporteDeudas[12].proveedores),
                    functionFormatPrice(itemsReporteDeudas[12].cuentasPorPagarACortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[12].instrumentosFinancierosCortoP),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDiversosCortoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDiversosCortoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDiversosCortoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDiversosCortoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[12].otrosAcreedoresDiversosCortoP),
                    functionFormatPrice(itemsReporteDeudas[12].otrosPasivosCortoPlazo),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDivLargoPlazoN),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDivLargoPlazoE),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDivLargoPlazoNPR),
                    functionFormatPrice(itemsReporteDeudas[12].acreedoresDivLargoPlazoEPR),
                    functionFormatPrice(itemsReporteDeudas[12].otrosAcreedoresDiversosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[12].cuentasPorPagarLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[12].instrumentosFinancierosLargoP),
                    functionFormatPrice(itemsReporteDeudas[12].otrosPasivosLargoPlazo),
                    functionFormatPrice(itemsReporteDeudas[12].aportacionesFuturoAumentoCapital),
                    functionFormatPrice(itemsReporteDeudas[12].suma)],
            ];

            // Definir las opciones de la tabla
            var options = {
                startY: 38
            };

            // Crear la tabla utilizando el método autoTable
            doc.autoTable({
                head: [data[0]], // Encabezados de la tabla
                body: data.slice(1), // Cuerpo de la tabla
                startY: options.startY, // Posición de inicio de la tabla
                headStyles: {
                    fillColor: [102, 46, 145],// Color de fondo en RGB (rojo, verde, azul)
                    halign: 'center'
                },
                columnStyles: {
                    // ALINEA LAS CANTIDADES A LA DERECHA
                    1: {
                        halign: 'right' // Alineación a la derecha
                    },
                    2: {
                        halign: 'right' // Alineación a la derecha
                    },
                    3: {
                        halign: 'right' // Alineación a la derecha
                    },
                    4: {
                        halign: 'right' // Alineación a la derecha
                    },
                    5: {
                        halign: 'right' // Alineación a la derecha
                    },
                    6: {
                        halign: 'right' // Alineación a la derecha
                    },
                    7: {
                        halign: 'right' // Alineación a la derecha
                    },
                    8: {
                        halign: 'right' // Alineación a la derecha
                    },
                    9: {
                        halign: 'right' // Alineación a la derecha
                    },
                    10: {
                        halign: 'right' // Alineación a la derecha
                    },
                    11: {
                        halign: 'right' // Alineación a la derecha
                    },
                    12: {
                        halign: 'right' // Alineación a la derecha
                    },
                    13: {
                        halign: 'right' // Alineación a la derecha
                    },
                    14: {
                        halign: 'right' // Alineación a la derecha
                    },
                    15: {
                        halign: 'right' // Alineación a la derecha
                    },
                    16: {
                        halign: 'right' // Alineación a la derecha
                    },
                    17: {
                        halign: 'right' // Alineación a la derecha
                    },
                    18: {
                        halign: 'right' // Alineación a la derecha
                    },
                    19: {
                        halign: 'right' // Alineación a la derecha
                    },
                    19: { fillColor: '#eeeeee', halign: 'right' }, // Colorear la última columna en gris
                },
                styles: {
                    fontSize: 9, // Tamaño de fuente en puntos
                }

            });
            doc.setFont('helvetica', 'normal');

            var footerX = 10;  // Posición X
            var footerY = doc.internal.pageSize.height - 20;  // Posición Y
            var pText = "*1 Proveedores";  // Texto del pie de página
            var cppcpText = "*2 Cuentas por Pagar a Corto Plazo";  // Texto del pie de página
            var ifcpText = "*3 Instrumentos Financieros Corto Plazo";  // Texto del pie de página
            var adcpnText = "*4 Acreedores Diversos Corto Plazo Nacionales";  // Texto del pie de página
            var adcpeText = "*5 Acreedores Diversos Corto Plazo Extranjeros";  // Texto del pie de página
            var adcpnprText = "*6 Acreedores Diversos Corto Plazo Nacional Parte Relacionada";  // Texto del pie de página
            var adcpeprText = "*7 Acreedores Diversos Corto Plazo Extranjero Parte Relacionada";  // Texto del pie de página
            var oadcpText = "*8 Otros Acreedores Diversos Corto Plazo";  // Texto del pie de página
            var opcpText = "*9 Otros Pasivos Corto Plazo";  // Texto del pie de página
            var adlpnText = "*10 Acreedores Diversos Largo Plazo Nacional";  // Texto del pie de página
            var adlpeText = "*11 Acreedores Diversos Largo Plazo Extranjero";  // Texto del pie de página
            var adlpnprText = "*12 Acreedores Diversos Largo Plazo Nacional Parte Relacionada";  // Texto del pie de página
            var adlpeprText = "*13 Acreedores Diversos Largo Plazo Extranjero Parte Relacionada";  // Texto del pie de página
            var oadlpText = "*14 Otros Acreedores Diversos Largo Plazo";  // Texto del pie de página
            var cpplpText = "*15 Cuentas por Pagar Largo Plazo";  // Texto del pie de página
            var iflpText = "*16 Instrumentos Financieros Largo Plazo";  // Texto del pie de página
            var oplpText = "*17 Otros Pasivos Largo Plazo";  // Texto del pie de página
            var apfacText = "*18 Aportaciones para Futuro Aumento \nde Capital";  // Texto del pie de página
            var footerFontSize = 10;  // Tamaño de fuente del pie de página
            var footerFontStyle = "italic";  // Estilo de fuente del pie de página
            var footerTextColor = "#888888";  // Color de texto del pie de página

            var spacing = 5; // Puedes ajustar este valor según tus necesidades
            var y18 = doc.internal.pageSize.height - 10 - (spacing * 7);
            var y1 = doc.internal.pageSize.height - 10 - (spacing * 6);
            var y2 = doc.internal.pageSize.height - 10 - (spacing * 5);
            var y3 = doc.internal.pageSize.height - 10 - (spacing * 4);
            var y4 = doc.internal.pageSize.height - 10 - (spacing * 3);
            var y5 = doc.internal.pageSize.height - 10 - (spacing * 2);
            var y6 = doc.internal.pageSize.height - 10 - (spacing * 1);
            var y7 = doc.internal.pageSize.height - 10 - (spacing * 6);
            var y8 = doc.internal.pageSize.height - 10 - (spacing * 5);
            var y9 = doc.internal.pageSize.height - 10 - (spacing * 4);
            var y10 = doc.internal.pageSize.height - 10 - (spacing * 3);
            var y11 = doc.internal.pageSize.height - 10 - (spacing * 2);
            var y12 = doc.internal.pageSize.height - 10 - (spacing * 1);
            var y13 = doc.internal.pageSize.height - 10 - (spacing * 6);
            var y14 = doc.internal.pageSize.height - 10 - (spacing * 5);
            var y15 = doc.internal.pageSize.height - 10 - (spacing * 4);
            var y16 = doc.internal.pageSize.height - 10 - (spacing * 3);
            var y17 = doc.internal.pageSize.height - 10 - (spacing * 2);

            // Agregar el pie de página
            doc.setFontSize(footerFontSize);
            doc.setFont(footerFontStyle);
            doc.setTextColor(footerTextColor);
            doc.text(pText, footerX, y18);
            doc.text(cppcpText, footerX, y1);
            doc.text(ifcpText, footerX, y2);
            doc.text(adcpnText, footerX, y3);
            doc.text(adcpeText, footerX, y4);
            doc.text(adcpnprText, footerX, y5);
            doc.text(adcpeprText, footerX, y6);
            doc.text(oadcpText, footerX + 102, y7);
            doc.text(opcpText, footerX + 102, y8);
            doc.text(adlpnText, footerX + 102, y9);
            doc.text(adlpeText, footerX + 102, y10);
            doc.text(adlpnprText, footerX + 102, y11);
            doc.text(adlpeprText, footerX + 102, y12);
            doc.text(oadlpText, footerX + 207, y13);
            doc.text(cpplpText, footerX + 207, y14);
            doc.text(iflpText, footerX + 207, y15);
            doc.text(oplpText, footerX + 207, y16);
            doc.text(apfacText, footerX + 207, y17);

            // Obtener el número total de páginas
            var totalPages = doc.internal.getNumberOfPages();

            // Recorrer todas las páginas y agregar el número de página
            for (var i = 1; i <= totalPages; i++) {

                // TITULO
                // Configuración del título
                var titleText = 'Ajuste anual por inflación: ' + objEmpresa.empresa + '\n' + objEmpresa.rfc;
                var titleFontSize = 15;
                var titleTextColor = '#FF0000'; // Color en formato hexadecimal

                // Posición del título
                var titleX = 10;
                var titleY = 10;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FF0000");
                doc.text('REPORTE', titleX, titleY, { align: 'left' });
                doc.setTextColor("#000");

                // Agregar el título al documento PDF
                doc.setFontSize(13);
                doc.setFont('helvetica', 'bold');
                doc.text('AJUSTE ANUAL POR INFLACIÓN', titleX, titleY += 5, { align: 'left' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                let empresa = objEmpresa.rfc + ' | ' + objEmpresa.empresa
                doc.text(empresa, titleX, titleY += 5, { align: 'left' });
                doc.setFontSize(11);
                let fecha = 'PERIODO: ' + Name
                doc.text(fecha, titleX, titleY += 5, { align: 'left' });

                // NUMERO DE PAGINAS
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                var textColor = "#000000";  // Color
                doc.setTextColor(textColor);
                doc.setPage(i);
                doc.text('Página ' + i + ' de ' + totalPages, 10, doc.internal.pageSize.height - 10);



                // Cargar la imagen
                // if (objEmpresa.logo.base64 != '') {
                // var logoX = doc.internal.pageSize.getWidth() - 70; // Coordenada X (esquina superior derecha)
                // var logoY = -1; // Coordenada Y (esquina superior)
                // doc.addImage(objEmpresa.logo.base64, 'PNG', logoX, logoY, 58, 20);

                // let imgData = objEmpresa.logo.base64

                // var imgWidth = 60; // Ancho del logo en puntos
                // var imgHeight = 30; // Alto del logo en puntos
                // var logoX = doc.internal.pageSize.getWidth() - 70; // Coordenada X (esquina superior derecha)
                // var logoY = -1; // Coordenada Y (esquina superior)

                // // Agregar la imagen como fondo en cada página
                // doc.addImage(imgData, 'JPEG', logoX, logoY, imgWidth, imgHeight, '', 'FAST');
                // }
            }

            // if (tipo === 'Anual') {

            //     doc.save('Ajuste_anual_por_inflación_' + objEmpresa.rfc + '_ejercicio_' + anios + ' .pdf');
            // } else {
            //     doc.save('Ajuste_anual_por_inflación_' +objEmpresa.rfc + '_de_enero_a_' + mes + '_del_' + anios + ' .pdf');
            // }
            const base64 = doc.output('datauristring');
            resolve(base64);
        } catch (error) {
            console.log(error)
        }
    })
}

export { AjusteAnualPDF };