import { render, staticRenderFns } from "./ConciliarPoliza.vue?vue&type=template&id=2c942184&"
import script from "./ConciliarPoliza.vue?vue&type=script&lang=js&"
export * from "./ConciliarPoliza.vue?vue&type=script&lang=js&"
import style0 from "./ConciliarPoliza.vue?vue&type=style&index=0&id=2c942184&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QField from 'quasar/src/components/field/QField.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QToolbar,QBtn,QTooltip,QToolbarTitle,QSeparator,QSplitter,QSelect,QInput,QIcon,QPopupProxy,QDate,QBar,QSpace,QBadge,QField,QTable,QTr,QTd,QPopupEdit,QExpansionItem,QCardActions,QFooter});qInstall(component, 'directives', {ClosePopup});
