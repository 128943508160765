import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,

    //CATALOGOS DEL SAT
    regimenFiscalStore: [],

    //UNIR POLIZAS
    listaPolizasUnir: [],

    // EMPRESA
    pdfStore: {},
    empresaStore: {
      _id: '',
      rfc: '',
      nombre: '',
      regimenFiscal: {
        regimenFiscal: ''
      },
      domicilioFiscal: '',
      logo: {
        base64: '',
      },
      nombreCorreo: '',
      correo: '',
      password: '',
      puerto: '',
      host: '',
    },

    // DESCARGAS 
    listaHistorialDescargasMetadataStore: [],
    listaHistorialDescargasCFDIStore: [],
    listaHistorialDescargasUUIDStore: [],

    // VALIDACION DE ARCHIVOS
    listaArchivosVigenciaStore: [],
    archivosStore: {
      tipo: '',
      nombreCer: '',
      archivoCer: {
        base64: ''
      },
      nombreKey: '',
      archivoKey: {
        base64: ''
      },
      password: '',
    },

    // CATALOGO DE CUENTAS 
    cuentaStore: {},
    listaCatalogoCuentasStore: [],
    listaCatalogoCuentasSatStore: [],

    // POLIZAS
    indexPolizas: -1,
    polizaStore: {},
    listaPolizasStore: [],

    //CONCILIAR POLIZAS
    polizaConciliadaStore: {},
    listaConcilarPolizasStore: [],
    indexPolizaConciliada: 0,

    //AUXILIAR
    auxuliarStore: {
      listaAuxiliarStore: [],
      numeroCuenta: '',
    },

    // DIOT
    listDiotStore: [],

    // PDF
    vistaPreviaStore: {
      tipo: 'NA'
    },

    estadoResultado: 0,
    // ACTIVIDADES DE OPERACIÓN
    listActividadesOperacion: [],
    // ACTIVIDADES DE INVERSIÓN
    listActividadesInversion: [],
    // ACTIVIDADES DE FINANCIAMIENTO
    listActividadesFinanciamiento: [],
    // EFECTIVO AL INICIO DEL EJERCICIO
    efectivoInicioEjercicioStore: 0,
    anioreciente: '',
    // DEVOLUCIONES DE IVA
    listDevolucionesIva: [],

    //COMPROBANTES CON POLIZAS
    listComprobantesPolizasStore: { tipo: '', lista: [] },
    //RUTA DEL AXIOS MONGO
    rutaDescargasStore: "https://api-erp.contago.com.mx/api/",
    // rutaDescargasStore: "https://localhost:44394/api/",

    rutaMongoStore: 'https://api-mongo.contago.com.mx/api/',
    // rutaMongoStore: 'https://localhost:44322/api/',
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    },
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token-autoconta", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token-autoconta")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token-autoconta")
      router.push({ name: 'Login' })
    },
  },
  modules: {
  }
})
