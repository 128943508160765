<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Conciliar Pólizas
                </q-toolbar-title>

                <!-- <q-toolbar-title v-show="!poliza._id == ''">
                    <template class="text-center">
                        <q-btn round color="primary" dense icon="mdi-arrow-left-bold" class="q-mr-sm"
                            @click="polizaAnterior()">
                            <q-tooltip>
                                Anterior
                            </q-tooltip>
                        </q-btn>

                        <q-btn round color="primary" dense icon="mdi-arrow-right-bold" class="q-mr-sm"
                            @click="polizaSiguiente()">
                            <q-tooltip>
                                Siguiente
                            </q-tooltip>
                        </q-btn>
                    </template>
</q-toolbar-title> -->


                <q-btn dense round color="green" icon="mdi-star-check" class="q-mr-sm" @click="PutPoliza()">
                    <q-tooltip>
                        Aplicar cambios (Otros cambios, apart de la reasignación a cuenta de bancos)
                    </q-tooltip>
                </q-btn>

                <!-- <q-btn v-if="poliza._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="PostPoliza()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="PutPoliza()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn> -->
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
            <q-splitter v-model="splitterModel" style="height: 640px;" :limits="[40, 100]">
                <template v-slot:before>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-4">
                            <q-select dense class="full-width" v-model="poliza.tipo" filled :options="itemsTiposPoliza"
                                label="Tipo de Póliza" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input dense type="number" v-model="poliza.numPoliza" filled label="No. Póliza" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input v-model="fehaIMasked" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="poliza.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12">
                            <q-input dense filled label="Descripción de la Póliza" v-model="poliza.descripcion" />
                        </div>
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>ASIENTOS</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-12">
                            <q-input dense filled v-model="asiento.descripcion" label="Descripción del asiento" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-select dense filled label="Número de Cuenta" v-model="asiento.cuenta"
                                option-label="cuenta" @filter="filtroCatalogoCuenta" :options="filtroCatalogoCuentas"
                                use-input input-debounce="0">
                                <q-badge floating rounded color="primary" :label="nombreCuentaBadge" />
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-field @blur="validaCargo()" v-model="asiento.cargo" label="Cargo" dense class="q-mr-md"
                                filled>
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                            </q-field>

                        </div>
                        <div class="col-12 col-md-3">
                            <q-field @blur="validaAbono()" v-model="asiento.abono" label="Abonos" dense filled>
                                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                    <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                        v-bind="moneyFormat" v-show="floatingLabel" />
                                </template>
                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-plus" @click="agregarAsiento()">
                                        <q-tooltip>
                                            Agregar
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-field>
                        </div>
                        <div class="col-12">
                            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                                :data="poliza.asientos" :columns="columnsAientos" row-key="_id"
                                :rows-per-page-options="[0]" hide-bottom>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width key="acciones">
                                            <q-btn size="md" color="red" rounded flat dense
                                                @click="eliminarAsiento(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                                            </q-btn>
                                            <q-btn size="md" color="blue" rounded flat dense
                                                @click="editarAiento(props.row)" icon="mdi-pencil">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Editar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="cuenta" :props="props">{{ props.row.cuenta.cuenta }}
                                            <q-popup-edit v-model="props.row.cuenta" title="Editar cuenta" auto-save
                                                v-slot="scope">
                                                <q-select dense filled label="Número de Cuenta" v-model="scope.value"
                                                    option-label="cuenta" @filter="filtroCatalogoCuenta"
                                                    :options="filtroCatalogoCuentas" use-input autofocus counter
                                                    @keyup.enter="scope.set" input-debounce="0" />
                                                <!-- <q-input v-model="scope.value" dense autofocus counter
                                                    @keyup.enter="scope.set" /> -->
                                            </q-popup-edit>
                                        </q-td>
                                        <q-td key="descripcion" :props="props">
                                            {{ props.row.descripcion }}
                                            <q-popup-edit v-model="props.row.descripcion"
                                                title="Editar descripcíon del asiento" auto-save v-slot="scope">
                                                <q-input v-model="scope.value" dense autofocus counter
                                                    @keyup.enter="scope.set" />
                                            </q-popup-edit>
                                        </q-td>
                                        <q-td key="cargo" :props="props">
                                            {{ formatCurrency(props.row.cargo) }}
                                        </q-td>
                                        <q-td key="abono" :props="props">
                                            {{ formatCurrency(props.row.abono) }}
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                        <div class="col-12 ">
                            <q-expansion-item header-class="bg-grey-3" class="full-width" v-model="listaXML"
                                icon="mdi-format-list-bulleted" label="Lista de XML">
                                <div class="row q-mt-sm q-col-gutter-sm">
                                    <div class="col-12">
                                        <q-table :columns="columnsXml" dense
                                            class="my-sticky-column-table shadow-0 header-tabla" row-key="folioFiscal"
                                            :data="poliza.listaXml">
                                            <template v-slot:body="props">
                                                <q-tr :props="props">
                                                    <q-td key="acciones">
                                                        <q-btn size="md" color="red" rounded flat dense
                                                            @click="VerComprobante(props.row)" icon="mdi-file-pdf-box">
                                                            <q-tooltip transition-show="flip-right"
                                                                transition-hide="flip-left"
                                                                content-style="font-size: 14px">PDF</q-tooltip>
                                                        </q-btn>
                                                    </q-td>
                                                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                                                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                                                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha)
                                                        }}</q-td>
                                                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                                                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                                    <q-td key="tipoComprobante" :props="props">{{
                    props.row.tipoComprobante
                }}</q-td>
                                                    <q-td key="subTotal" :props="props">{{
                    formatCurrency(props.row.subTotal)
                }}</q-td>
                                                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total)
                                                        }}</q-td>
                                                    <q-td key="descuento" :props="props">{{
                    formatCurrency(props.row.descuento)
                }}</q-td>
                                                    <q-td key="metodoPago" :props="props">{{ props.row.metodoPago
                                                        }}</q-td>
                                                    <q-td key="formaPago" :props="props">{{ props.row.formaPago
                                                        }}</q-td>
                                                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal
                                                        }}</q-td>
                                                    <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                                                    <q-td key="fechaPago" :props="props">{{
                    FormatDate(props.row.fechaPago)
                }}</q-td>
                                                </q-tr>
                                            </template>
                                        </q-table>
                                    </div>
                                </div>
                            </q-expansion-item>
                        </div>
                    </div>
                </template>
                <template v-slot:after>
                    <div class="text-center">
                        <q-icon name="mdi-download" size="sm" class="q-mr-lg" style="cursor: pointer;" color="green"
                            @click="descargarComprobante()"></q-icon>
                        <q-icon name="mdi-minus" size="md" style="cursor: pointer;" color="primary"
                            @click="page--"></q-icon>
                        {{ page }} /{{ numPages }}
                        <q-icon name="mdi-plus" size="md" style="cursor: pointer;" color="primary"
                            @click="page++"></q-icon>
                        <q-icon name="mdi-printer" size="sm" class="q-ml-lg" style="cursor: pointer;" color="blue"
                            @click="$refs.pdf.print()"></q-icon>
                        <q-icon name="mdi-xml" size="sm" class="q-ml-lg" style="cursor: pointer;" color="red"
                            @click="GetXML"></q-icon>
                        <div style="width: 100%">
                            <pdf ref="pdf" :src="pdfBase64" :page="page" :rotate="rotate"
                                @progress="loadedRatio = $event" @num-pages="numPages = $event"
                                @link-clicked="page = $event"></pdf>
                        </div>
                    </div>
                </template>
            </q-splitter>
        </q-card-section>

        <q-card-actions>
            <q-footer class="bg-dark" dark elevated style="height: 45px; margin-top: 0px;">
                <q-toolbar>
                    <q-space></q-space>
                    <div class="text-h6">Total Cargo: {{ formatCurrency(totalCargo) }}</div>
                    <q-space></q-space>
                    <div class="text-h6">Total Abono: {{ formatCurrency(totalAbono) }}</div>
                    <q-space></q-space>
                </q-toolbar>
            </q-footer>
        </q-card-actions>
    </q-card>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import pdf from 'vue-pdf'
import { ComprobanteBase64 } from '../PDF/ComprobanteBase64.js'
import { ComprobanteNominaBase64 } from '../PDF/ComprobanteNomina.js'
import { ComprobantePagoBase64 } from '../PDF/ComprobantePago.js'
import { generarCodigoQR } from '../../plugins/qrcodeGenerator';
export default {
    components: {
        Money,
        pdf: pdf
    },
    data() {
        return {
            pdfBase64: '',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,

            verPDF: false,
            splitterModel: 100,
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },

            filtroCatalogoCuentas: null,
            itemsTiposPoliza: ['Ingreso', 'Egreso', 'Diario'],
            listaXML: false,

            asiento: {
                descripcion: '',
                cuenta: { cuenta: '', numCuenta: '' },
                cargo: 0,
                abono: 0,
            },
            indexAsiento: -1,
            columnsAientos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'cuenta', align: 'center', label: 'Cuenta', field: 'cuenta', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'descripcion', align: 'center', label: 'Asiento', field: 'descripcion', sortable: true, classes: 'text-left', },
                { name: 'cargo', align: 'center', label: 'Cargo', field: 'cargo', sortable: true, classes: 'text-right', },
                { name: 'abono', align: 'center', label: 'Abono', field: 'abono', sortable: true, classes: 'text-right', },
            ],

            columnsXml: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'serie', align: 'center', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'center', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'tipoComprobante', align: 'center', label: 'TipoComprobante', field: 'tipoComprobante', sortable: true },
                { name: 'subTotal', align: 'center', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'center', label: 'total', field: 'Total', sortable: true },
                { name: 'descuento', align: 'center', label: 'Descuento', field: 'descuento', sortable: true },
                { name: 'metodoPago', align: 'center', label: 'Metodo Pago', field: 'metodoPago', sortable: true },
                { name: 'formaPago', align: 'center', label: 'Forma Pago', field: 'formaPago', sortable: true },
                { name: 'folioFiscal', align: 'center', label: 'Folio Fiscal', field: 'folioFiscal', sortable: true },
                { name: 'moneda', align: 'center', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'fechaPago', align: 'center', label: 'Fecha Pago', field: 'fechaPago', sortable: true },
            ],

            //CATALOGO
            catalogoPadre: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        poliza() {
            return this.$store.state.polizaConciliadaStore
        },

        itemsCatalogoCuentas() {
            return this.$store.state.listaCatalogoCuentasStore
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.poliza.fecha ? moment.utc(this.poliza.fecha).format('DD/MMMM/yyyy') : ''
        },

        totalCargo() {
            return this.sum(this.poliza.asientos, 'cargo')
        },

        totalAbono() {
            return this.sum(this.poliza.asientos, 'abono')
        },

        nombreCuentaBadge() {
            let cuenta = ''

            try {
                let partes = this.asiento.cuenta.numCuenta.split('.')
                let cuentaPadre = partes[0]

                let indice = 0
                indice = this.catalogoPadre.findIndex(x => x.numCuenta === cuentaPadre);
                if (indice >= 0) {
                    cuenta = this.catalogoPadre[indice].cuenta
                }
            } catch (error) {
                return cuenta
            }
        }

    },
    watch: {
    },
    created() {
        this.getCatalogoCuentas();
    },
    methods: {
        validaCargo() {
            this.asiento.abono = 0
        },

        validaAbono() {
            this.asiento.cargo = 0
        },

        async PostPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando Poliza. Espere...', messageColor: 'white' })
            //this.poliza.abono = this.sum(this.poliza.asientos, 'abono')
            //this.poliza.cargo = this.sum(this.poliza.asientos, 'cargo')

            try {
                let response = await axios.post('Polizas/PostPoliza/erp_' + this.token.rfc, this.poliza)
                console.log(response)

                this.poliza._id = response.data
                this.$store.state.listaConcilarPolizasStore.push(this.poliza)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async PutPoliza() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Editando Poliza. Espere...', messageColor: 'white' })
            //this.poliza.abono = this.sum(this.poliza.asientos, 'abono')
            //this.poliza.cargo = this.sum(this.poliza.asientos, 'cargo')

            try {
                // this.poliza.estatus = 'Validada'
                let response = await axios.put('Polizas/PutPoliza/erp_' + this.token.rfc, this.poliza)
                console.log(response)

                let indice = this.$store.state.listaConcilarPolizasStore.findIndex(x => x._id === this.poliza._id);
                Object.assign(this.$store.state.listaConcilarPolizasStore[indice], this.poliza)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La Poliza ha sido actualizado exitosamente.` })
                this.polizaSiguiente();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        agregarAsiento() {
            let cargo = parseFloat(this.asiento.cargo);
            let abono = parseFloat(this.asiento.abono);
            this.asiento.cargo = cargo
            this.asiento.abono = abono
            if (this.indexAsiento == -1) {
                this.$store.state.polizaConciliadaStore.asientos.push(this.asiento);

            } else {
                Object.assign(this.$store.state.polizaConciliadaStore.asientos[this.indexAsiento], this.asiento)
            }

            this.asiento = {
                descripcion: '',
                cuenta: { cuenta: '' },
                cargo: 0,
                abono: 0
            }
            this.indexAsiento = -1;
        },

        editarAiento(item) {
            this.indexAsiento = this.$store.state.polizaConciliadaStore.asientos.indexOf(item);
            this.asiento = { ...item }
        },

        eliminarAsiento(data) {
            let editedIndexCorreo = this.poliza.asientos.indexOf(data)
            this.poliza.asientos.splice(editedIndexCorreo, 1)
        },

        async getCatalogoCuentas() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Consultando catálogo', messageColor: 'white' })
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                let catalogo = response.data.filter(f => f.tipoCuenta === 'R' && f.estatus === 'Vigente')
                this.$store.state.listaCatalogoCuentasStore = catalogo;
                this.catalogoPadre = response.data.filter(f => f.nivel === 1);
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        FormatDate(value) {
            if (typeof value == "string") {
                let fecha_a = value.replace('T', ' ');
                let fecha_b = fecha_a.replace('Z', '');
                const cadenaFechaConHora = fecha_b;
                const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
                const formato = "dd-MMMM-yyyy HH:mm:ss";
                const configuracionLocal = { locale: es };
                let resultado = format(fecha, formato, configuracionLocal);
                return resultado;
            }
            return value;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        filtroCatalogoCuenta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filtroCatalogoCuentas = this.itemsCatalogoCuentas.filter(v => v.cuenta.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filtroCatalogoCuentas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        cierraVentana() {
            this.splitterModel = 0
            this.$emit('cierraVentana')
        },

        async VerComprobante(item) {
            this.splitterModel = 40
            try {
                console.log(item)
                let response = await axios.get('Comprobantes/GetComprobanteAsync/erp_' + this.token.rfc + '/' + item.folioFiscal + '/' + item.tipoComprobante + '/' + this.poliza.tipo);
                console.log(response)
                let x = response.data;
                let color = "#" + '662e91';

                let extraQr = x.timbreFiscalDigital.selloCFD.slice(-8);
                let cadenaOriginal = '||1.1|' + x.folioFiscal + '|' + x.timbreFiscalDigital.fechaTimbrado + '|' + x.timbreFiscalDigital.rfcProvCertif + '|' + x.timbreFiscalDigital.selloCFD + '|' + x.timbreFiscalDigital.noCertificadoSAT + '||'
                x.timbreFiscalDigital.cadenaOriginal = cadenaOriginal;

                let codigoQR = await generarCodigoQR(x.folioFiscal, x.emisor.rfc, x.receptor.rfc, x.total, extraQr);
                let base64 = ''
                switch (item.tipoComprobante) {
                    case "P":
                        base64 = await ComprobantePagoBase64(x, x.tipoComprobanteInterno, x.estatus, color, codigoQR);
                        break;
                    case "I":
                        base64 = await ComprobanteBase64(x, x.tipoComprobanteInterno, x.estatus, color, codigoQR);
                        break
                    case "E":
                        base64 = await ComprobanteBase64(x, x.tipoComprobanteInterno, x.estatus, color, codigoQR);
                        break
                    case "N":
                        base64 = await ComprobanteNominaBase64(x, x.tipoComprobanteInterno, x.estatus, color, codigoQR);
                        break
                }

                this.pdfBase64 = 'data:application/pdf;base64,' + base64.split(',')[1];
                this.$store.state.pdfStore.nombre = item.folioFiscal;
                this.$store.state.pdfStore.fecha = x.fecha;

                // console.log(this.$store.state.comprobanteStore)
            } catch (error) {
                console.log(error)
            }
        },

        polizaAnterior() {
            this.splitterModel = 100
            let indice = this.$store.state.indexPolizaConciliada - 1
            if (indice == -1) {
                indice = this.$store.state.listaConcilarPolizasStore.length - 1
            }
            this.$store.state.indexPolizaConciliada = indice
            this.$store.state.polizaConciliadaStore = Object.assign({}, this.$store.state.listaConcilarPolizasStore[indice])
        },

        polizaSiguiente() {
            try {
                this.splitterModel = 100
                let indice = this.$store.state.indexPolizaConciliada + 1
                console.log(indice);
                if (indice > this.$store.state.listaConcilarPolizasStore.length - 1) {
                    this.cierraVentana();
                    // indice = 0;
                }
                this.$store.state.indexPolizaConciliada = indice
                this.$store.state.polizaConciliadaStore = Object.assign({}, this.$store.state.listaConcilarPolizasStore[indice])
            } catch (error) {
                console.log(error);
            }
        },

        imprimirComprobante() {

        },

        descargarComprobante() {
            const fileName = this.$store.state.pdfStore.nombre + ".pdf";
            const link = document.createElement("a");
            link.href = this.pdfBase64;
            link.download = fileName;
            link.click();
        },

        async GetXML() {
            const nombresMeses = [
                'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
            ];

            let folioFiscal = this.$store.state.pdfStore.nombre;
            let rfc = this.token.rfc
            let fecha = new Date(this.$store.state.pdfStore.fecha);
            let año = fecha.getFullYear();
            let mes = fecha.getMonth();
            let nombreMes = nombresMeses[mes]

            try {
                let response = await axios.get('Comprobantes/GetXmlAsync/' + rfc + '/' + año + '/' + nombreMes + '/' + folioFiscal);
                await this.descargaComprobanteXml(response.data)
            } catch (error) {
                console.log(error)
            }
        },

        async descargaComprobanteXml(item) {
            const xmlBase64 = item
            const fileName = this.$store.state.pdfStore.nombre + ".xml";
            const link = document.createElement("a");
            link.href = "data:text/xml;base64," + xmlBase64;
            link.download = fileName;
            link.click();
        },
    }
}
</script>
<style lang="sass">
.my-sticky-column-table

  thead tr:first-child th:first-child
    /* bg color is important for th; just specify one */
    background-color: #fff

  td:first-child
    background-color: #fff

  th:first-child,
  td:first-child
    position: sticky
    left: 0
    z-index: 1
</style>dex: 1
</style>