<template>
    <q-card>
        <!-- DIALOGO PARA CREAR UNA NUEVA POLIZA -->
        <q-dialog full-width v-model="dialogNuevaPoliza" persistent transition-show="scale" transition-hide="scale">
            <Poliza @cierraVentana="dialogNuevaPoliza = false"></Poliza>
        </q-dialog>
        <q-card-section>
            <q-toolbar>
                <!-- CIERRA VENTANA -->
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Póliza Incial
                </q-toolbar-title>
                <!-- PARA DESCARGAR PLANTILLA -->
                <q-btn round color="green-10" icon="mdi-file-excel-box-outline" dense @click="DescargarExcel()">
                    <q-tooltip>
                        Descargar plantilla en excel
                    </q-tooltip>
                </q-btn>
                <!-- VALIDAMOS EL AÑO -->
                <template v-if="paso == 1">
                    <q-btn dense round color="green" icon="mdi-calendar-check" class="q-mr-sm" @click="confirmarYear">
                        <q-tooltip>
                            Confirmar año
                        </q-tooltip>
                    </q-btn>
                </template>
                <!-- VALIDAMOS EL EXCEL -->
                <template v-if="paso == 2">
                    <q-btn dense round color="green" icon="mdi-receipt-text-check" class="q-mr-sm" @click="ExtraeDatos">
                        <q-tooltip>
                            Validar Excel
                        </q-tooltip>
                    </q-btn>
                </template>
                <!-- GUARDAMOS LAS CUENTAS -->
                <template v-if="paso == 3">
                    <q-btn dense round color="green" icon="mdi-text-box-check" class="q-mr-sm" @click="PostCuentasNuevas()">
                        <q-tooltip>
                            Generar Póliza
                        </q-tooltip>
                    </q-btn>
                </template>
                <!-- GUARDAMOS LAS POLIZA -->
                <template v-if="paso == 4">
                    <q-btn dense round color="red" icon="mdi-cancel" class="q-mr-sm" @click="paso = 1">
                        <q-tooltip>
                            Cancelar
                        </q-tooltip>
                    </q-btn>
                </template>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section>
            <div class="row q-col-gutter-sm">
                <template v-if="paso == 1">
                    <div class="col-12">
                        <q-select outlined v-model="selectAño" :options="optionsAños" label="Seleccione el año de cierre"
                            dense class="text-align-center" />
                    </div>
                </template>
                <template v-if="paso == 2">
                    <div class="col-12">
                        <q-uploader label="Seleccione su archivo en excel"
                            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            :factory="UploadDocument" class="full-width" />
                    </div>
                </template>
                <template v-if="paso == 3">
                    <div class="col-12">
                        <q-table class="shadow-0 header-tabla" :data="listCuentasNuevas" :pagination="initialPagination"
                            :columns="columnsCuentas" row-key="_id" title="Cuentas Nuevas">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="numCuenta" :props="props">{{ props.row.numCuenta }}</q-td>
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="naturaleza" :props="props">{{ props.row.naturaleza }}</q-td>
                                    <q-td key="numCuentaSat" :props="props">{{ props.row.numCuentaSat.numCuenta }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </template>
                <!-- <template v-if="paso == 4">
                    <div class="col-12">
                        <poliza class="full-width"></poliza>
                    </div>
                </template> -->
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import * as XLSX from 'xlsx';
import Poliza from '../Polizas/Poliza.vue'

export default {
    components: {
        Money,
        Poliza
    },
    data() {
        return {
            archivo: null,
            listaMovimientos: [],
            selectAño: '',

            //CATALOGO DE CUENTAS
            listCuentasNuevas: [],
            columnsCuentas: [
                { name: 'numCuenta', align: 'left', label: 'Número de Cuenta', field: 'numCuenta', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true, },
                { name: 'naturaleza', align: 'center', label: 'Naturaleza', field: 'naturaleza', sortable: true, },
                { name: 'numCuentaSat', align: 'left', label: 'Cuenta SAT', field: 'numCuentaSat', sortable: true, },
            ],
            initialPagination: {
                sortBy: 'numCuenta',
                descending: false,
                page: 1,
                rowsPerPage: 10
            },

            paso: 1,

            poliza: {},
            dialogNuevaPoliza: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        optionsAños() {
            var fechaActual = new Date();
            var añoActual = fechaActual.getFullYear();
            añoActual--;
            let listaChida = []
            for (let a = 0; a <= 6; a++) {
                listaChida.push(añoActual)
                añoActual--;
            }
            return listaChida
        },

    },
    watch: {

    },

    created() {
        this.GetCatalogoCuentas();
        this.GetCatalogoCuentasSat();
    },

    methods: {
        async GetCatalogoCuentas() {
            this.loadingTabla = true
            try {
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentas/erp_" + this.token.rfc);
                this.$store.state.listaCatalogoCuentasStore = response.data;
                // console.log(response.data)
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async GetCatalogoCuentasSat() {
            try {
                let response = await axios.get("CatalogoCuentas/GetCatalogoCuentasSat/");
                // console.log(response)
                this.$store.state.listaCatalogoCuentasSatStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        UploadDocument(files) {
            const file = files[0]
            this.archivo = file;
        },

        async ExtraeDatos() {
            if (!this.archivo) {
                this.$q.notify({
                    message: 'Seleccione el archivo en excel y de click en la nube para cargar',
                    type: 'warning',
                    actions: [
                        { label: 'Dismiss', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            this.listCuentasNuevas = [];
            this.listaMovimientos = [];
            const file = this.archivo;
            const resultMovimientos = await this.readExcelFile(file, 'Hoja1');
            this.listaMovimientos.push(...resultMovimientos);

            //CREAMOS LA POLIZA
            let asientos = await this.GeneraAsientos();
            let poliza = {
                _id: '',
                tipo: 'Diario',
                fecha: this.selectAño + '-12-31T23:59:59',
                mes: 'Diciembre',
                año: this.selectAño,
                descripcion: 'PÓLIZA DE INICIO',
                numPoliza: '0',
                asientos: asientos,
                listaXml: [],
                estatus: 'Activo',
                cargo: 0,
                abono: 0,
                diferencia: 0,
            }
            // console.log(poliza)
            this.poliza = { ...poliza }
            this.$store.state.polizaStore = { ...poliza }
            this.paso = 3;
            // this.dialogNuevaPoliza = true;
        },

        readExcelFile(file, hoja) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const sheetName = workbook.SheetNames[0];
                    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    resolve(sheetData);
                };
                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsArrayBuffer(file);
            });
        },

        async GeneraAsientos() {
            const catalogo = this.$store.state.listaCatalogoCuentasStore;
            const catalogoSat = this.$store.state.listaCatalogoCuentasSatStore;
            let item = 1;
            let listaAsientos = [];
            for (let a of this.listaMovimientos) {
                let cargo_ = 0;
                let abono_ = 0;
                if (a.debe) {
                    cargo_ = a.debe;
                }
                if (a.haber) {
                    abono_ = a.haber;
                }
                let asientoContable = {
                    item: item,
                    cuenta: {},
                    descripcion: '',
                    cargo: Number(cargo_),
                    abono: Number(abono_)
                }
                const cuentaM = a.cuentaSat.toString();
                let cuentaContaGo = catalogo.find(f => f.numCuenta === cuentaM);

                //CREACION DE CUENTAS
                switch (true) {
                    case cuentaM === '102.01':
                        //PRIMERO VALIDAMOS SI LA CUENTA YA EXISTE
                        let validaCuentaBancos = catalogo.find(f => f.descripcion === a.nombre);
                        if (!validaCuentaBancos) {
                            let numCuentaBancos = await this.crearCuentaHijo('102.01');;
                            let numCuentaUnicoBancos = await this.agregarCeros(numCuentaBancos);;
                            let nivelBancos = numCuentaBancos.split('.').length;
                            let cuentaBancos = {
                                _id: '',
                                numCuentaUnico: numCuentaUnicoBancos,
                                numCuenta: numCuentaBancos,
                                descripcion: a.nombre,
                                numCuentaSat: { ...cuentaContaGo.numCuentaSat },
                                cuenta: numCuentaBancos + ' | ' + a.nombre,
                                tipoCuenta: 'R',
                                naturaleza: cuentaContaGo.naturaleza,
                                nivel: nivelBancos,
                                editable: 'SI',
                                estatus: "Vigente"
                            }
                            this.$store.state.listaCatalogoCuentasStore.push(cuentaBancos);
                            this.listCuentasNuevas.push(cuentaBancos)
                            asientoContable.cuenta = { ...cuentaBancos }
                        } else {
                            asientoContable.cuenta = { ...validaCuentaBancos }
                        }
                        // console.log(cuentaBancos);
                        break;
                    case cuentaM === '105.01':
                        let validaCuentaClientes = catalogo.find(f => f.descripcion === a.nombre);
                        if (!validaCuentaClientes) {
                            let numCuentaClientes = await this.crearCuentaHijo('105.01');;
                            let numCuentaUnicoClientes = await this.agregarCeros(numCuentaClientes);;
                            let nivelClientes = numCuentaClientes.split('.').length;
                            let cuentaClientes = {
                                _id: '',
                                numCuentaUnico: numCuentaUnicoClientes,
                                numCuenta: numCuentaClientes,
                                descripcion: a.nombre,
                                numCuentaSat: { ...cuentaContaGo.numCuentaSat },
                                cuenta: numCuentaClientes + ' | ' + a.nombre,
                                tipoCuenta: 'R',
                                naturaleza: cuentaContaGo.naturaleza,
                                nivel: nivelClientes,
                                editable: 'SI',
                                estatus: "Vigente"
                            }
                            this.$store.state.listaCatalogoCuentasStore.push(cuentaClientes);
                            this.listCuentasNuevas.push(cuentaClientes)
                            asientoContable.cuenta = { ...cuentaClientes }
                        } else {
                            asientoContable.cuenta = { ...validaCuentaClientes }
                        }
                        // console.log(cuentaClientes);
                        break;
                    case cuentaM.includes('205'):
                        let validaAcreedor = cuentaM.split('.').length;
                        if (validaAcreedor >= 2) {
                            let validaCuentaAcreedor = catalogo.find(f => f.descripcion === a.nombre);
                            if (!validaCuentaAcreedor) {
                                let numCuentaAcreedores = await this.crearCuentaHijo(cuentaM);
                                let nivelAcreedores = numCuentaAcreedores.split('.').length;
                                let numCuentaUnicoAcreedores = await this.agregarCeros(numCuentaAcreedores);;
                                let cuentaAcreedores = {
                                    _id: '',
                                    numCuentaUnico: numCuentaUnicoAcreedores,
                                    numCuenta: numCuentaAcreedores,
                                    descripcion: a.nombre,
                                    numCuentaSat: { ...cuentaContaGo.numCuentaSat },
                                    cuenta: numCuentaAcreedores + ' | ' + a.nombre,
                                    tipoCuenta: 'R',
                                    naturaleza: cuentaContaGo.naturaleza,
                                    nivel: nivelAcreedores,
                                    editable: 'SI',
                                    estatus: "Vigente"
                                }
                                this.$store.state.listaCatalogoCuentasStore.push(cuentaAcreedores);
                                this.listCuentasNuevas.push(cuentaAcreedores)
                                asientoContable.cuenta = { ...cuentaAcreedores }
                            } else {
                                asientoContable.cuenta = { ...validaCuentaAcreedor }
                            }
                        }
                        break;
                    case cuentaM === '201.01':
                        let validaCuentaProveedor = catalogo.find(f => f.descripcion === a.nombre);
                        if (!validaCuentaProveedor) {
                            let numCuentaProveedores = await this.crearCuentaHijo(cuentaM);
                            let nivelProveedores = numCuentaProveedores.split('.').length;
                            let numCuentaUnicoProveedores = await this.agregarCeros(numCuentaProveedores);;
                            let cuentaProveedores = {
                                _id: '',
                                numCuentaUnico: numCuentaUnicoProveedores,
                                numCuenta: numCuentaProveedores,
                                descripcion: a.nombre,
                                numCuentaSat: { ...cuentaContaGo.numCuentaSat },
                                cuenta: numCuentaProveedores + ' | ' + a.nombre,
                                tipoCuenta: 'R',
                                naturaleza: cuentaContaGo.naturaleza,
                                nivel: nivelProveedores,
                                editable: 'SI',
                                estatus: "Vigente"
                            }
                            this.$store.state.listaCatalogoCuentasStore.push(cuentaProveedores);
                            this.listCuentasNuevas.push(cuentaProveedores)
                            asientoContable.cuenta = { ...cuentaProveedores }
                        } else {
                            asientoContable.cuenta = { ...validaCuentaProveedor }
                        }
                        break;
                    default:
                        asientoContable.cuenta = { ...cuentaContaGo }
                }
                listaAsientos.push(asientoContable);
                item++;
            }
            let pre = listaAsientos.filter(objeto => objeto.cuenta.tipoCuenta === 'R');
            let final = pre.sort((a, b) => a.cuenta.numCuentaUnico.localeCompare(b.cuenta.numCuentaUnico));
            return final;
        },

        async ValidaAsientos(lista) {
            this.$store.state.listaCatalogoCuentasStore = [];
            await this.GetCatalogoCuentas();
            let catalogo = this.$store.state.listaCatalogoCuentasStore;
            let item = 1;
            for (let c of lista) {
                try {
                    c.item = item;
                    let cuentaContaGo = catalogo.find(f => f.numCuentaUnico === c.cuenta.numCuentaUnico);
                    item++;
                } catch (error) {
                    console.log(error)
                }
            }
        },

        agregarCeros(valor) {
            const partes = valor.split('.');
            const partesConCeros = partes.map(parte => parte.padStart(10, '0'));
            const valorFormateado = partesConCeros.join('.');
            // this.cuenta.numCuentaUnico = valorFormateado;
            return valorFormateado;
        },

        crearCuentaHijo(cuenta) {
            const cuentaPadre = cuenta
            const lista = this.$store.state.listaCatalogoCuentasStore.map(objeto => objeto.numCuenta);

            // Filtrar elementos de la cuenta "10.01.01"
            const elementosCuentaPadre = lista.filter(item => item.startsWith(cuentaPadre + '.'));

            // Encontrar el último elemento en la cuenta
            const ultimoElemento = elementosCuentaPadre[elementosCuentaPadre.length - 1];

            // Generar un nuevo elemento
            let nuevoElemento;
            if (ultimoElemento) {
                const ultimoNumero = parseInt(ultimoElemento.split('.').pop(), 10);
                nuevoElemento = cuentaPadre + '.' + (ultimoNumero + 1).toString().padStart(2, '0');
            } else {
                nuevoElemento = cuentaPadre + '.01';
            }
            return nuevoElemento;
        },

        async PostCuentasNuevas() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'purple', spinnerSize: 140, message: 'Guardando cuenta. Espere...', messageColor: 'white' })
            for (let c of this.listCuentasNuevas) {
                await this.postCuenta(c);
            }
            this.$q.loading.hide()
            this.dialogNuevaPoliza = true;
            await this.ValidaAsientos(this.$store.state.polizaStore.asientos);
            this.paso = 4
        },

        async postCuenta(cuenta) {
            try {
                let response = await axios.post('CatalogoCuentas/PostCuenta/erp_' + this.token.rfc, cuenta)
                cuenta._id = response.data
                // this.$store.state.listaCatalogoCuentasStore.push(cuenta)
                this.$q.notify({ type: 'positive', message: 'La cuenta ' + cuenta.cuenta + ' ha sido guardado exitosamente.' })
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        confirmarYear() {
            if (this.selectAño === '') {
                this.$q.notify({
                    message: 'Seleccione el año de cierre',
                    type: 'warning',
                    actions: [
                        { label: 'Dismiss', color: 'white', handler: () => { } }
                    ]
                })
                return;
            }
            this.paso = 2;
        },

        DescargarExcel() {
            try {
                const rutaArchivo = '/poliza inicial.xlsx';
                const enlaceTemporal = document.createElement('a');
                enlaceTemporal.href = rutaArchivo;
                enlaceTemporal.download = 'poliza iniclal.xlsx';
                document.body.appendChild(enlaceTemporal);
                enlaceTemporal.click();
                document.body.removeChild(enlaceTemporal);
            } catch (error) {
                console.log(erro)
            }
        },
    }
}
</script>
<style>
.full-width {
    width: 100%;
}
</style>